import { Observable } from 'rxjs';

import {ErrorInfoModel, LogEntry, LogPublisherConfig} from '../models/common.model';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import * as StackTraceParser from 'error-stack-parser';
import {Injector} from '@angular/core';
import {StorageService} from '@shared-services/storage.service';

export abstract class LogPublisher {
    storageService: StorageService;
    logPublisherConfig: LogPublisherConfig;
    abstract log(record: LogEntry): Observable<any>;
    abstract clear(): Observable<boolean>;

    constructor(public injector: Injector) {
        this.storageService = this.injector.get(StorageService);
    }

    getErrorInfo(error) : ErrorInfoModel {
        // All the context details that you want (usually coming from other services; Constants, UserService...)
        const name = error.name || null;
        const appId = this.storageService.getLocalStorage('default').login;
        const user = this.storageService.getLocalStorage('username');
        const time = new Date().getTime();
        const id = `${appId}-${user}-${time}`;
        const location = this.injector.get(LocationStrategy);
        const url = location instanceof PathLocationStrategy ? location.path() : '';
        const status = error.status || null;
        let stack = [];
        try {
            stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);
        } catch (ex) {
            stack = [];
        }
        return {name: name , appId: appId, user: user, time: time, id: id,
            url: url, status: status, stack: stack};
    }
}
