import {Subscription, interval} from 'rxjs';
import {Router} from '@angular/router';
import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {AuthService} from '@app/shared/services/auth.service';
import {StorageService} from '@app/shared/services/storage.service';
import {RoutePaths} from '@shared-constants/route-paths';

import {PopupType} from '@app/shared/components/popup/popup.model';
import {LoaderType} from '@app/shared/directives/loader/loader.model';
import {PopupService} from '@shared-components/popup/popup.service';
import {LogService} from '@shared-services/log.service';
import {LogLevel} from '@shared-models/common.model';
import * as moment from "moment-timezone";
import { ERROR, OTP_ERRORS } from '@app/shared/constants/error-messages';
// import { MatomoTracker } from 'ngx-matomo';

@Component({
    selector: 'app-otp-verification',
    templateUrl: './otp-verification.component.html',
    styleUrls: ['./otp-verification.component.scss']
})
export class OTPVerificationComponent implements OnInit, AfterViewInit, OnDestroy {
    title: string;
    retainLoader = true;
    verifyFG: FormGroup;
    private traceId: string;
    verificationText: string;
    logData= {};
    finalog = {};
    arrayDate = {};
    logText = 'Application Log Verify OTP';

    verifyOtpSubscription: Subscription;
    public loaderTypeEnum = LoaderType;
    public otpMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

    defaultTimer = 180;
    mainTimer;
    currentTimer = '03:00';
    isTimerComplete = false;
    otpReceiveTimeTimezone = 'America/Chicago';
    dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
    webWorker: Worker
    get codeFormControl(): FormControl {
        return this.verifyFG.get('code') as FormControl;
    }

    constructor(public fb: FormBuilder, public authService: AuthService, private routePaths: RoutePaths,
                private router: Router, private cdRef: ChangeDetectorRef, public storageService: StorageService,
                private popupService: PopupService,
                private logger: LogService
                ) {
        this.traceId = this.logger.genTraceId();
    }

    ngOnInit() {
        this.logger.postEntry(
            LogLevel.Info,
            'Initializing OTP Verification Component',
            'OTPVerification.ngOnInit',
            this.traceId,
            100
        );
        if (this.storageService.isDeviceNotAvailable) {
            this.title = 'Verification Code';
            this.verificationText = 'Please enter the verification code generated by the front desk.';
        } else {
            this.title = 'Identity Verification';
            this.verificationText = 'Please enter the verification code you received from the previous step.';
        }

        this.setupFormControls();

        if(typeof Worker !== 'undefined') {
            this.webWorker = new Worker('@app/web-worker.worker', { type: 'module' })
            this.webWorker.onmessage = function(data) {
              document.getElementById('otp_timer').innerText = data.data;
            }
        }
        this.startTimer();
    }
    ngAfterViewInit() {
        this.logger.postEntry(
            LogLevel.Info,
            'OTP Verification Component initialized',
            'OTPVerification.ngAfterViewInit',
            this.traceId,
            100
        );
    }

    private setupFormControls() {
        this.logger.postEntry(
            LogLevel.Info,
            'Setting up OTP Verification form controls',
            'OTPVerification.ngOnInit',
            this.traceId,
            100
        );
        this.verifyFG = this.fb.group({
            code: this.fb.control('', Validators.required),
        });
    }

    verifyCode() {
        if (this.isTimerComplete) {
            this.popupService.showModal(ERROR, OTP_ERRORS.OTPExpired, PopupType.ERROR, true);
            this.verifyFG.setValue({
                code: ''
            });
            return false;
        }
        this.logger.postEntry(
            LogLevel.Info,
            'Verifying 2FA Code',
            'OTPVerification.verifyCode',
            this.traceId,
            100
        );
        this.retainLoader = true;
        if (this.storageService.isDeviceNotAvailable) {
            this.logger.postEntry(
                LogLevel.Info,
                'User device marked not available',
                'OTPVerification.verifyCode',
                this.traceId,
                100
            );

            this.verifyOtpSubscription = this.authService.verifyOTPWithoutDevice(this.codeFormControl.value)
                .subscribe(() => {
                    this.otpVerificationSuccess();
                }, (error) => {
                    this.otpVerificationError(error);
                });
        } else {
            this.verifyOtpSubscription = this.authService.verifyOTP(this.verifyFG.value, moment().tz(this.otpReceiveTimeTimezone).format(this.dateTimeFormat))
                .subscribe(() => {
                    this.otpVerificationSuccess();
                }, (error) => {
                    this.otpVerificationError(error);
                });
        }
    }

    otpVerificationSuccess() {
        this.logger.postEntry(
            LogLevel.Info,
            '2FA Code verified',
            'OTPVerification.otpVerificationSuccess',
            this.traceId,
            100
        );
        this.retainLoader = false;
        this.cdRef.detectChanges();
        this.codeFormControl.reset();
        this.storageService.isOTPVerified = true;
        this.logger.postEntry(
            LogLevel.Info,
            'Navigating to ' + this.routePaths.APPOINTMENT_DETAILS,
            'OTPVerification.otpVerificationSuccess',
            this.traceId,
            100
        );
        this.arrayDate['OTPverified'] = 'Done';
        this.prepareLog(this.logText, this.arrayDate);
        this.router.navigate([this.routePaths.APPOINTMENT_DETAILS]);
        return false;
    }

    otpVerificationError(error) {
        this.logger.postEntry(
            LogLevel.Info,
            'Unable to verify 2FA code',
            'OTPVerification.otpVerificationError',
            this.traceId,
            100
        );
        this.arrayDate['OTPverified'] = 'False';
        this.prepareLog(this.logText, this.arrayDate);
        this.retainLoader = false;
        this.cdRef.detectChanges();
        this.handleError(error);
    }

    otpBlur() {
        //console.log(this.codeFormControl.value);
        if (this.arrayDate['ReSendOTP'] == "") {
            delete this.arrayDate['ReSendOTP'];
        }
        if (this.arrayDate['OTPverified'] == 'False') {
            delete this.arrayDate['OTPverified'];
        }
        this.arrayDate['OTP'] = this.codeFormControl.value;
        this.prepareLog(this.logText, this.arrayDate);
    }

    sendAnotherOTP() {
        this.logger.postEntry(
            LogLevel.Info,
            'Sending new 2FA code',
            'OTPVerification.sendAnotherOTP',
            this.traceId,
            100
        );
        this.logger.postEntry(
            LogLevel.Info,
            'Navigating to ' + this.routePaths.SEND_OTP,
            'OTPVerification.sendAnotherOTP',
            this.traceId,
            100
        );
        if (this.arrayDate['OTP'] == "") {
            delete this.arrayDate['OTP'];
        }
        this.arrayDate['ReSendOTP'] = 'Done';
        this.prepareLog(this.logText, this.arrayDate);
        this.router.navigate([this.routePaths.SEND_OTP]);
        return false;
    }

    private handleError(err) {
        this.logger.postEntry(
            LogLevel.Error,
            err.error.message,
            'OTPVerification.handleError',
            this.traceId,
            err.status
        );
        this.codeFormControl.reset();
        this.popupService.showModal('Error', err.error.message, PopupType.ERROR, true);
    }

    ngOnDestroy() {
        this.logger.postEntry(
            LogLevel.Info,
            'Destroying OTP Verification Component',
            'OTPVerification.ngOnDestroy',
            this.traceId,
            100
        );
        this.retainLoader = false;
        this.mainTimer.unsubscribe();
    }

    private prepareLog(formName, data) {
        var formvalue = formName
        if (data) {
            //console.log(this.logData);
            this.finalog[formvalue] = JSON.stringify(data);
            this.finalog['patientId'] = this.storageService.patientId;
            //this.finalog['appointmentId'] = this.storageService.appointmentId;   
        }
        var textinfo = 'Getting log for Patient Id ' + this.storageService.patientId + ' Verify OTP Page:';
        this.logger.trackFormIoEvents(textinfo, JSON.stringify(this.finalog));
    }

    startTimer() {
        this.authService.updateOTPReceiveTime({'otpReceiveTime': moment().tz(this.otpReceiveTimeTimezone).format(this.dateTimeFormat)}).subscribe();
        let timer = this.defaultTimer - 1;
        let minutes;
        let seconds;
        this.mainTimer = interval(1000).subscribe(() => {
          minutes = Math.floor(timer / 60);
          seconds = Math.floor(timer % 60);
          minutes = minutes < 10 ? '0' + minutes : minutes;
          seconds = seconds < 10 ? '0' + seconds : seconds;
          this.webWorker.postMessage(minutes + ':' + seconds);
          --timer;
          if (timer < 0) {
            this.isTimerComplete = true;
            this.mainTimer.unsubscribe();
          }
        });
    }
}
