import { AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@shared-services/auth.service';
import { CommonService } from '@shared-services/common.service';
import { LogLevel } from '@shared-models/common.model';
import { LogService } from '@shared-services/log.service';
import { StorageService } from '@shared-services/storage.service';
import { PopupType } from '@shared-components/popup/popup.model';
import { FormUtil } from '@shared-util/form.util';
import { LoaderType } from '@shared-directives/loader/loader.model';
import { RoutePaths } from '@shared-constants/route-paths';
import { ERROR, LoginType, Error as ErrorMessage } from '@shared-constants/error-messages';
import { PopupService } from '@shared-components/popup/popup.service';
import { LoginErrorStatus } from './../../shared/constants/error-messages';
import { Md5 } from 'ts-md5/dist/md5';
// import { MatomoTracker } from 'ngx-matomo';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(loginTypeFb, authService, commonService, storageService, router, routePaths, cdRef, popupService, sanitizer, logger) {
        this.loginTypeFb = loginTypeFb;
        this.authService = authService;
        this.commonService = commonService;
        this.storageService = storageService;
        this.router = router;
        this.routePaths = routePaths;
        this.cdRef = cdRef;
        this.popupService = popupService;
        this.sanitizer = sanitizer;
        this.logger = logger;
        this.title = 'Welcome to eRegistration';
        this.loginType = LoginType;
        this.retainLoader = true;
        this.loaderTypeEnum = LoaderType;
        this.isSmartLinkEnabled = false;
        this.isCookieEnabled = true;
        this.smartLink = 'SMARTLINK';
        this.logData = {};
        this.finalog = {};
        this.arrayDate = {};
        this.logText = 'Application Log Login Page';
    }
    Object.defineProperty(LoginComponent.prototype, "loginTypeValue", {
        get: function () {
            debugger;
            if (!this.isSmartLinkEnabled) {
                if (!this.isAlternateLoginAllowed) {
                    return this.loginType.DEMOGRAPHICS;
                }
                else {
                    return this.loginTypeFg.get('loginType').value;
                }
            }
            else {
                return this.smartLink;
            }
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.ngOnInit = function () {
        this.traceId = this.logger.genTraceId();
        this.logger.postEntry(LogLevel.Info, 'Initializing Login component', 'LoginComponent.ngOnInit', this.traceId, 100);
        if (!navigator.cookieEnabled) {
            this.isCookieEnabled = false;
            this.popupService.showModal('Cookie Error', 'Use of this website requires your browser cookies being enabled. Please go to your browser settings to ensure that cookies are enabled and try again.', PopupType.ERROR, true, 'Refresh');
            return false;
        }
        if (this.storageService.patientToken) {
            var patientToken = this.storageService.patientToken;
            var decodeToken = atob(patientToken);
            var tokenArray = decodeToken.split('/');
            // this.storageService.patientToken = patientToken;
            this.storageService.locationId = tokenArray[1];
            this.storageService.patientId = tokenArray[2];
            this.storageService.appointmentId = tokenArray[3];
            this.isSmartLinkEnabled = true;
        }
        else {
            if (this.storageService.LoginConfiguration &&
                this.storageService.LoginConfiguration.demographics &&
                this.storageService.LoginConfiguration.patientId) {
                this.isAlternateLoginAllowed = this.storageService.LoginConfiguration.patientId.enabled;
            }
            else {
                this.isAlternateLoginAllowed = !this.storageService.LoginConfiguration.demographics.enabled;
            }
            this.isSmartLinkEnabled = false;
        }
        if (this.isAlternateLoginAllowed) {
            this.setupFormControl();
        }
        this.headerMessage = this.sanitizer.bypassSecurityTrustHtml(this.storageService.LoginInfoText);
    };
    LoginComponent.prototype.ngAfterViewInit = function () {
        this.logger.postEntry(LogLevel.Info, 'Login component initialized', 'LoginComponent.ngAfterViewInit', this.traceId, 100);
    };
    LoginComponent.prototype.setupFormControl = function () {
        this.logger.postEntry(LogLevel.Info, 'Setting up form control', 'LoginComponent.setupFormControl', this.traceId, 100);
        this.loginTypeFg = this.loginTypeFb.group({
            loginType: new FormControl(this.loginType.DEMOGRAPHICS)
        });
    };
    LoginComponent.prototype.isFormValid = function () {
        // mark forms as touched
        this.logger.postEntry(LogLevel.Info, 'Checking login form is valid.', 'LoginComponent.isFormValid', this.traceId, 100);
        var isLoginFormValid = false;
        switch (this.loginTypeValue) {
            case this.loginType.DEMOGRAPHICS:
                FormUtil.markAsTouched(this.demographicsLoginComponent.demographicsLoginFg);
                isLoginFormValid = this.demographicsLoginComponent.isFormValid();
                break;
            case this.loginType.PATIENT_ID:
                FormUtil.markAsTouched(this.patientIDLoginComponent.patientIDLoginFg);
                isLoginFormValid = this.patientIDLoginComponent.isFormValid();
                break;
            case this.smartLink:
                FormUtil.markAsTouched(this.smartLinkLoginComponent.smartLinkLoginFg);
                isLoginFormValid = this.smartLinkLoginComponent.isFormValid();
                break;
            default:
                isLoginFormValid = false;
        }
        if (isLoginFormValid) {
            this.logger.postEntry(LogLevel.Info, 'Login form is valid.', 'LoginComponent.isFormValid', this.traceId, 100);
        }
        else {
            this.logger.postEntry(LogLevel.Info, 'Login form is invalid', 'LoginComponent.isFormValid', this.traceId, 100);
        }
        return isLoginFormValid;
    };
    LoginComponent.prototype.login = function () {
        var _this = this;
        this.retainLoader = true;
        this.cdRef.detectChanges();
        this.logger.postEntry(LogLevel.Info, 'Begin login.', 'LoginComponent.login', this.traceId, 100);
        if (this.isFormValid()) {
            this.storageService.loginType = this.loginTypeValue;
            var loginFormVal;
            if (this.loginTypeValue == 'SMARTLINK') {
                loginFormVal = this.smartLinkLoginComponent.getFormValue();
                var md5 = new Md5();
                var encodedDob = md5.appendStr(loginFormVal.DOB).end();
                if (encodedDob != this.storageService.accountInfoDetails.patientTokenData) {
                    this.popupService.showModal(ERROR, 'Entered DOB does not match our records', PopupType.ERROR, true);
                    return false;
                }
            }
            else {
                loginFormVal = this.loginTypeValue === this.loginType.DEMOGRAPHICS ? (this.demographicsLoginComponent.getFormValue())
                    : this.patientIDLoginComponent.getFormValue();
            }
            /*this.logger.postEntry( PHI
                LogLevel.Info,
                'Login Form Values: ' + JSON.stringify(loginFormVal),
                'LoginComponent.login',
                this.traceId,
                100
            );*/
            // check for a null suffix
            if (loginFormVal.suffix == null) {
                this.logger.postEntry(LogLevel.Error, 'URL suffix is null', 'LoginComponent.login', this.traceId, 100);
            }
            loginFormVal.LoginType = this.loginTypeValue;
            loginFormVal.DOB = loginFormVal.DOB.replace(/\//gi, '-');
            this.loginSubscription = this.authService.login(loginFormVal).subscribe(function (response) {
                _this.retainLoader = false;
                _this.storageService.patientId = response.data.PatientID;
                _this.authService.patientDetails = response.data.Patient;
                _this.storageService.authToken = response.data.AcccessToken;
                _this.storageService.authExpiry = response.data.TokenExpiry;
                _this.storageService.storeAuthTokenInLocalStorage();
                // If patient login, we need to check for valid 2FA items
                if (_this.loginTypeValue === _this.loginType.DEMOGRAPHICS) {
                    var patient = response.data.Patient[0];
                    var isTwoFactorInvalid = !patient.Phone && !patient.Email;
                    // check for 2FA items, throw an error to display in modal if not available
                    if (isTwoFactorInvalid) {
                        var errorObj = {
                            error: {
                                message: ErrorMessage.LOGIN.INVALID_2FA
                            },
                            status: 401
                        };
                        _this.handleError(errorObj);
                        throw new Error("2FA not valid");
                    }
                }
                console.log("after condition");
                _this.prepareLog(_this.logText, loginFormVal);
                // this.matomoTracker.trackEvent('checkin-patient-app', 'login', 'success');
                _this.commonService.getTemplateSettings().subscribe(function (templateResponse) {
                    _this.storageService.patientData = templateResponse.data;
                    if (_this.storageService.loginType === 'SMARTLINK' && _this.storageService.patientData.patientDetails['Patient']) {
                        _this.router.navigate([_this.routePaths.PHONE_VERIFICATION]);
                        _this.storageService.isOTPVerified = false;
                    }
                    else {
                        _this.router.navigate([_this.routePaths.SEND_OTP]);
                    }
                    // console.log(templateResponse);
                }, function (err) { return _this.handleError(err); });
            }, function (err) {
                _this.handleError(err);
                // this.matomoTracker.trackEvent('checkin-patient-app', 'login', 'fail');
            });
        }
    };
    LoginComponent.prototype.handleError = function (err) {
        this.logger.postEntry(LogLevel.Error, err.error.message, 'LoginComponent.isFormValid', this.traceId, err.status);
        var errorModalMessage = err.error.message || LoginErrorStatus[err.status] || LoginErrorStatus.Default;
        this.retainLoader = false;
        this.cdRef.detectChanges();
        this.popupService.showModal(ERROR, errorModalMessage, PopupType.ERROR, true);
    };
    LoginComponent.prototype.ngOnDestroy = function () {
        this.logger.postEntry(LogLevel.Info, 'Destroying Login Component.', 'LoginComponent.ngOnDestroy', this.traceId, 100);
        this.loginSubscription.unsubscribe();
        this.retainLoader = false;
    };
    LoginComponent.prototype.prepareLog = function (formName, data) {
        var formvalue = formName;
        if (data) {
            //console.log(this.logData);
            this.finalog[formvalue] = JSON.stringify(data);
        }
        //console.log(data);
        var textinfo = 'Getting log for Patient Id ' + this.storageService.patientId + ' Login Page:';
        this.logger.trackFormIoEvents(textinfo, JSON.stringify(this.finalog));
    };
    return LoginComponent;
}());
export { LoginComponent };
