import {NgModule} from '@angular/core';

import {MaskDateDirective} from './date-mask.directive';

@NgModule({
    declarations: [
        MaskDateDirective,
    ],
    exports: [
        MaskDateDirective
    ]
})
export class MaskDateModule {
}
