import { StorageService } from '@shared-services/storage.service';
import { DoCheck, ElementRef, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { equals } from '@shared-util/common.util';
import { LoaderOptions, LoaderType } from './loader.model';
var LoaderDirective = /** @class */ (function () {
    function LoaderDirective(el, storageService) {
        this.storageService = storageService;
        this.loaderVisible = new EventEmitter();
        this.loaderType = LoaderType.BUTTON;
        // the promise list
        this.promiseList = [];
        // save element
        this.promiseEl = el.nativeElement;
    }
    Object.defineProperty(LoaderDirective.prototype, "isPromiseActive", {
        // boolean to determine if promises were resolved
        get: function () {
            return this.promiseList.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    LoaderDirective.prototype.ngDoCheck = function () {
        var _this = this;
        if (!this.appLoader || !this.promiseEl) {
            return;
        }
        if (!this.appLoader.promises) {
            return;
        }
        if (!this.dectectOptionsChange()) {
            return;
        }
        this.promiseList = [];
        this.appLoader.promises.forEach(function (promise) {
            if (!promise || promise['fulfilled']) {
                return;
            }
            _this.addPromise(promise);
        });
        this.loaderType = this.appLoader.loaderType;
        if (this.appLoader.disabledFieldSet) {
            this.disabledFieldset = this.appLoader.disabledFieldSet;
        }
        if (this.isPromiseActive && (this.loaderType === LoaderType.FULL_PAGE)) {
            this.checkAndInitPromiseHandler();
        }
    };
    LoaderDirective.prototype.dectectOptionsChange = function () {
        if (equals(this.appLoader, this.optionsRecord)) {
            return false;
        }
        this.optionsRecord = this.appLoader;
        return true;
    };
    /**
     * Checks if all required parameters are there and inits the promise handler
     * @param {Object}promiseEl
     */
    LoaderDirective.prototype.checkAndInitPromiseHandler = function () {
        // check if element and promise is set
        if (this.promiseEl && this.isPromiseActive) {
            this.initLoadingState();
        }
    };
    /**
     * Handles everything to be triggered when the promiseEl is set
     * to loading state.
     * @param {Object}promiseEl
     */
    LoaderDirective.prototype.initLoadingState = function () {
        this.appendSpinnerTpl();
        if (this.loaderType === LoaderType.BUTTON) {
            this.disableEl(this.promiseEl);
            if (this.disabledFieldset) {
                this.disableEl(this.disabledFieldset);
            }
        }
    };
    /**
     * Handles everything to be triggered when loading is finished
     * @param {Object}loaderEl
     */
    LoaderDirective.prototype.cancelLoadingStateIfPromiseDone = function () {
        if (!this.isPromiseActive) {
            this.removeSpinnerTpl();
            if (this.loaderType === LoaderType.BUTTON) {
                this.enableEl(this.promiseEl);
                if (this.disabledFieldset) {
                    this.enableEl(this.disabledFieldset);
                }
            }
        }
    };
    /**
     * @param {Object}promiseEl
     */
    LoaderDirective.prototype.disableEl = function (el) {
        el.setAttribute('disabled', 'disabled');
    };
    /**
     * @param {Object}promiseEl
     */
    LoaderDirective.prototype.enableEl = function (el) {
        el.removeAttribute('disabled');
    };
    /**
     * $compile and append the spinner template.
     * @param {Object}promiseEl
     */
    LoaderDirective.prototype.appendSpinnerTpl = function () {
        var config = LoaderType.loaderConfig(this.loaderType);
        if (this.loaderType === LoaderType.FULL_PAGE) {
            this.promiseEl.classList.add('blur');
        }
        this.promiseEl.insertAdjacentHTML('beforeend', config.spinnerTpl);
        this.spinnerEl = this.promiseEl.getElementsByClassName(config.spinnerClass)[0];
        setTimeout(this.changeLoadingText.bind(this), 15000);
        this.loaderVisible.emit(true);
    };
    LoaderDirective.prototype.changeLoadingText = function () {
        if (this.promiseEl && this.promiseEl.getElementsByClassName('loading')[0]) {
            this.promiseEl.getElementsByClassName('loading')[0].innerHTML = 'Still loading...';
        }
    };
    LoaderDirective.prototype.removeSpinnerTpl = function () {
        if (this.loaderType === LoaderType.FULL_PAGE) {
            this.promiseEl.classList.remove('blur');
        }
        if (this.spinnerEl && this.spinnerEl.parentElement) {
            this.spinnerEl.parentElement.removeChild(this.spinnerEl);
        }
        this.loaderVisible.emit(false);
    };
    LoaderDirective.prototype.addPromise = function (promise) {
        var _this = this;
        if (this.promiseList.indexOf(promise) !== -1) {
            return;
        }
        this.promiseList.push(promise);
        if (promise instanceof Promise) {
            promise.then.call(promise, function () { return _this.finishPromise(promise); }, function () { return _this.finishPromise(promise); });
        }
        else if (promise instanceof Subscription) {
            promise.add(function () { return _this.finishPromise(promise); });
        }
    };
    LoaderDirective.prototype.finishPromise = function (promise) {
        promise['fulfilled'] = true;
        var index = this.promiseList.indexOf(promise);
        if (index === -1) {
            return;
        }
        this.promiseList.splice(index, 1);
        if (!this.isPromiseActive && (!this.appLoader.retainLoader || this.storageService.isSessionExpired)) {
            this.cancelLoadingStateIfPromiseDone();
        }
    };
    LoaderDirective.prototype.onClick = function () {
        // Click triggers @Input update
        // We need to use timeout to wait for @Input to update
        var _this = this;
        if (this.loaderType !== LoaderType.FULL_PAGE) {
            setTimeout(function () {
                // return if something else than a promise is passed
                if ((!_this.promiseList || _this.promiseList.length <= 0) && !_this.appLoader.ignorePromises) {
                    return;
                }
                _this.initLoadingState();
            }, 0);
        }
    };
    return LoaderDirective;
}());
export { LoaderDirective };
