import { interval } from 'rxjs';
import { Router } from '@angular/router';
import { AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@app/shared/services/auth.service';
import { StorageService } from '@app/shared/services/storage.service';
import { RoutePaths } from '@shared-constants/route-paths';
import { PopupType } from '@app/shared/components/popup/popup.model';
import { LoaderType } from '@app/shared/directives/loader/loader.model';
import { PopupService } from '@shared-components/popup/popup.service';
import { LogService } from '@shared-services/log.service';
import { LogLevel } from '@shared-models/common.model';
import * as moment from "moment-timezone";
import { ERROR, OTP_ERRORS } from '@app/shared/constants/error-messages';
// import { MatomoTracker } from 'ngx-matomo';
var OTPVerificationComponent = /** @class */ (function () {
    function OTPVerificationComponent(fb, authService, routePaths, router, cdRef, storageService, popupService, logger) {
        this.fb = fb;
        this.authService = authService;
        this.routePaths = routePaths;
        this.router = router;
        this.cdRef = cdRef;
        this.storageService = storageService;
        this.popupService = popupService;
        this.logger = logger;
        this.retainLoader = true;
        this.logData = {};
        this.finalog = {};
        this.arrayDate = {};
        this.logText = 'Application Log Verify OTP';
        this.loaderTypeEnum = LoaderType;
        this.otpMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
        this.defaultTimer = 180;
        this.currentTimer = '03:00';
        this.isTimerComplete = false;
        this.otpReceiveTimeTimezone = 'America/Chicago';
        this.dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
        this.traceId = this.logger.genTraceId();
    }
    Object.defineProperty(OTPVerificationComponent.prototype, "codeFormControl", {
        get: function () {
            return this.verifyFG.get('code');
        },
        enumerable: true,
        configurable: true
    });
    OTPVerificationComponent.prototype.ngOnInit = function () {
        this.logger.postEntry(LogLevel.Info, 'Initializing OTP Verification Component', 'OTPVerification.ngOnInit', this.traceId, 100);
        if (this.storageService.isDeviceNotAvailable) {
            this.title = 'Verification Code';
            this.verificationText = 'Please enter the verification code generated by the front desk.';
        }
        else {
            this.title = 'Identity Verification';
            this.verificationText = 'Please enter the verification code you received from the previous step.';
        }
        this.setupFormControls();
        if (typeof Worker !== 'undefined') {
            this.webWorker = new Worker('@app/web-worker.worker', { type: 'module' });
            this.webWorker.onmessage = function (data) {
                document.getElementById('otp_timer').innerText = data.data;
            };
        }
        this.startTimer();
    };
    OTPVerificationComponent.prototype.ngAfterViewInit = function () {
        this.logger.postEntry(LogLevel.Info, 'OTP Verification Component initialized', 'OTPVerification.ngAfterViewInit', this.traceId, 100);
    };
    OTPVerificationComponent.prototype.setupFormControls = function () {
        this.logger.postEntry(LogLevel.Info, 'Setting up OTP Verification form controls', 'OTPVerification.ngOnInit', this.traceId, 100);
        this.verifyFG = this.fb.group({
            code: this.fb.control('', Validators.required),
        });
    };
    OTPVerificationComponent.prototype.verifyCode = function () {
        var _this = this;
        if (this.isTimerComplete) {
            this.popupService.showModal(ERROR, OTP_ERRORS.OTPExpired, PopupType.ERROR, true);
            this.verifyFG.setValue({
                code: ''
            });
            return false;
        }
        this.logger.postEntry(LogLevel.Info, 'Verifying 2FA Code', 'OTPVerification.verifyCode', this.traceId, 100);
        this.retainLoader = true;
        if (this.storageService.isDeviceNotAvailable) {
            this.logger.postEntry(LogLevel.Info, 'User device marked not available', 'OTPVerification.verifyCode', this.traceId, 100);
            this.verifyOtpSubscription = this.authService.verifyOTPWithoutDevice(this.codeFormControl.value)
                .subscribe(function () {
                _this.otpVerificationSuccess();
            }, function (error) {
                _this.otpVerificationError(error);
            });
        }
        else {
            this.verifyOtpSubscription = this.authService.verifyOTP(this.verifyFG.value, moment().tz(this.otpReceiveTimeTimezone).format(this.dateTimeFormat))
                .subscribe(function () {
                _this.otpVerificationSuccess();
            }, function (error) {
                _this.otpVerificationError(error);
            });
        }
    };
    OTPVerificationComponent.prototype.otpVerificationSuccess = function () {
        this.logger.postEntry(LogLevel.Info, '2FA Code verified', 'OTPVerification.otpVerificationSuccess', this.traceId, 100);
        this.retainLoader = false;
        this.cdRef.detectChanges();
        this.codeFormControl.reset();
        this.storageService.isOTPVerified = true;
        this.logger.postEntry(LogLevel.Info, 'Navigating to ' + this.routePaths.APPOINTMENT_DETAILS, 'OTPVerification.otpVerificationSuccess', this.traceId, 100);
        this.arrayDate['OTPverified'] = 'Done';
        this.prepareLog(this.logText, this.arrayDate);
        this.router.navigate([this.routePaths.APPOINTMENT_DETAILS]);
        return false;
    };
    OTPVerificationComponent.prototype.otpVerificationError = function (error) {
        this.logger.postEntry(LogLevel.Info, 'Unable to verify 2FA code', 'OTPVerification.otpVerificationError', this.traceId, 100);
        this.arrayDate['OTPverified'] = 'False';
        this.prepareLog(this.logText, this.arrayDate);
        this.retainLoader = false;
        this.cdRef.detectChanges();
        this.handleError(error);
    };
    OTPVerificationComponent.prototype.otpBlur = function () {
        //console.log(this.codeFormControl.value);
        if (this.arrayDate['ReSendOTP'] == "") {
            delete this.arrayDate['ReSendOTP'];
        }
        if (this.arrayDate['OTPverified'] == 'False') {
            delete this.arrayDate['OTPverified'];
        }
        this.arrayDate['OTP'] = this.codeFormControl.value;
        this.prepareLog(this.logText, this.arrayDate);
    };
    OTPVerificationComponent.prototype.sendAnotherOTP = function () {
        this.logger.postEntry(LogLevel.Info, 'Sending new 2FA code', 'OTPVerification.sendAnotherOTP', this.traceId, 100);
        this.logger.postEntry(LogLevel.Info, 'Navigating to ' + this.routePaths.SEND_OTP, 'OTPVerification.sendAnotherOTP', this.traceId, 100);
        if (this.arrayDate['OTP'] == "") {
            delete this.arrayDate['OTP'];
        }
        this.arrayDate['ReSendOTP'] = 'Done';
        this.prepareLog(this.logText, this.arrayDate);
        this.router.navigate([this.routePaths.SEND_OTP]);
        return false;
    };
    OTPVerificationComponent.prototype.handleError = function (err) {
        this.logger.postEntry(LogLevel.Error, err.error.message, 'OTPVerification.handleError', this.traceId, err.status);
        this.codeFormControl.reset();
        this.popupService.showModal('Error', err.error.message, PopupType.ERROR, true);
    };
    OTPVerificationComponent.prototype.ngOnDestroy = function () {
        this.logger.postEntry(LogLevel.Info, 'Destroying OTP Verification Component', 'OTPVerification.ngOnDestroy', this.traceId, 100);
        this.retainLoader = false;
        this.mainTimer.unsubscribe();
    };
    OTPVerificationComponent.prototype.prepareLog = function (formName, data) {
        var formvalue = formName;
        if (data) {
            //console.log(this.logData);
            this.finalog[formvalue] = JSON.stringify(data);
            this.finalog['patientId'] = this.storageService.patientId;
            //this.finalog['appointmentId'] = this.storageService.appointmentId;   
        }
        var textinfo = 'Getting log for Patient Id ' + this.storageService.patientId + ' Verify OTP Page:';
        this.logger.trackFormIoEvents(textinfo, JSON.stringify(this.finalog));
    };
    OTPVerificationComponent.prototype.startTimer = function () {
        var _this = this;
        this.authService.updateOTPReceiveTime({ 'otpReceiveTime': moment().tz(this.otpReceiveTimeTimezone).format(this.dateTimeFormat) }).subscribe();
        var timer = this.defaultTimer - 1;
        var minutes;
        var seconds;
        this.mainTimer = interval(1000).subscribe(function () {
            minutes = Math.floor(timer / 60);
            seconds = Math.floor(timer % 60);
            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;
            _this.webWorker.postMessage(minutes + ':' + seconds);
            --timer;
            if (timer < 0) {
                _this.isTimerComplete = true;
                _this.mainTimer.unsubscribe();
            }
        });
    };
    return OTPVerificationComponent;
}());
export { OTPVerificationComponent };
