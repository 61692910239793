var PopupService = /** @class */ (function () {
    function PopupService() {
    }
    Object.defineProperty(PopupService.prototype, "modal", {
        set: function (value) {
            this.modalComponent = value;
        },
        enumerable: true,
        configurable: true
    });
    PopupService.prototype.showModal = function (heading, message, type, isVisible, btnName) {
        this.modalComponent.modal = {
            'heading': heading,
            'message': message,
            'type': type,
            'isVisible': isVisible,
            'btnName': btnName ? btnName : 'Ok'
        };
    };
    return PopupService;
}());
export { PopupService };
