import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {Subscription} from 'rxjs';

import {AuthService} from '@shared-services/auth.service';
import {CommonService} from '@shared-services/common.service';
import {LogLevel} from '@shared-models/common.model';
import {LogService} from '@shared-services/log.service';
import {StorageService} from '@shared-services/storage.service';
import {PopupType} from '@shared-components/popup/popup.model';
import {FormUtil} from '@shared-util/form.util';
import {LoaderType} from '@shared-directives/loader/loader.model';
import {RoutePaths} from '@shared-constants/route-paths';
import {ERROR, LoginType, Error as ErrorMessage} from '@shared-constants/error-messages';
import {PopupService} from '@shared-components/popup/popup.service';

import {PatientIDLoginComponent} from './patient-login/patientID-login.component';
import {DemographicsLoginComponent} from './demographics-login/demographic-login.component';
import {SmartLinkLoginComponent} from './smart-link/smart-link.component';
import {LoginErrorStatus} from './../../shared/constants/error-messages';

import { Md5 } from 'ts-md5/dist/md5';
// import { MatomoTracker } from 'ngx-matomo';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(PatientIDLoginComponent, {static: false}) patientIDLoginComponent;
    @ViewChild(DemographicsLoginComponent, {static: false}) demographicsLoginComponent;
    @ViewChild(SmartLinkLoginComponent, {static: false}) smartLinkLoginComponent;

    loginTypeFg: FormGroup;
    title = 'Welcome to eRegistration';
    loginSubscription: Subscription;
    loginType = LoginType;
    retainLoader = true;
    isAlternateLoginAllowed: boolean;
    loaderTypeEnum = LoaderType;
    private traceId: string;
    isSmartLinkEnabled = false;
    isCookieEnabled = true;
    smartLink = 'SMARTLINK';
    headerMessage;
    logData= {};
    finalog = {};
    arrayDate = {};
    logText = 'Application Log Login Page';

    get loginTypeValue(): string {
        debugger;
        if (!this.isSmartLinkEnabled) {
            if (!this.isAlternateLoginAllowed) {
                return this.loginType.DEMOGRAPHICS;
            } else {
                return this.loginTypeFg.get('loginType').value as string;
            }
        }
        else {
            return this.smartLink;
        }
    }

    constructor(public loginTypeFb: FormBuilder, private authService: AuthService, private commonService: CommonService,
        public storageService: StorageService, private router: Router,
        private routePaths: RoutePaths,
        private cdRef: ChangeDetectorRef,
        private popupService: PopupService,
        private sanitizer: DomSanitizer,
        private logger: LogService
        ) { }

    ngOnInit() {
        this.traceId = this.logger.genTraceId();
        this.logger.postEntry(
            LogLevel.Info,
            'Initializing Login component',
            'LoginComponent.ngOnInit',
            this.traceId,
            100
        );
        if(!navigator.cookieEnabled) {
            this.isCookieEnabled = false;
            this.popupService.showModal('Cookie Error', 'Use of this website requires your browser cookies being enabled. Please go to your browser settings to ensure that cookies are enabled and try again.', PopupType.ERROR, true, 'Refresh');
            return false;
        }

        if (this.storageService.patientToken) {
            var patientToken = this.storageService.patientToken;
            const decodeToken = atob(patientToken);
            const tokenArray = decodeToken.split('/');
            // this.storageService.patientToken = patientToken;
            this.storageService.locationId = tokenArray[1];
            this.storageService.patientId = tokenArray[2];
            this.storageService.appointmentId = tokenArray[3];
            this.isSmartLinkEnabled = true;
        } else {
            if (this.storageService.LoginConfiguration &&
                this.storageService.LoginConfiguration.demographics &&
                this.storageService.LoginConfiguration.patientId) {
                this.isAlternateLoginAllowed = this.storageService.LoginConfiguration.patientId.enabled;
            } else {
                this.isAlternateLoginAllowed = !this.storageService.LoginConfiguration.demographics.enabled;
            }
            this.isSmartLinkEnabled = false;
        }

        if (this.isAlternateLoginAllowed) {
            this.setupFormControl();
        }

        this.headerMessage = this.sanitizer.bypassSecurityTrustHtml(this.storageService.LoginInfoText);
    }

    ngAfterViewInit () {
        this.logger.postEntry(
            LogLevel.Info,
            'Login component initialized',
            'LoginComponent.ngAfterViewInit',
            this.traceId,
            100
        );
    }
    setupFormControl() {
        this.logger.postEntry(
            LogLevel.Info,
            'Setting up form control',
            'LoginComponent.setupFormControl',
            this.traceId,
            100
        );
        this.loginTypeFg = this.loginTypeFb.group({
            loginType: new FormControl(this.loginType.DEMOGRAPHICS)
        });
    }

    private isFormValid() {
        // mark forms as touched
        this.logger.postEntry(
            LogLevel.Info,
            'Checking login form is valid.',
            'LoginComponent.isFormValid',
            this.traceId,
            100
        );
        let isLoginFormValid = false;
        switch (this.loginTypeValue) {
            case this.loginType.DEMOGRAPHICS:
                FormUtil.markAsTouched(this.demographicsLoginComponent.demographicsLoginFg);
                isLoginFormValid = this.demographicsLoginComponent.isFormValid();
                break;
            case this.loginType.PATIENT_ID:
                FormUtil.markAsTouched(this.patientIDLoginComponent.patientIDLoginFg);
                isLoginFormValid = this.patientIDLoginComponent.isFormValid();
                break;
            case this.smartLink:
                FormUtil.markAsTouched(this.smartLinkLoginComponent.smartLinkLoginFg);
                isLoginFormValid = this.smartLinkLoginComponent.isFormValid();
                break;
            default:
                isLoginFormValid = false;
        }
        if (isLoginFormValid) {
            this.logger.postEntry(
                LogLevel.Info,
                'Login form is valid.',
                'LoginComponent.isFormValid',
                this.traceId,
                100
            );
        } else {
            this.logger.postEntry(
                LogLevel.Info,
                'Login form is invalid',
                'LoginComponent.isFormValid',
                this.traceId,
                100
            );
        }
        return isLoginFormValid;
    }

    login() {
        this.retainLoader = true;
        this.cdRef.detectChanges();
        this.logger.postEntry(
            LogLevel.Info,
            'Begin login.',
            'LoginComponent.login',
            this.traceId,
            100
        );

        if (this.isFormValid()) {
            this.storageService.loginType = this.loginTypeValue;
            var loginFormVal;
            if (this.loginTypeValue == 'SMARTLINK') {
                loginFormVal = this.smartLinkLoginComponent.getFormValue();
                const md5 = new Md5();
                const encodedDob = md5.appendStr(loginFormVal.DOB).end();
                if (encodedDob != this.storageService.accountInfoDetails.patientTokenData) {

                    this.popupService.showModal(ERROR, 'Entered DOB does not match our records', PopupType.ERROR, true);
                    return false;
                }
            }
            else {
                loginFormVal = this.loginTypeValue === this.loginType.DEMOGRAPHICS ? (this.demographicsLoginComponent.getFormValue())
                    : this.patientIDLoginComponent.getFormValue();
            }
            /*this.logger.postEntry( PHI
                LogLevel.Info,
                'Login Form Values: ' + JSON.stringify(loginFormVal),
                'LoginComponent.login',
                this.traceId,
                100
            );*/
            // check for a null suffix
            if (loginFormVal.suffix == null) {
                this.logger.postEntry(
                    LogLevel.Error,
                    'URL suffix is null',
                    'LoginComponent.login',
                    this.traceId,
                    100
                );
            }

            loginFormVal.LoginType = this.loginTypeValue;
            loginFormVal.DOB = loginFormVal.DOB.replace(/\//gi, '-');
            this.loginSubscription = this.authService.login(loginFormVal).subscribe((response) => {
                this.retainLoader = false;
                this.storageService.patientId = response.data.PatientID;
                this.authService.patientDetails = response.data.Patient;
                this.storageService.authToken = response.data.AcccessToken;
                this.storageService.authExpiry = response.data.TokenExpiry;
                this.storageService.storeAuthTokenInLocalStorage();


                // If patient login, we need to check for valid 2FA items
                if (this.loginTypeValue === this.loginType.DEMOGRAPHICS) {
                    const [patient] = response.data.Patient
                    const isTwoFactorInvalid = !patient.Phone && !patient.Email
                    // check for 2FA items, throw an error to display in modal if not available
                    if (isTwoFactorInvalid) {
                        const errorObj = {
                            error: {
                                message: ErrorMessage.LOGIN.INVALID_2FA
                            },
                            status: 401
                        }

                        this.handleError(errorObj);
                        throw new Error("2FA not valid");
                    }
                }
                console.log("after condition");
                this.prepareLog(this.logText, loginFormVal);

                // this.matomoTracker.trackEvent('checkin-patient-app', 'login', 'success');
                this.commonService.getTemplateSettings().subscribe((templateResponse) => {
                    this.storageService.patientData = templateResponse.data;
                    if (this.storageService.loginType === 'SMARTLINK' && this.storageService.patientData.patientDetails['Patient']) {
                            this.router.navigate([this.routePaths.PHONE_VERIFICATION]);
                            this.storageService.isOTPVerified = false;
                    } else {
                        this.router.navigate([this.routePaths.SEND_OTP]);
                    }
                   // console.log(templateResponse);

                }, (err) => this.handleError(err));
            }, (err) => {
                this.handleError(err);
                // this.matomoTracker.trackEvent('checkin-patient-app', 'login', 'fail');
            });

        }
    }

    private handleError(err) {
        this.logger.postEntry(
            LogLevel.Error,
            err.error.message,
            'LoginComponent.isFormValid',
            this.traceId,
            err.status
        );

        const errorModalMessage = err.error.message || LoginErrorStatus[err.status] || LoginErrorStatus.Default;

        this.retainLoader = false;
        this.cdRef.detectChanges();
        this.popupService.showModal(ERROR, errorModalMessage, PopupType.ERROR, true);
    }

    ngOnDestroy() {
        this.logger.postEntry(
            LogLevel.Info,
            'Destroying Login Component.',
            'LoginComponent.ngOnDestroy',
            this.traceId,
            100
        );
        this.loginSubscription.unsubscribe();
        this.retainLoader = false;
    }

    private prepareLog(formName, data) {
        var formvalue = formName
        if (data) {
            //console.log(this.logData);
            this.finalog[formvalue] = JSON.stringify(data);
        }
        //console.log(data);
        var textinfo = 'Getting log for Patient Id ' + this.storageService.patientId + ' Login Page:';
        this.logger.trackFormIoEvents(textinfo, JSON.stringify(this.finalog));
    }
}
