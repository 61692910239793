var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { of as observableOf } from 'rxjs';
import { LogPublisher } from '@app/shared/publishers/log-publisher';
import { environment } from '@environments/environment';
var LogWebApi = /** @class */ (function (_super) {
    __extends(LogWebApi, _super);
    function LogWebApi(injector, logLocalStorage, httpService, storageService) {
        var _this = 
        // Must call super() from derived classes
        _super.call(this, injector) || this;
        _this.injector = injector;
        _this.logLocalStorage = logLocalStorage;
        _this.httpService = httpService;
        _this.storageService = storageService;
        return _this;
        // Set location
    }
    // Add log entry to back end data store
    LogWebApi.prototype.log = function (entry) {
        var _this = this;
        // Log the error to the console
        if (this.logLocalStorage) {
            this.values = JSON.parse(localStorage.getItem(this.logLocalStorage.logPublisherConfig.loggerLocation)) || [];
            if (this.values.indexOf(entry) === -1) {
                this.values.push(entry);
            }
            else {
                this.values.push(entry);
            }
        }
        // Send error to server
        var options = {};
        this.httpService.post(environment.baseUrl + '' + this.logPublisherConfig.loggerLocation, this.values, options)
            .subscribe(function (res) {
            _this.clear();
            return observableOf(true);
        });
        return observableOf(entry);
    };
    // Clear all log entries from local storage
    LogWebApi.prototype.clear = function () {
        if (this.logLocalStorage) {
            this.logLocalStorage.clear();
        }
        return observableOf(true);
    };
    return LogWebApi;
}(LogPublisher));
export { LogWebApi };
