import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import {PopupType} from '@shared-components/popup/popup.model';
import { RoutePaths } from '@shared-constants/route-paths';
import { CommonService } from '@app/shared/services/common.service';
import { StorageService } from '@app/shared/services/storage.service';
import {PopupService} from '@shared-components/popup/popup.service';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import {ERROR} from '@shared-constants/error-messages';

@Injectable()
export class AppResolver implements Resolve<any> {
    constructor(
        private router: Router,
        private commonService: CommonService,
        private storage: StorageService,
        private popupService: PopupService,
        private localStorage: LocalStorageService,
        private routePaths: RoutePaths,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        let patientToken = (!route.parent.queryParams.token) ? '' :  route.parent.queryParams.token;
        const previewLinkData = { previewLink: window.location.href, suffix: route.parent.url[0].path, patientToken: patientToken};
        //const queryparams = route.queryParams;
        if(!navigator.cookieEnabled) {
           // console.log("Use of this website requires your browser cookies being enabled. Please go to your browser settings to ensure that cookies are enabled and try again.");
            return false;
        }
        if (previewLinkData.suffix && previewLinkData.suffix.length > 1) {
            this.storage.clear();
            this.storage.isSuffixResolved = false;
            this.localStorage.setItemInLocalStorage('urlSuffix', previewLinkData.suffix);

            this.localStorage.setItemInLocalStorage('patientToken', patientToken);  
            if (patientToken) {
                // translate base64url (RFC 4648 §5) to base64 (RFC 4648 §4) - `tr/-_/+\//`
                patientToken = patientToken.replace(/[-_]/g, (r) => ({'-': '+', '_': '/'})[r]);
                const decodeToken = atob(patientToken);
                const tokenArray = decodeToken.split('/');
                this.localStorage.setItemInLocalStorage('patientToken', patientToken);  
                this.localStorage.setItemInLocalStorage('locationId', tokenArray[1]);
                this.localStorage.setItemInLocalStorage('patientId', tokenArray[2]);
                this.localStorage.setItemInLocalStorage('appointmentId', tokenArray[3]);
            }                          

           // if (!this.storage.accountInfoDetails || previewLinkData.previewLink.includes('login')) {
                return this.commonService.getAccountInfoDetails(previewLinkData).pipe(
                    tap((response) => {
                        this.storage.accountInfoDetails = response.data;
                        this.storage.isSuffixResolved = true;

                    }),
                    catchError((error) => {
                        this.localStorage.resetLocalStorage();
                        this.storage.isSuffixResolved = true;
                        this.router.navigate([this.routePaths.NOT_FOUND]);
                        return EMPTY;
                    }),
                );
           /* } else {
                this.storage.isSuffixResolved = true;
                return this.storage.accountInfoDetails;
            }*/
        }
    }
}
