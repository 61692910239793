import {Injectable} from '@angular/core';

import {CommonService} from '@shared-services/common.service';

@Injectable()
export class AppConfigService {

    constructor(private commonService: CommonService) {
    }

    public load(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.commonService.getAppConfig()
                .subscribe((response) => {
                        this.commonService.appConfig = response;
                        this.commonService.patientConfig$.next(true);
                        resolve(true);
                    },
                    () => reject());
        });
    }

}
