<div class="form-wrapper" *ngIf="sendOtpFg">
    <fieldset #disabledFieldset>
        <app-header [title]="title"></app-header>
        <div id="main">
            <div class="container">
                <p>Before proceeding, we need to verify your identity. How would you like to receive your verification
                    code?</p>
                <form [formGroup]="sendOtpFg">
                    <div *ngFor="let patientDetail of patientDetails; let i = index"
                         class="form-group form-field-type-radio">
                        <div class="radio" *ngIf="patientDetail.Phone">
                            <label class="control-label" for="verification-method-sms{{i}}">
                                <input id="verification-method-sms{{i}}" [value]="patientDetail.Phone"
                                    (click)="getValue(patientDetail.Phone)"
                                       formControlName="destination"
                                       type="radio">
                                <span>SMS {{patientDetail.Phone | hidePhone}}</span>
                                <!-- XXX-XXX-4035 -->
                            </label>
                        </div>
                        <div class="radio" *ngIf="patientDetail.Email">
                            <label class="control-label" for="verification-method-email{{i}}">
                                <input id="verification-method-email{{i}}" [value]="patientDetail.Email"
                                    (click)="getValue(patientDetail.Email)"
                                       formControlName="destination" type="radio">
                                <span>Email {{patientDetail.Email | hideEmail}}</span>
                                <!-- XXX@hotmail.com -->
                            </label>
                        </div>
                    </div>
                    <div class="info-wrapper no-device">
                        <h5 class="no-device-text" (click)="donotHaveDevice()">Don't have a device?</h5>
                    </div>
                    <div class="info-wrapper" style="cursor: pointer;">
                        <a class="info-link" (click)="showInfo()">
                            <i class="icon-info">Info</i>
                            <span>Why do I need
                                <br/> a verification code?</span>
                        </a>
                    </div>
                    <button type="submit" [disabled]="sendOtpFg.invalid" class="btn btn-primary btn-full-width"
                            (click)="sendOtp()"
                            [appLoader]="{promises: [sendOtpSubscription], loaderType: loaderTypeEnum.BUTTON, disabledFieldSet: disabledFieldset}">
                        Continue
                    </button>
                </form>
            </div>
        </div>
    </fieldset>
</div>

