import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {StorageService} from '@shared-services/storage.service';
import {RoutePaths} from '@shared-constants/route-paths';
import {LoginType} from '@shared-constants/error-messages';


@Injectable()
export class OTPGuard implements CanActivate {

    constructor(private router: Router, private storageService: StorageService, private routePaths: RoutePaths) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        /*if (this.storageService.loginType === 'SMARTLINK') {
            if (this.storageService.patientData.patientDetails['Patient']) {
                this.router.navigate([this.routePaths.PHONE_VERIFICATION]);
                this.storageService.isOTPVerified = false;
            }
            return false;
        }*/

        if ((this.storageService.LoginConfiguration && this.storageService.LoginConfiguration.demographics && 
            this.storageService.LoginConfiguration.patientId && 
            this.storageService.LoginConfiguration.patientId.noOtp 
            && this.storageService.loginType === LoginType.PATIENT_ID) ) {
            this.router.navigate([this.routePaths.APPOINTMENT_DETAILS]);
            this.storageService.isOTPVerified = true;
            return false;
        }
        return !this.storageService.isOTPVerified;
    }
}
