<div *ngIf="isSuffixResolved" class="fullpage-loader">
    <div class="overlay"></div>
    <div class="loading-container">
        <div class="sk-circle">
            <div class="sk-circle1 sk-child1"></div>
            <div class="sk-circle2 sk-child1"></div>
            <div class="sk-circle3 sk-child1"></div>
            <div class="sk-circle4 sk-child1"></div>
            <div class="sk-circle5 sk-child1"></div>
            <div class="sk-circle6 sk-child1"></div>
            <div class="sk-circle7 sk-child1"></div>
            <div class="sk-circle8 sk-child1"></div>
            <div class="sk-circle9 sk-child1"></div>
            <div class="sk-circle10 sk-child1"></div>
            <div class="sk-circle11 sk-child1"></div>
            <div class="sk-circle12 sk-child1"></div>
        </div>
        <div class="loading">Loading...</div>
    </div>
</div>
<router-outlet>
</router-outlet>
<app-popup #modal></app-popup>
