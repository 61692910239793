import { Injectable } from '@angular/core';
import { DraftInfo } from '@app/auth/auth.model';

import { AccountInfoDetails, LoginConfig, PatientData } from '@shared-models/common.model';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { LogService } from '@shared-services/log.service';

@Injectable()
export class StorageService {
    _authToken: string;
    _authExpiry: number;
    _appCounter: number;

    getItemFromLocalStorage(key) {
        const value = this.localStorage.getLocalStorage(key);
        if (value) {
            return this.localStorage.getLocalStorage(key);
        } else {
            return false;
        }
    }

    get appCounter(): number {
        return this.getItemFromLocalStorage('_appCounter');
    }

    set appCounter(value) {
        this._appCounter = value;
        this.localStorage.setItemInLocalStorage('_appCounter', value);
    }

    get lastPage(): boolean {
        return this.getItemFromLocalStorage('lastPage');
    }

    set lastPage(value: boolean) {
        this.localStorage.setItemInLocalStorage('lastPage', value);
    }

    set loginType(value: string) {
        this.localStorage.setItemInLocalStorage('loginType', value);
    }

    get loginType(): string {
        return this.getItemFromLocalStorage('loginType');
    }

    get isPatientCheckedIn(): boolean {
        return this.getItemFromLocalStorage('isPatientCheckedIn');
    }

    set isPatientCheckedIn(value: boolean) {
        this.localStorage.setItemInLocalStorage('isPatientCheckedIn', value);
    }

    get isOTPVerified(): boolean {
        return this.getItemFromLocalStorage('isOTPVerified');
    }

    set isOTPVerified(value: boolean) {
        this.localStorage.setItemInLocalStorage('isOTPVerified', value);
    }

    get isDemographicsSubmitted(): boolean {
        return this.getItemFromLocalStorage('isDemographicsSubmitted');
    }

    set isDemographicsSubmitted(value: boolean) {
        this.localStorage.setItemInLocalStorage('isDemographicsSubmitted', value);
    }

    get isPrimaryInsuranceSubmitted(): boolean {
        return this.getItemFromLocalStorage('isPrimaryInsuranceSubmitted');
    }

    set isPrimaryInsuranceSubmitted(value: boolean) {
        this.localStorage.setItemInLocalStorage('isPrimaryInsuranceSubmitted', value);
    }

    get isSecondaryInsuranceSubmitted(): boolean {
        return this.getItemFromLocalStorage('isSecondaryInsuranceSubmitted');
    }

    set isSecondaryInsuranceSubmitted(value: boolean) {
        this.localStorage.setItemInLocalStorage('isSecondaryInsuranceSubmitted', value);
    }

    get isDriverLicenseSubmitted(): boolean {
        return this.getItemFromLocalStorage('isDriverLicenseSubmitted');
    }

    set isDriverLicenseSubmitted(value: boolean) {
        this.localStorage.setItemInLocalStorage('isDriverLicenseSubmitted', value);
    }

    get isPatientImageSubmitted(): boolean {
        return this.getItemFromLocalStorage('isPatientImageSubmitted');
    }

    set isPatientImageSubmitted(value: boolean) {
        this.localStorage.setItemInLocalStorage('isPatientImageSubmitted', value);
    }

    get isAgreementAndConsentSubmitted(): boolean {
        return this.getItemFromLocalStorage('isAgreementAndConsentSubmitted');
    }

    set isAgreementAndConsentSubmitted(value: boolean) {
        this.localStorage.setItemInLocalStorage('isAgreementAndConsentSubmitted', value);
    }

    get isCompletePage(): boolean {
        return this.getItemFromLocalStorage('isCompletePage');
    }

    set isCompletePage(value: boolean) {
        this.localStorage.setItemInLocalStorage('isCompletePage', value);
    }

    get isDeviceNotAvailable(): boolean {
        return this.getItemFromLocalStorage('isDeviceNotAvailable');
    }

    set isDeviceNotAvailable(value: boolean) {
        this.localStorage.setItemInLocalStorage('isDeviceNotAvailable', value);
    }

    get isSessionExpired(): boolean {
        return this.getItemFromLocalStorage('isSessionExpired');
    }

    set isSessionExpired(value: boolean) {
        this.localStorage.setItemInLocalStorage('isSessionExpired', value);
    }

    set patientBalance(value: number) {
        this.localStorage.setItemInLocalStorage('patientTotalBalance', value);
    }

    get patientBalance(): number {
        return this.getItemFromLocalStorage('patientTotalBalance');
    }

    set mdpayEnabled(value: boolean) {
        this.localStorage.setItemInLocalStorage('mdpayEnabled', value);
    }

    get mdpayEnabled(): boolean {
        return this.getItemFromLocalStorage('mdpayEnabled');
    }

    set patientToken(value: string) {
        this.localStorage.setItemInLocalStorage('patientToken', value);
    }

    get patientToken(): string {
        return this.getItemFromLocalStorage('patientToken');
    }

    set isReturnedFronPhoneVerification(value: boolean) {
        this.localStorage.setItemInLocalStorage('isReturnedFronPhoneVerification', value);
    }

    get isReturnedFronPhoneVerification() {
        return this.getItemFromLocalStorage('isReturnedFronPhoneVerification');
    }

    get isSuffixResolved(): boolean {
        if (this.localStorage.getLocalStorage('isSuffixResolved') !== null) {
            return this.localStorage.getLocalStorage('isSuffixResolved');
        } else {
            return true;
        }
    }

    set isSuffixResolved(value: boolean) {
        this.localStorage.setItemInLocalStorage('isSuffixResolved', value);
    }

    get authToken(): string {
        if (this.localStorage.getLocalStorage('authToken')) {
            return this.localStorage.getLocalStorage('authToken');
        } else {
            return this._authToken;
        }
    }

    set authToken(token: string) {
        this._authToken = token;
    }

    get authExpiry(): number {
        if (this.localStorage.getLocalStorage('authExpiry')) {
            return this.localStorage.getLocalStorage('authExpiry');
        } else {
            return this._authExpiry;
        }
    }

    set authExpiry(expiry: number) {
        this._authExpiry = expiry;
    }

    get appointmentId(): string {
        return this.localStorage.getLocalStorage('appointmentId');
    }

    set appointmentId(apptId: string) {
        this.localStorage.setItemInLocalStorage('appointmentId', apptId);
    }

    get locationId(): string {
        return this.localStorage.getLocalStorage('locationId');
    }

    set locationId(locId: string) {
        this.localStorage.setItemInLocalStorage('locationId', locId);
    }

    get patientId(): string {
        return this.localStorage.getLocalStorage('patientId');
    }

    set patientId(pId: string) {
        this.localStorage.setItemInLocalStorage('patientId', pId);
    }

    get urlSuffix(): string {
        return this.localStorage.getLocalStorage('urlSuffix');
    }

    get accountInfoDetails(): AccountInfoDetails {
        return this.localStorage.getLocalStorage('accountInfoDetails');
    }

    set accountInfoDetails(accInfoDetails: AccountInfoDetails) {
        this.localStorage.setItemInLocalStorage('accountInfoDetails', accInfoDetails);
    }

    set draftPage (page: DraftInfo) {
        this.localStorage.setItemInLocalStorage('draftPage', page);
    }

    get draftPage (): DraftInfo {
        return this.localStorage.getLocalStorage('draftPage');
    }

    set formId (formId) {
        this.localStorage.setItemInLocalStorage('formId', formId);
    }

    get formId () :string {
        return this.localStorage.getLocalStorage('formId');
    }

    set oldFormId (formId) {
        this.localStorage.setItemInLocalStorage('oldformId', formId);
    }

    get oldFormId () :string {
        return this.localStorage.getLocalStorage('oldformId');
    }
    

    get accountCode(): string {
        if (this.accountInfoDetails) {
            return this.accountInfoDetails.Company;
        }
        return '';
    }

    get logo(): string {
        if (this.accountInfoDetails) {
            return this.accountInfoDetails.Logo;
        }
        return '';
    }

    get ButtonColor(): string {
        if (this.accountInfoDetails) {
            return this.accountInfoDetails.ButtonColor;
        }
        return '';
    }

    get TextColor(): string {
        if (this.accountInfoDetails) {
            return this.accountInfoDetails.TextColor;
        }
        return '';
    }

    get headerColor(): string {
        if (this.accountInfoDetails) {
            return this.accountInfoDetails.HeaderColor;
        }
        return '';
    }

    get ButtonFontColor(): string {
        if (this.accountInfoDetails) {
            return this.accountInfoDetails.ButtonFontColor;
        }
        return '';
    }

    get patientData(): PatientData {
        return this.localStorage.getLocalStorage('patientData');
    }

    set patientData(pData: PatientData) {
        this.localStorage.setItemInLocalStorage('patientData', pData);
    }

    get isPrimaryInsuranceEnabled(): boolean {
        if (this.patientData) {
            return this.patientData.patientSettings.IsPrimaryInsurance;
        }
        return false;
    }

    get isSecondaryInsuranceEnabled(): boolean {
        if (this.patientData) {
            return this.patientData.patientSettings.IsSecondaryInsurance;
        }
        return false;
    }

    get IsPrimaryInsuranceImageOnly(): boolean {
        if (this.patientData) {
            return this.patientData.patientSettings.IsPrimaryInsuranceImageOnly;
        }
        return false;
    }

    get IsSecondaryInsuranceImageOnly(): boolean {
        if (this.patientData) {
            return this.patientData.patientSettings.IsSecondaryInsuranceImageOnly;
        }
        return false;
    }

    get IsDriverLicenseImageOnly(): boolean {
        if (this.patientData) {
            return this.patientData.patientSettings.IsDriverLicenseImageOnly;
        }
        return false;
    }

    get isAcuantEnabled(): boolean {
        if (this.patientData) {
            return this.patientData.patientSettings.IsAcuantEnabled;
        }
        return false;
    }

    get acuantLicenseKey(): string {
        if (this.patientData) {
            return this.patientData.patientSettings.LicenseKey;
        }
        return '';
    }

    get isDriverLicenseEnabled(): boolean {
        if (this.patientData) {
            return this.patientData.patientSettings.IsDriverLicenseEnable;
        }
        return false;
    }

    get isPatientImageEnabled(): boolean {
        if (this.patientData) {
            return this.patientData.patientSettings.IsImageUploadEnabled;
        }
        return false;
    }

    get patientImageText(): string {
        if (this.patientData && this.patientData.patientSettings.ImageText) {
            return this.patientData.patientSettings.ImageText;
        }
        return '';
    }

    get isAgreementAndConsentEnabled(): boolean {
        if (this.patientData) {
            return this.patientData.patientSettings.IsAgreementAndConsentEnable;
        }
        return false;
    }

    get AgreementAndConsentText(): string {
        if (this.patientData && this.patientData.patientSettings.AgreementAndConsentText) {
            return this.patientData.patientSettings.AgreementAndConsentText;
        }
        return '';
    }

    get templateTypeID(): number {
        if (this.patientData) {
            return this.patientData.patientSettings.TemplateTypeID;
        }
        return;
    }

    get LoginInfoText(): string {
        if (this.accountInfoDetails) {
            return this.accountInfoDetails.LoginInfo;
        }
        return '';
    }

    get LoginConfiguration(): LoginConfig {
        if (this.accountInfoDetails) {
            return this.accountInfoDetails.LoginConfig;
        }
    }

    getLocalStorage(key) {
        return JSON.parse(localStorage.getItem(key));
    }

    storeAuthTokenInLocalStorage() {
        this.localStorage.setItemInLocalStorage('authToken', this._authToken);
        const time = new Date();
        time.setSeconds(time.getSeconds() + this._authExpiry);
        this.localStorage.setItemInLocalStorage('authExpiry', time.getTime());
    }

    clear() {
        this.isPatientCheckedIn = false;
        //this.isOTPVerified = false;
        this.lastPage = false;
        this.isDemographicsSubmitted = false;
        this.isPrimaryInsuranceSubmitted = false;
        this.isSecondaryInsuranceSubmitted = false;
        this.isDriverLicenseSubmitted = false;
        this.isAgreementAndConsentSubmitted = false;
        this.isCompletePage = false;
        this.isDeviceNotAvailable = false;
        this.isSessionExpired = false;
        this.isPatientImageSubmitted = false;

        this._authToken = '';
        this._authExpiry = 0;
        this.localStorage.deleteLocalStorage('authToken');
        this.localStorage.deleteLocalStorage('authExpiry');
        this.localStorage.deleteLocalStorage('patientId');
        this.localStorage.deleteLocalStorage('appointmentId');
        this.localStorage.deleteLocalStorage('patientDetails');
        this.localStorage.deleteLocalStorage('isOTPVerified');
        this.localStorage.deleteLocalStorage('draftPage');
    }

    constructor(private localStorage: LocalStorageService) {}
}
