import {LogLevel, PatientData, TemplateType} from './../models/common.model';
import {Injectable} from '@angular/core';

import {AccountInfoDetails, AppConfig, CommonResponse, State} from '@app/shared/models/common.model';
import {RequestUrls} from '@app/shared/constants/request-urls';

import {HttpService} from '@app/shared/services/http.service';
import {StorageService} from '@app/shared/services/storage.service';
import {LogService} from "@shared-services/log.service";
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    public appConfig: AppConfig;
    private traceId: string;

    patientConfig$ = new BehaviorSubject(false);

    get gender(): string[] {
        this.logger.postEntry(
            LogLevel.Info,
            'Getting gender from app configuration.',
            'CommonService.get gender',
            this.traceId,
            100
        );
        return this.appConfig.Gender;
    }

    get states(): State[] {
        this.logger.postEntry(
            LogLevel.Info,
            'Getting states from app configuration.',
            'CommonService.get states',
            this.traceId,
            100
        );
        return this.appConfig.States;
    }

    get relationships(): string[] {
        this.logger.postEntry(
            LogLevel.Info,
            'Getting relationships from app configuration.',
            'CommonService.get relationships',
            this.traceId,
            100
        );
        return this.appConfig.Relationship;
    }

    get templateTypes(): TemplateType[] {
        this.logger.postEntry(
            LogLevel.Info,
            'Getting states from app configuration.',
            'CommonService.get templateTypes',
            this.traceId,
            100
        );
        return this.appConfig.TemplateTypes;
    }

    constructor(private http: HttpService, private storageService: StorageService, private logger: LogService) {
        this.traceId = this.logger.genTraceId();
    }

    getAccountInfoDetails(previewLinkData) {
        this.logger.postEntry(
            LogLevel.Info,
            'Posting account info detail request to: ' + RequestUrls.ANONYMOUS.GET_ACCOUNT_INFO_DETAILS,
            'CommonService.getAccountInfoDetails',
            this.traceId,
            100
        );
        return this.http.post<CommonResponse<AccountInfoDetails>>(RequestUrls.ANONYMOUS.GET_ACCOUNT_INFO_DETAILS, previewLinkData);
    }

    getAppConfig() {
        this.logger.postEntry(
            LogLevel.Info,
            'Posting account info detail request to: ' + RequestUrls.ANONYMOUS.APP_CONFIG,
            'CommonService.getAppConfig',
            this.traceId,
            100
        );
        return this.http.get<AppConfig>(RequestUrls.ANONYMOUS.APP_CONFIG);
    }

    isFileGreaterThanSize(file: File, size: Number): boolean {
        this.logger.postEntry(
            LogLevel.Info,
            'Checking file size',
            'CommonService.isFileGreaterThanSize',
            this.traceId,
            100
        );
        if (file.size > size) {
            this.logger.postEntry(
                LogLevel.Warning,
                'File size ' + JSON.stringify(file.size) + ' exceeds maximum size of ' + JSON.stringify(size),
                'CommonService.isFileGreaterThanSize',
                this.traceId,
                100
            );
            return  true;
        }
        this.logger.postEntry(
            LogLevel.Info,
            'File size within parameters',
            'CommonService.isFileGreaterThanSize',
            this.traceId,
            100
        );
        return false;
    }

    getTemplateSettings() {
        this.logger.postEntry(
            LogLevel.Info,
            'Getting template settings',
            'CommonService.getTemplateSettings',
            this.traceId,
            100
        );
        const reqData: {suffix: string, patientId: string, locationId?: string} = {
            suffix: this.storageService.urlSuffix,
            patientId: this.storageService.patientId
        };
        if (this.storageService.locationId !== undefined) {
            reqData.locationId = this.storageService.locationId
        }
        this.logger.postEntry(
            LogLevel.Info,
            'Template request data: ' + JSON.stringify(reqData),
            'CommonService.getAppointmentDetails',
            this.traceId,
            100
        );
        this.logger.postEntry(
            LogLevel.Info,
            'Posting template request to: ' + RequestUrls.AUTHENTICATED.GET_TEMPLATE_SETTINGS,
            'CommonService.getAppointmentDetails',
            this.traceId,
            100
        );
        return this.http.post<CommonResponse<PatientData>>(RequestUrls.AUTHENTICATED.GET_TEMPLATE_SETTINGS, reqData);
    }
}
