import {Pipe, PipeTransform} from '@angular/core';


@Pipe({name: 'hideEmail', pure: false})
export class HideEmailPipe implements PipeTransform {
    transform(input: string): string {

        const localPartIndex = input.indexOf('@');
        for (let x = 1; x < localPartIndex - 1; x++) {
            input = input.substr(0, x) + 'X' + input.substr(x + 1);
        }
        return input;
    }
}
