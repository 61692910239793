import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';

import {BootstrapFormGroup} from '@shared-modules/ng-bootstrap-input/bootstrap-form-group';
import {BootstrapInputConfigInterface} from '@shared-modules/ng-bootstrap-input/bootstrap-input-config';
import {LogLevel, State} from '@shared-models/common.model';
import {StorageService} from '@shared-services/storage.service';
import {CommonService} from '@shared-services/common.service';
import {LoaderType} from '@app/shared/directives/loader/loader.model';
import {Error} from '@shared-constants/error-messages';
// import { MatomoTracker } from 'ngx-matomo';

import * as moment from 'moment';
import { FlatPickrOutputOptions, FlatpickrDirective } from 'angularx-flatpickr/flatpickr.directive';
import {LogService} from '@shared-services/log.service';

@Component({
    selector: 'app-demographics',
    templateUrl: './demographic-login.component.html',
    styleUrls: ['./demographic-login.component.scss']
})
export class DemographicsLoginComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('dobPicker', {static: false}) dobPicker: ElementRef;
    // startOption:FlatpickrDirective={
    //     maxDate:new Date()

    // }

    public demographicsLoginFg: BootstrapFormGroup = <BootstrapFormGroup>this.loginFb.group({});
    readonly errors = Error;
    maxDate = new Date();
    minDate = new Date('01/01/1900');
    isDatePickerOpen = false;
    states: State[];
    gender: string[];
    retainLoader = true;
    prevSelectedDate: string;
    private traceId: string;
    firstNameConfig: BootstrapInputConfigInterface;
    lastNameConfig: BootstrapInputConfigInterface;
   // streetAddressConfig: BootstrapInputConfigInterface;
   // cityConfig: BootstrapInputConfigInterface;
   // zipConfig: BootstrapInputConfigInterface;
   // cellConfig: BootstrapInputConfigInterface;
   // homeConfig: BootstrapInputConfigInterface;
    loaderTypeEnum = LoaderType;
    onFocus = false;
    checkIcon = false;
    dateErrorPresent = false;
    tempDate: any;
   // phoneMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
   // zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

    constructor(public loginFb: FormBuilder, private commonService: CommonService,
                public storageService: StorageService,
                private logger: LogService) {
    }

    /*get addressFormGroup(): BootstrapFormGroup {
        return this.demographicsLoginFg.get('Address') as BootstrapFormGroup;
    }

    get phoneFormGroup(): BootstrapFormGroup {
        return this.demographicsLoginFg.get('PhoneNumber') as BootstrapFormGroup;
    }*/

    get dobFormControl(): FormControl {
        return this.demographicsLoginFg.get('DOB') as FormControl;
    }

    ngOnInit() {
        this.logger.postEntry(
            LogLevel.Info,
            'Initializing Demographic Login Component',
            'DemographicLogin.ngOnInit',
            this.traceId,
            100
        );
        this.storageService.clear();
        this.states = this.commonService.states;
        this.gender = this.commonService.gender;
        this.setupFormControls();
        // this.dobFormControl.valueChanges.subscribe(()=>{
        //     this.dobFormControl.value.trim()

        //      this.tempDate=new Date(this.dobFormControl.value)

        // this.dobFormControl.statusChanges.subscribe(()=>{
        //     if(this.dobFormControl.valid && (this.maxDate.getTime()>= this.tempDate.getTime()) )
        //     {
        //         this.checkIcon=true
        //         this.dateErrorPresent=false
        //     }
        //     else{
        //         this.checkIcon=false
        //         this.dateErrorPresent=true
        //     }

        // })
        // })
    }

    ngAfterViewInit() {
        this.logger.postEntry(
            LogLevel.Info,
            'Demographic Login Component initialized',
            'DemographicLogin.ngAfterViewInit',
            this.traceId,
            100
        );
    }

    ngOnDestroy() {
        this.logger.postEntry(
            LogLevel.Info,
            'Destroying Demographic Login Component',
            'DemographicLogin.ngOnDestroy',
            this.traceId,
            100
        );
    }

    isFormValid() {
        this.logger.postEntry(
            LogLevel.Info,
            'Checking if demographics form is valid',
            'DemographicLogin.isFormValid',
            this.traceId,
            100
        );
        return this.demographicsLoginFg.valid;
    }

    setupFormControls() {
        this.logger.postEntry(
            LogLevel.Info,
            'Setting up demographics form controls',
            'DemographicComponent.setupFormControls',
            this.traceId,
            100
        );
        this.demographicsLoginFg = <BootstrapFormGroup>this.loginFb.group({
            DOB: this.loginFb.control('', Validators.required),
            PhoneNumber: <BootstrapFormGroup>this.loginFb.group({}),
            Address: <BootstrapFormGroup>this.loginFb.group({}),
        });
        this.logger.postEntry(
            LogLevel.Info,
            'Configuring FirstName',
            'DemographicComponent.setupFormControls',
            this.traceId,
            100
        );
        this.firstNameConfig = {
            type: 'text',
            name: 'FirstName',
            form: this.demographicsLoginFg,
            label: 'First Name',
            validationConfig: {
                required: {
                    value: true,
                    message: this.errors.LOGIN.FIRSTNAME.REQUIRED
                },
                maxLength: {
                    value: 100
                },
                validationRegex: {
                    value: /^[a-zA-Z][a-zA-Z\s]*([-a-zA-Z\s][a-zA-Z\s]*)?$/,
                    message: this.errors.LOGIN.FIRSTNAME.INVALID
                }
            },
        };
        this.logger.postEntry(
            LogLevel.Info,
            'Configuring LastName',
            'DemographicComponent.setupFormControls',
            this.traceId,
            100
        );
        this.lastNameConfig = {
            type: 'text',
            name: 'LastName',
            form: this.demographicsLoginFg,
            label: 'Last Name',
            validationConfig: {
                required: {
                    value: true,
                    message: this.errors.LOGIN.LASTNAME.REQUIRED
                },
                maxLength: {
                    value: 100
                },
                validationRegex: {
                    value: /^[a-zA-Z][a-zA-Z\s]*([-a-zA-Z\s][a-zA-Z\s]*)?$/,
                    message: this.errors.LOGIN.LASTNAME.INVALID
                }
            },
        };
        // this.addAdditionalControls();
    }

    // Don't delete: Additional Controls are disabled as per currently not in use but might be utilized in near future
    // addAdditionalControls() {
    //     if (this.storageService.accountInfoDetails.Sex) {
    //         this.demographicsLoginFg.addControl('Sex', this.loginFb.control(this.gender[0], Validators.required));
    //     }

    //     if (this.storageService.accountInfoDetails.HomePhone) {
    //         this.homeConfig = {
    //             type: 'text',
    //             name: 'Home',
    //             form: this.phoneFormGroup,
    //             label: 'Home Phone',
    //             validationConfig: {
    //                 required: {
    //                     value: true,
    //                     message: this.errors.LOGIN.PHONE.HOME.REQUIRED
    //                 },
    //                 customValidation: {
    //                     value: 'phone',
    //                     message: this.errors.LOGIN.PHONE.HOME.INVALID
    //                 },
    //                 maskArray: this.phoneMask,
    //             },
    //         };
    //     }
    //     if (this.storageService.accountInfoDetails.CellPhone) {
    //         this.cellConfig = {
    //             type: 'text',
    //             name: 'Mobile',
    //             form: this.phoneFormGroup,
    //             label: 'Cell Phone',
    //             validationConfig: {
    //                 required: {
    //                     value: true,
    //                     message: this.errors.LOGIN.PHONE.CELL.REQUIRED
    //                 },
    //                 customValidation: {
    //                     value: 'phone',

    //                     message: this.errors.LOGIN.PHONE.CELL.INVALID
    //                 },
    //                 maskArray: this.phoneMask,
    //             },
    //         };
    //     }

    //     if (this.storageService.accountInfoDetails.streetAddress) {
    //         this.streetAddressConfig = {
    //             type: 'text',
    //             name: 'StreetAddress',
    //             form: this.addressFormGroup,
    //             label: 'Street Address',
    //             validationConfig: {
    //                 required: {
    //                     value: true,
    //                     message: this.errors.LOGIN.ADDRESS.STREET.REQUIRED
    //                 },
    //                 maxLength: {
    //                     value: 100
    //                 },
    //             },
    //         };
    //     }
    //     if (this.storageService.accountInfoDetails.City) {
    //         this.cityConfig = {
    //             type: 'text',
    //             name: 'City',
    //             form: this.addressFormGroup,
    //             label: 'City',
    //             validationConfig: {
    //                 required: {
    //                     value: true,
    //                     message: this.errors.LOGIN.ADDRESS.CITY.REQUIRED
    //                 },
    //                 maxLength: {
    //                     value: 100
    //                 },
    //                 validationRegex: {
    //                     value: /^[a-zA-Z]+(?:[\s-.]*[a-zA-Z]*)*$/,
    //                     message: this.errors.LOGIN.ADDRESS.CITY.INVALID
    //                 }
    //             },
    //         };
    //     }
    //     if (this.storageService.accountInfoDetails.State) {
    //         this.addressFormGroup.addControl('State', this.loginFb.control(this.states[0].Code, Validators.required));
    //     }
    //     if (this.storageService.accountInfoDetails.Zip) {
    //         this.zipConfig = {
    //             type: 'text',
    //             name: 'Zip',
    //             form: this.addressFormGroup,
    //             label: 'Zip',
    //             validationConfig: {
    //                 required: {
    //                     value: true,
    //                     message: this.errors.LOGIN.ADDRESS.ZIP.REQUIRED
    //                 },
    //                 customValidation: {
    //                     value: 'zip',
    //                     message: this.errors.LOGIN.ADDRESS.ZIP.INVALID
    //                 },
    //                 maskArray: this.zipCodeMask,
    //             },
    //         };
    //     }
    // }


    openDatePicker(event) {
        this.logger.postEntry(
            LogLevel.Info,
            'Attempting to open date picker',
            'DemographicComponent.openDatePicker',
            this.traceId,
            100
        );
        this.dobPicker.nativeElement.nextSibling.focus();
        this.dobPicker.nativeElement.nextSibling.click();
        if (!this.isDatePickerOpen) {
            this.dobPicker.nativeElement.focus();
            this.dobPicker.nativeElement.click();
            this.dobPicker.nativeElement._flatpickr.open();
            event.stopPropagation();
            return false;
        }
        this.logger.postEntry(
            LogLevel.Info,
            'Date picker is already open',
            'DemographicComponent.openDatePicker',
            this.traceId,
            100
        );
    }

    onOpenDatePicker() {
        this.logger.postEntry(
            LogLevel.Info,
            'Opened date picker',
            'DemographicComponent.onOpenDatePicker',
            this.traceId,
            100
        );
        this.prevSelectedDate = this.dobPicker.nativeElement._flatpickr.selectedDates[0];
        this.isDatePickerOpen = true;
    }

    onCloseDatePicker() {
        this.logger.postEntry(
            LogLevel.Info,
            'Closing date picker',
            'DemographicComponent.onCloseDatePicker',
            this.traceId,
            100
        );
        this.dobFormControl.markAsTouched();
        this.dobPicker.nativeElement.blur();
        const calSelectedDate = this.dobPicker.nativeElement._flatpickr.selectedDates[0];
        if (this.dobPicker.nativeElement.value) {
            const dateMoment = moment(this.dobPicker.nativeElement.value, 'MM/DD/YYYY', true);
            const date = dateMoment.toDate();
            if (dateMoment.isValid() && (dateMoment.year() >= 1900) && (date.getTime() <= this.maxDate.getTime())) {
                this.dobPicker.nativeElement._flatpickr.setDate(this.dobPicker.nativeElement.value);
                this.checkIcon = true;
                this.onFocus = false;
                this.dateErrorPresent = false;
            } else {
                this.dobPicker.nativeElement._flatpickr.clear();
                this.checkIcon = false;
                this.dateErrorPresent = true;
            }
        } else {
            this.checkIcon = false;
                this.dateErrorPresent = true;
                this.dobPicker.nativeElement._flatpickr.clear();
        }
        setTimeout(() => {
            this.isDatePickerOpen = false;
        }, 200);
    }

    inputValueUpdated() {
        this.prevSelectedDate = this.dobPicker.nativeElement._flatpickr.selectedDates[0];
    }

    getFormValue() {
        const loginFormVal = this.demographicsLoginFg.value;
        loginFormVal.DOB = this.dobPicker.nativeElement.value;
        loginFormVal.suffix = this.storageService.urlSuffix;
        // if (this.storageService.accountInfoDetails.HomePhone) {
        //     loginFormVal.PhoneNumber.Home = `+${loginFormVal.PhoneNumber.Home.replace(/\D+/g, '')}`;
        // }
        // if (this.storageService.accountInfoDetails.CellPhone) {
        //     loginFormVal.PhoneNumber.Mobile = `+${loginFormVal.PhoneNumber.Mobile.replace(/\D+/g, '')}`;
        // }
        return loginFormVal;
    }
    focus() {
        // this.dobFormControl.untouched
        this.onFocus = true;
        this.checkIcon = false;
        this.dobFormControl.markAsUntouched();
        this.dobFormControl.markAsPristine();
        // console.log(this.onFocus)
        // this.checkIcon=false
        // alert("asd")
    }

    blur() {
        this.onFocus = false;
        const tempDob = new Date(this.dobPicker.nativeElement.value);

        if ((this.maxDate.getTime() >= tempDob.getTime())) {

          this.checkIcon = true;
          this.dateErrorPresent = false;
        //   console.log(this.checkIcon)
        } else {
            this.dobFormControl.setValue('');
            this.checkIcon = false;
            this.dateErrorPresent = true;
            // console.log(this.checkIcon)
        }
    }

}
