import {Injectable} from '@angular/core';
import {LogService} from '@shared-services/log.service';
import {LogLevel} from '@shared-models/common.model';


@Injectable()
export class LocalStorageService {
   private traceId: string;

    constructor(private logger: LogService) {
        this.traceId = logger.genTraceId();
    }

    setLocalStorage(values: any) {
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                var list = ['patientData','accountInfoDetails','patientDetails'];
                if(list.indexOf(key) === -1) { // disable PHI

                    this.logger.postEntry(
                        LogLevel.Info,
                        'Setting ' + key + ' to ' + JSON.stringify(values[key]),
                        'LocalStorageService.setLocalStorage',
                        this.traceId,
                        100
                    );
                }
                localStorage.setItem(key, JSON.stringify(values[key]));
            }
        }
    }

    setItemInLocalStorage(key: string, value: any) {
        var list = ['patientData','accountInfoDetails','patientDetails'];
        if(list.indexOf(key) === -1) { // disable PHI
        
            this.logger.postEntry(
                LogLevel.Info,
                'Setting ' + key + ' to ' + JSON.stringify(value),
                'LocalStorageService.setItemInLocalStorage',
                this.traceId,
                100
            );
        }
        localStorage.setItem(key, JSON.stringify(value));
    }

    getLocalStorage(key) {
        var list = ['patientData','accountInfoDetails','patientDetails'];
        if(list.indexOf(key) === -1) { // disable PHI

            this.logger.postEntry(
                LogLevel.Info,
                'Getting ' + key + '  from Local Storage.',
                'LocalStorageService.setLocalStorage',
                this.traceId,
                100
            );
        
            const value = JSON.parse(localStorage.getItem(key));
            this.logger.postEntry(
                LogLevel.Info,
                'Current value of ' + key + ': ' + JSON.stringify(value),
                'LocalStorageService.setLocalStorage',
                this.traceId,
                100
            );
        }
        return JSON.parse(localStorage.getItem(key));
    }

    resetLocalStorage() {
        this.logger.postEntry(
            LogLevel.Info,
            'Resetting Local Storage.',
            'LocalStorageService.setLocalStorage',
            this.traceId,
            100
        );
        localStorage.clear();
    }

    deleteLocalStorage(key) {
        this.logger.postEntry(
            LogLevel.Info,
            'Deleting ' + key + ' from Local Storage',
            'LocalStorageService.setLocalStorage',
            this.traceId,
            100
        );
        localStorage.removeItem(key);
    }

}
