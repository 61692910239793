import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {RoutePaths} from '@shared-constants/route-paths';
import {StorageService} from '@shared-services/storage.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private storageService: StorageService, private router: Router,
                private routePaths: RoutePaths) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        this.storageService.oldFormId = this.storageService.formId;
        if (route.parent.url[0].path === this.storageService.urlSuffix && (this.storageService.authToken || this.storageService.appointmentId)) {
            return true;
        } else if (!navigator.cookieEnabled) {
            return false;
        }else {
            this.storageService.clear();
            this.router.navigate([`/${route.parent.url[0].path}/login`]);
            return false;
        }
    }
}
