import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';

import {BootstrapInputConfigInterface} from '@shared-modules/ng-bootstrap-input/bootstrap-input-config';
import {BootstrapFormGroup} from '@shared-modules/ng-bootstrap-input/bootstrap-form-group';
import {StorageService} from '@shared-services/storage.service';
import {LoaderType} from '@app/shared/directives/loader/loader.model';
import {Error} from '@shared-constants/error-messages';
import flatpickr from 'flatpickr';
// import { MatomoTracker } from 'ngx-matomo';


import * as moment from 'moment';
import {LogService} from '@shared-services/log.service';
import {LogLevel} from '@shared-models/common.model';

@Component({
    selector: 'app-smartlink',
    templateUrl: './smart-link.component.html',
    styleUrls: ['./smart-link.component.scss']
})
export class SmartLinkLoginComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('dobPicker', {static: false}) dobPicker: ElementRef;
    // @ViewChild('dobPickerIcon') dobPickerIcon: ElementRef;

    readonly errors = Error;
    maxDate = new Date();
    minDate = new Date('01/01/1900');
    isDatePickerOpen = false;
    retainLoader = true;
    prevSelectedDate: string;
    private traceId: string;
    smartLinkLoginFg: BootstrapFormGroup = <BootstrapFormGroup>this.loginFb.group({});
    smartLinkConfig: BootstrapInputConfigInterface;
    loaderTypeEnum = LoaderType;

    onFocus = false;
    checkIcon = false;
    dateErrorPresent = false;
    tempDate: Date;
    dateValid = false;
    date: Date;
    dobData = '';

    get dobFormControl(): FormControl {
        return this.smartLinkLoginFg.get('DOB') as FormControl;
    }

    constructor(public loginFb: FormBuilder,
                public storageService: StorageService,
                private logger: LogService) {
        this.traceId = this.logger.genTraceId();
    }

    ngOnInit() {
        this.logger.postEntry(
            LogLevel.Info,
            'Initializing Patient ID Login Component',
            'smartLinkLogin.ngOnInit',
            this.traceId,
            100
        );
        this.setupFormControls();


    }

    ngAfterViewInit() {
        this.logger.postEntry(
            LogLevel.Info,
            'Patient ID Login Component initialized',
            'smartLinkLogin.ngAfterViewInit',
            this.traceId,
            100
        );

        this.dobPicker.nativeElement.focus();
    }

    ngOnDestroy() {
        this.logger.postEntry(
            LogLevel.Info,
            'Destroying Patient ID Login Component',
            'smartLinkLogin.ngOnDestroy',
            this.traceId,
            100
        );
    }

    isFormValid() {
        this.logger.postEntry(
            LogLevel.Info,
            'Checking if Patient ID Login form is valid',
            'smartLinkLogin.isFormValid',
            this.traceId,
            100
        );
        return this.smartLinkLoginFg.valid;
    }

    setupFormControls() {
        this.logger.postEntry(
            LogLevel.Info,
            'Setting up Patient ID Login form controls',
            'smartLinkLogin.setupFormControls',
            this.traceId,
            100
        );
        if (this.storageService.isReturnedFronPhoneVerification == true) {
            let dob = this.storageService.patientData.patientDetails['DateOfBirth']
            this.dobData = dob.replace('-', '/');
            this.storageService.isReturnedFronPhoneVerification = false;
        }    

        this.smartLinkLoginFg = <BootstrapFormGroup>this.loginFb.group({
            DOB: this.loginFb.control('', Validators.required)
        });
        this.logger.postEntry(
            LogLevel.Info,
            'Configuring smartLink',
            'smartLinkLogin.setupFormControls',
            this.traceId,
            100
        );
    }

    openDatePicker(event) {
        this.logger.postEntry(
            LogLevel.Info,
            'Attempting to open date picker',
            'smartLinkLogin.openDatePicker',
            this.traceId,
            100
        );
        this.dobPicker.nativeElement.nextSibling.focus();
        this.dobPicker.nativeElement.nextSibling.click();
        if (!this.isDatePickerOpen) {
            this.dobPicker.nativeElement.focus();
            this.dobPicker.nativeElement.click();
            this.dobPicker.nativeElement._flatpickr.open();
            event.stopPropagation();
            return false;
        }
        this.logger.postEntry(
            LogLevel.Info,
            'Date picker already open',
            'smartLinkLogin.openDatePicker',
            this.traceId,
            100
        );

    }

    onOpenDatePicker() {
        this.logger.postEntry(
            LogLevel.Info,
            'Date picker opened',
            'smartLinkLogin.onOpenDatePicker',
            this.traceId,
            100
        );
        setTimeout(() => {
            this.prevSelectedDate = this.dobPicker.nativeElement._flatpickr.selectedDates[0];
        this.isDatePickerOpen = true;

        }, 200);

    }



    onCloseDatePicker() {
        this.logger.postEntry(
            LogLevel.Info,
            'Closing date picker',
            'smartLinkLogin.onCloseDatePicker',
            this.traceId,
            100
        );
        this.dobFormControl.markAsTouched();
        this.dobPicker.nativeElement.blur();

        const calSelectedDate = this.dobPicker.nativeElement._flatpickr.selectedDates[0];

        if (this.dobPicker.nativeElement.value ) {
            const dateMoment = moment(this.dobPicker.nativeElement.value, 'MM/DD/YYYY', true);
            const date = dateMoment.toDate();
            this.date = date;
            if (dateMoment.isValid() && (dateMoment.year() >= 1900) && (date.getTime() <= this.maxDate.getTime())) {
                this.dobPicker.nativeElement._flatpickr.setDate(this.dobPicker.nativeElement.value);
                this.checkIcon = true;
                this.onFocus = false;
                this.dateErrorPresent = false;
            } else {
                this.checkIcon = false;
                this.dateErrorPresent = true;
                this.dobPicker.nativeElement._flatpickr.clear();
            }
        } else {
            this.checkIcon = false;
                this.dateErrorPresent = true;
                this.dobPicker.nativeElement._flatpickr.clear();
        }
        setTimeout(() => {
            this.isDatePickerOpen = false;
        }, 200);
        this.logger.postEntry(
            LogLevel.Info,
            'Date picker closed',
            'smartLinkLogin.onCloseDatePicker',
            this.traceId,
            100
        );
    }

    inputValueUpdated() {
        this.prevSelectedDate = this.dobPicker.nativeElement._flatpickr.selectedDates[0];
    }

    getFormValue() {
        const loginFormVal = this.smartLinkLoginFg.value;
        loginFormVal.PatientId = this.storageService.patientId;
        loginFormVal.appointmentId = this.storageService.appointmentId;
        loginFormVal.DOB = this.dobPicker.nativeElement.value;
        loginFormVal.suffix = this.storageService.urlSuffix;
        loginFormVal.LocationId = this.storageService.locationId;
        loginFormVal.LoginType = 'SmartLink';
        console.log("Login Form value", loginFormVal);
        return loginFormVal;
    }

    focus() {
        this.onFocus = true;
        this.checkIcon = false;
        this.dobFormControl.markAsUntouched();
        this.dobFormControl.markAsPristine();
    }

    blur() {

        this.onFocus = false;
        const tempDob = new Date(this.dobPicker.nativeElement.value);
        if ((this.maxDate.getTime() >= tempDob.getTime())) {

          this.checkIcon = true;
          this.dateErrorPresent = false;

        } else {
            this.dobFormControl.setValue('');
            this.checkIcon = false;
            this.dateErrorPresent = true;
        }
    }

    valueChanges() {


    }
}
