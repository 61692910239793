
import {of as observableOf, Observable} from 'rxjs';
import {Injector} from '@angular/core';

import {LogPublisher} from '@app/shared/publishers/log-publisher';
import {LogEntry} from '@shared-models/common.model';
import {LogLocalStorage} from '@app/shared/publishers/log-local-storage.service';
import {HttpService} from '@shared-services/http.service';
import {environment} from '@environments/environment';
import {StorageService} from '@app/shared/services/storage.service';

export class LogWebApi extends LogPublisher {
    values: LogEntry[];

    constructor(public injector: Injector, private logLocalStorage: LogLocalStorage,
                private httpService: HttpService, public storageService: StorageService) {
        // Must call super() from derived classes
        super(injector);
        // Set location
    }

    // Add log entry to back end data store
    log(entry: LogEntry): Observable<any> {
        // Log the error to the console
        if (this.logLocalStorage) {
            this.values = JSON.parse(localStorage.getItem(this.logLocalStorage.logPublisherConfig.loggerLocation)) || [];
            if (this.values.indexOf(entry) === -1) {
                this.values.push(entry);
            } else {
                this.values.push(entry);
            }
        }

        // Send error to server
        const options = {};
        this.httpService.post(environment.baseUrl + '' + this.logPublisherConfig.loggerLocation, this.values, options)
            .subscribe((res) => {
                this.clear();
                return observableOf(true);
            });

        return observableOf(entry);
    }

    // Clear all log entries from local storage
    clear(): Observable<boolean> {
        if (this.logLocalStorage) {
            this.logLocalStorage.clear();
        }
        return observableOf(true);
    }

}
