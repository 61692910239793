var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { of as observableOf } from 'rxjs';
import { LogPublisher } from '@app/shared/publishers/log-publisher';
var LogConsole = /** @class */ (function (_super) {
    __extends(LogConsole, _super);
    function LogConsole(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        return _this;
    }
    LogConsole.prototype.log = function (entry) {
        // Log to console
        // console.log(entry.buildLogString());
        return observableOf(entry);
    };
    LogConsole.prototype.clear = function () {
        console.clear();
        return observableOf(true);
    };
    return LogConsole;
}(LogPublisher));
export { LogConsole };
