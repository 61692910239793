import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';

import {StorageService} from '@shared-services/storage.service';

@Injectable()
export class NotCheckinGuard implements CanActivate {

    constructor(private storageService: StorageService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return !this.storageService.isPatientCheckedIn && !this.storageService.isCompletePage;
    }
}
