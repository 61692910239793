import {StorageService} from '@shared-services/storage.service';
import {Component, Input, OnInit} from '@angular/core';
import {LogService} from '@shared-services/log.service';
import {LogLevel} from '@shared-models/common.model';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    @Input() title: string;
    @Input() subtitle: string;

    logo: string;
    private traceId: string;

    constructor(private storageService: StorageService, private logger: LogService) {
        this.traceId = this.logger.genTraceId();
    }

    ngOnInit() {
        this.logger.postEntry(
            LogLevel.Info,
            'Initializing Header Component',
            'Header.ngOnInit',
            this.traceId,
            100
        );
        this.logo = `url(${this.storageService.logo})`;
    }
}
