import {Injectable} from '@angular/core';

import {StorageService} from '@shared-services/storage.service';

@Injectable()
export class DemoRoutePaths {
    urlSuffix = 'demo';
    constructor(private storageService: StorageService) {
    }

    NOT_FOUND = `/not-found`;

    get LOGIN() {
        return `/${this.urlSuffix}/demo-app/demo-login`;
    }

    get VERIFY_OTP() {
        return `/${this.urlSuffix}/demo-app/demo-verify-otp`;
    }

    get SEND_OTP() {
        return `/${this.urlSuffix}/demo-app/demo-send-otp`;
    }

    get APPOINTMENT_DETAILS() {
        return `/${this.urlSuffix}/demo-app/appointment-details`;
    }

    get DEMOGRAPHICS() {
        return `/${this.urlSuffix}/demo-app/demographics`;
    }

    get PRIMARY_INSURANCE() {
        return `/${this.urlSuffix}/demo-app/insurance/primary`;
    }

    get SECONDARY_INSURANCE() {
        return `/${this.urlSuffix}/demo-app/insurance/secondary`;
    }

    get DRIVER_LICENSE() {
        return `/${this.urlSuffix}/demo-app/driving-license`;
    }

    get PATIENT_IMAGE() {
        return `/${this.urlSuffix}/demo-app/image-upload`;
    }

    get AGREEMENT_CONSENT() {
        return `/${this.urlSuffix}/demo-app/agreements-consent`;
    }

    get COMPLETE() {
        return `/${this.urlSuffix}/demo-app/complete`;
    }
}
