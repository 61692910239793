import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {PopupService} from '@shared-components/popup/popup.service';
// Import 3rd party modules
import {TextMaskModule} from 'angular2-text-mask';
import {FlatpickrModule} from 'angularx-flatpickr';
// Import new modules and components created within the project
import {BootstrapInputModule} from '@shared-modules/ng-bootstrap-input/bootstrap-input.module';
import {HttpService} from '@shared-services/http.service';
import {AuthService} from '@shared-services/auth.service';
import {StorageService} from '@shared-services/storage.service';
import {LoaderModule} from '@shared-directives/loader/loader.module';
import {AppConfigService} from '@shared-services/app-config.service';
import {LocalStorageService} from '@shared-services/local-storage.service';
import {AppResolver} from '@shared-guards/auth/app.resolve';
import {AuthGuard} from '@shared-guards/auth';
import {PageNotFoundComponent} from '@shared-components/not-found/not-found.component';
import {PipeModule} from '@shared-pipes/pipe.module';
import {ComponentsModule} from '@shared-components/components.module';
import {NotCheckinGuard} from '@shared-guards/not-checkin.guard';
import {OTPGuard} from '@shared-guards/otp.guard';
import {NotCompleteGuard} from '@shared-guards/not-complete.guard';
import {AppointmentGuard} from '@shared-guards/appointment-details.guard';
import {RoutePaths} from '@shared-constants/route-paths';
import {DemoRoutePaths} from '@shared-constants/demo-route-paths';
import {MaskDateModule} from '@shared-directives/mask/date-mask.module';

import {SendOtpComponent} from '@app/auth/verification/send-otp/send-otp.component';
import {OTPVerificationComponent} from '@app/auth/verification/otp-verification/otp-verification.component';
import {PhoneVerificationComponent} from '@app/auth/verification/phone-verification/phone-verification.component';
import {AppointmentDetailsComponent} from '@app/auth/appointment-details/appointment-details.component';
import {AppComponent} from '@app/app.component';
import {AppRoutingModule} from '@app/app-routing.module';
import {LoginModule} from './auth/login/login.module';
//import {DemoModule} from '@app/demo/demo.module';
//import { MatomoModule } from 'ngx-matomo';
import {LogPublishersService} from "@shared-services/log-publishers.service";
import {LogService} from "@shared-services/log.service";
import {ErrorsService} from "@shared-services/errors.service";

export function startupServiceFactory(appConfigService: AppConfigService, logger: LogService): Function {
    return () => {
        logger.coralogixRUMInit();
        appConfigService.load();
    }
}


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        BootstrapInputModule,
        AppRoutingModule,
        LoaderModule,
        TextMaskModule,
        PipeModule,
        ComponentsModule,
        MaskDateModule,
        FlatpickrModule.forRoot(),
        LoginModule,
       // MatomoModule
    ],
    declarations: [
        AppComponent,
        SendOtpComponent,
        OTPVerificationComponent,
        PhoneVerificationComponent,
        PageNotFoundComponent,
        AppointmentDetailsComponent,
    ],
    providers: [
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        AppConfigService,
        {provide: APP_INITIALIZER, useFactory: startupServiceFactory, deps: [AppConfigService, LogService], multi: true},
        HttpService,
        StorageService,
        AuthService,
        AppResolver,
        AuthGuard,
        NotCheckinGuard,
        OTPGuard,
        NotCompleteGuard,
        AppointmentGuard,
        LocalStorageService,
        RoutePaths,
        DemoRoutePaths,
        PopupService,
        LogPublishersService,
        LogService,
        ErrorsService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
