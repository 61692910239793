<div [appLoader]="{promises: [AppointmentSubscription, BalanceSubscription, AccountInfoSubscription], loaderType: loaderTypeEnum.FULL_PAGE}">
    <fieldset #disabledFieldset>
        <div class="form-wrapper" *ngIf="AppointmentDetails">
            <app-header [title]="title"></app-header>
            <div id="main">
                <div class="container">
                    <h3>Appointment For:</h3>
                    <p>{{AppointmentDetails.patientName}}</p>
                    <h3>When:</h3>
                    <p>{{AppointmentDetails.appointmentDate}}
                        <br/>{{AppointmentDetails.appointmentTime}} {{AppointmentDetails.appointmentTZ}}</p>
                    <h3>Where:</h3>
                    <p>{{AppointmentDetails.doctorName}}
                        <br/>{{AppointmentDetails.practiceName}}
                        <br/>{{AppointmentDetails.practiceAddress}}
                        <br/>{{AppointmentDetails.practiceCity}}, {{AppointmentDetails.practiceState}}
                        {{AppointmentDetails.practicePinCode}}</p>
                    <br/>
                    <br/>
                    <form>
                        <button type="button" (click)="checkin()" class="btn btn-primary btn-full-width"
                                [appLoader]="{promises: [checkinSubscription], loaderType: loaderTypeEnum.BUTTON, disabledFieldSet: disabledFieldset, retainLoader: retainLoader}">
                            {{buttonText}} ›
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </fieldset>
</div>

