import {NgModule} from '@angular/core';
import {TextMaskModule} from 'angular2-text-mask';

import {HidePhonePipe} from '@app/shared/pipes/hide-phone.pipe';
import {HideEmailPipe} from '@app/shared/pipes/hide-email.pipe';

@NgModule({
    imports: [
        TextMaskModule
    ],
    declarations: [
        HideEmailPipe,
        HidePhonePipe
    ],
    exports: [
        HideEmailPipe,
        HidePhonePipe
    ]
})
export class PipeModule {
}
