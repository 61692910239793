<div class="form-wrapper">
    <fieldset #disabledFieldset>
        <app-header [title]="title"></app-header>
        <div id="main">
            <div class="container">
                <p>{{verificationText}}</p>
                <form [formGroup]="verifyFG">
                    <div class="form-group" [class.nodevice]="storageService.isDeviceNotAvailable">
                        <label for="code" class="control-label pad-bottom-4">Verification Code</label>
                        <input type="text" id="code" class="form-control" formControlName="code" lang="en"
                                (blur) = "otpBlur()"
                               [textMask]="{mask: otpMask, guide: false}">
                        <div class="form-group otp-timer" style="text-align: right;" id="otp_timer">{{currentTimer}}</div>
                    </div>
                    <div class="info-wrapper" *ngIf="!storageService.isDeviceNotAvailable">
                        <h5 class="pad-bottom-4 h5-font-size">Didn’t receive a code?</h5>
                        <p class="p-line-height">Allow a few moments for the code to arrive.
                            <br/>Still no code? Try sending another.</p>
                        <button type="button" class="btn btn-primary send-btn" (click)="sendAnotherOTP()">Send another
                            code ›
                        </button>
                    </div>
                    <button type="submit" (click)="verifyCode()" [disabled]="verifyFG.invalid"
                            class="btn btn-primary btn-full-width"
                            [appLoader]="{promises: [verifyOtpSubscription], loaderType: loaderTypeEnum.BUTTON, disabledFieldSet: disabledFieldset, retainLoader: retainLoader}">
                        Verify
                    </button>
                </form>
            </div>
        </div>
    </fieldset>
</div>

