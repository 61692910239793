import {PopupType} from '@shared-components/popup/popup.model';
import {Component, OnInit, ViewChild, AfterViewInit, NgZone, OnChanges, SimpleChanges} from '@angular/core';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';

import {StorageService} from '@shared-services/storage.service';
import {HttpService} from '@shared-services/http.service';
import {PopupService} from '@shared-components/popup/popup.service';
import {PopupComponent} from '@shared-components/popup/popup.component';
import {ERROR} from '@shared-constants/error-messages';

declare const $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, AfterViewInit, OnChanges {
    isSuffixResolved: boolean;

    @ViewChild('modal', {static: true}) modal: PopupComponent;

    suffixResolve(): void {
       this.isSuffixResolved = this.storageService.isSuffixResolved;
    }

    constructor(private router: Router,
                private route: ActivatedRoute,
                private storageService: StorageService,
                private httpService: HttpService,
                private popupService: PopupService,
                private _zone: NgZone) {
                    // this.matomoInjector.init(environment.matomoUrl, environment.matomoId);
    }

    ngOnInit() {
       // this.storageService.clear();
        this.popupService.modal = this.modal;
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            
        this._zone.run(() => {
            $(`<style>.page-header {background-color : ${this.storageService.headerColor}}
            label { color : ${this.storageService.TextColor} }
            .btn-primary, .btn-primary:hover, .btn:focus, .btn-primary:active,
            .btn-primary:active:focus,
            .btn-primary:active:hover,
            .btn-primary.disabled.focus,
            .btn-primary.disabled:focus,
            .btn-primary.disabled:hover,
            .btn-primary[disabled].focus,
            .btn-primary[disabled]:focus,
            .btn-primary[disabled]:hover,
            fieldset[disabled] .btn-primary:hover,
            fieldset[disabled] .btn-primary:focus,
            fieldset[disabled] .btn-primary:active
            {
            color : ${this.storageService.ButtonFontColor};
            background-color : ${this.storageService.ButtonColor};
            }
            </style>`).appendTo('head');
        });
            window.scrollTo(0, 0);
        });
        this.subscribeSessionExpired();
    }

    ngAfterViewInit() {
        
    }

    ngOnChanges(changes: SimpleChanges) {
        
        if (!this.isSuffixResolved) {
            this.suffixResolve();
        }
    }

    private subscribeSessionExpired() {
        this.httpService.onSessionExpired().subscribe(
            (errMsg) => {
                this.popupService.showModal('Session Timeout', errMsg, PopupType.ERROR, true);
            }
        );
    }
}
