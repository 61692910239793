import { StorageService } from '@shared-services/storage.service';
var DemoRoutePaths = /** @class */ (function () {
    function DemoRoutePaths(storageService) {
        this.storageService = storageService;
        this.urlSuffix = 'demo';
        this.NOT_FOUND = "/not-found";
    }
    Object.defineProperty(DemoRoutePaths.prototype, "LOGIN", {
        get: function () {
            return "/" + this.urlSuffix + "/demo-app/demo-login";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemoRoutePaths.prototype, "VERIFY_OTP", {
        get: function () {
            return "/" + this.urlSuffix + "/demo-app/demo-verify-otp";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemoRoutePaths.prototype, "SEND_OTP", {
        get: function () {
            return "/" + this.urlSuffix + "/demo-app/demo-send-otp";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemoRoutePaths.prototype, "APPOINTMENT_DETAILS", {
        get: function () {
            return "/" + this.urlSuffix + "/demo-app/appointment-details";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemoRoutePaths.prototype, "DEMOGRAPHICS", {
        get: function () {
            return "/" + this.urlSuffix + "/demo-app/demographics";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemoRoutePaths.prototype, "PRIMARY_INSURANCE", {
        get: function () {
            return "/" + this.urlSuffix + "/demo-app/insurance/primary";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemoRoutePaths.prototype, "SECONDARY_INSURANCE", {
        get: function () {
            return "/" + this.urlSuffix + "/demo-app/insurance/secondary";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemoRoutePaths.prototype, "DRIVER_LICENSE", {
        get: function () {
            return "/" + this.urlSuffix + "/demo-app/driving-license";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemoRoutePaths.prototype, "PATIENT_IMAGE", {
        get: function () {
            return "/" + this.urlSuffix + "/demo-app/image-upload";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemoRoutePaths.prototype, "AGREEMENT_CONSENT", {
        get: function () {
            return "/" + this.urlSuffix + "/demo-app/agreements-consent";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemoRoutePaths.prototype, "COMPLETE", {
        get: function () {
            return "/" + this.urlSuffix + "/demo-app/complete";
        },
        enumerable: true,
        configurable: true
    });
    return DemoRoutePaths;
}());
export { DemoRoutePaths };
