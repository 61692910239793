import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';

import {StorageService} from '@shared-services/storage.service';

@Injectable()
export class AppointmentGuard implements CanActivate {

    constructor(private storageService: StorageService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        console.log("is OTP verified: " + this.storageService.isOTPVerified);
        return this.storageService.isOTPVerified;
    }
}
