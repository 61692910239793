var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { of as observableOf } from 'rxjs';
import { LogPublisher } from '@app/shared/publishers/log-publisher';
var LogLocalStorage = /** @class */ (function (_super) {
    __extends(LogLocalStorage, _super);
    function LogLocalStorage(injector) {
        var _this = 
        // Must call super() from derived classes
        _super.call(this, injector) || this;
        _this.injector = injector;
        return _this;
        // Set location
    }
    // Append log entry to local storage
    LogLocalStorage.prototype.log = function (entry) {
        var values;
        try {
            // Get previous values from local storage
            values = JSON.parse(localStorage.getItem(this.logPublisherConfig.loggerLocation))
                || [];
            // Add new log entry to array
            if (values.length == 50) {
                values = values.splice(0, 1);
            }
            values.push(entry);
            // Store array into local storage
            localStorage.setItem(this.logPublisherConfig.loggerLocation, JSON.stringify(values));
            // Set return value
        }
        catch (ex) {
            // Display error in console
            console.log(ex);
        }
        return observableOf(entry);
    };
    // Clear all log entries from local storage
    LogLocalStorage.prototype.clear = function () {
        localStorage.removeItem(this.logPublisherConfig.loggerLocation);
        return observableOf(true);
    };
    return LogLocalStorage;
}(LogPublisher));
export { LogLocalStorage };
