import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {StorageService} from '@shared-services/storage.service';
import {LocalStorageService} from '@shared-services/local-storage.service';

@Injectable()
export class NotCompleteGuard implements CanActivate {

    constructor(private router: Router, private route: ActivatedRoute,
                private storageService: StorageService, private localStorage: LocalStorageService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return !this.storageService.isCompletePage;
    }
}
