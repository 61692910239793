import { AfterViewInit, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { StorageService } from '@shared-services/storage.service';
import { LoaderType } from '@app/shared/directives/loader/loader.model';
import { Error } from '@shared-constants/error-messages';
// import { MatomoTracker } from 'ngx-matomo';
import * as moment from 'moment';
import { LogService } from '@shared-services/log.service';
import { LogLevel } from '@shared-models/common.model';
var PatientIDLoginComponent = /** @class */ (function () {
    function PatientIDLoginComponent(loginFb, storageService, logger) {
        this.loginFb = loginFb;
        this.storageService = storageService;
        this.logger = logger;
        // @ViewChild('dobPickerIcon') dobPickerIcon: ElementRef;
        this.errors = Error;
        this.maxDate = new Date();
        this.minDate = new Date('01/01/1900');
        this.isDatePickerOpen = false;
        this.retainLoader = true;
        this.patientIDLoginFg = this.loginFb.group({});
        this.loaderTypeEnum = LoaderType;
        this.onFocus = false;
        this.checkIcon = false;
        this.dateErrorPresent = false;
        this.dateValid = false;
        this.traceId = this.logger.genTraceId();
    }
    Object.defineProperty(PatientIDLoginComponent.prototype, "dobFormControl", {
        get: function () {
            return this.patientIDLoginFg.get('DOB');
        },
        enumerable: true,
        configurable: true
    });
    PatientIDLoginComponent.prototype.ngOnInit = function () {
        this.logger.postEntry(LogLevel.Info, 'Initializing Patient ID Login Component', 'PatientIDLogin.ngOnInit', this.traceId, 100);
        this.setupFormControls();
    };
    PatientIDLoginComponent.prototype.ngAfterViewInit = function () {
        this.logger.postEntry(LogLevel.Info, 'Patient ID Login Component initialized', 'PatientIDLogin.ngAfterViewInit', this.traceId, 100);
    };
    PatientIDLoginComponent.prototype.ngOnDestroy = function () {
        this.logger.postEntry(LogLevel.Info, 'Destroying Patient ID Login Component', 'PatientIDLogin.ngOnDestroy', this.traceId, 100);
    };
    PatientIDLoginComponent.prototype.isFormValid = function () {
        this.logger.postEntry(LogLevel.Info, 'Checking if Patient ID Login form is valid', 'PatientIDLogin.isFormValid', this.traceId, 100);
        return this.patientIDLoginFg.valid;
    };
    PatientIDLoginComponent.prototype.setupFormControls = function () {
        this.logger.postEntry(LogLevel.Info, 'Setting up Patient ID Login form controls', 'PatientIDLogin.setupFormControls', this.traceId, 100);
        this.patientIDLoginFg = this.loginFb.group({
            DOB: this.loginFb.control('', Validators.required)
        });
        this.logger.postEntry(LogLevel.Info, 'Configuring PatientId', 'PatientIDLogin.setupFormControls', this.traceId, 100);
        this.patientIDConfig = {
            type: 'text',
            name: 'PatientId',
            form: this.patientIDLoginFg,
            label: 'PATIENT NUMBER',
            validationConfig: {
                required: {
                    value: true,
                    message: this.errors.LOGIN.PATIENT_ID.REQUIRED
                },
                maxLength: {
                    value: 32
                },
                validationRegex: {
                    value: /^[0-9a-zA-Z./-]*$/,
                    message: this.errors.LOGIN.PATIENT_ID.INVALID
                }
            },
        };
    };
    PatientIDLoginComponent.prototype.openDatePicker = function (event) {
        this.logger.postEntry(LogLevel.Info, 'Attempting to open date picker', 'PatientIDLogin.openDatePicker', this.traceId, 100);
        this.dobPicker.nativeElement.nextSibling.focus();
        this.dobPicker.nativeElement.nextSibling.click();
        if (!this.isDatePickerOpen) {
            this.dobPicker.nativeElement.focus();
            this.dobPicker.nativeElement.click();
            this.dobPicker.nativeElement._flatpickr.open();
            event.stopPropagation();
            return false;
        }
        this.logger.postEntry(LogLevel.Info, 'Date picker already open', 'PatientIDLogin.openDatePicker', this.traceId, 100);
    };
    PatientIDLoginComponent.prototype.onOpenDatePicker = function () {
        var _this = this;
        this.logger.postEntry(LogLevel.Info, 'Date picker opened', 'PatientIDLogin.onOpenDatePicker', this.traceId, 100);
        setTimeout(function () {
            _this.prevSelectedDate = _this.dobPicker.nativeElement._flatpickr.selectedDates[0];
            _this.isDatePickerOpen = true;
        }, 200);
    };
    PatientIDLoginComponent.prototype.onCloseDatePicker = function () {
        var _this = this;
        this.logger.postEntry(LogLevel.Info, 'Closing date picker', 'PatientIDLogin.onCloseDatePicker', this.traceId, 100);
        this.dobFormControl.markAsTouched();
        this.dobPicker.nativeElement.blur();
        var calSelectedDate = this.dobPicker.nativeElement._flatpickr.selectedDates[0];
        if (this.dobPicker.nativeElement.value) {
            var dateMoment = moment(this.dobPicker.nativeElement.value, 'MM/DD/YYYY', true);
            var date = dateMoment.toDate();
            this.date = date;
            if (dateMoment.isValid() && (dateMoment.year() >= 1900) && (date.getTime() <= this.maxDate.getTime())) {
                this.dobPicker.nativeElement._flatpickr.setDate(this.dobPicker.nativeElement.value);
                this.checkIcon = true;
                this.onFocus = false;
                this.dateErrorPresent = false;
            }
            else {
                this.checkIcon = false;
                this.dateErrorPresent = true;
                this.dobPicker.nativeElement._flatpickr.clear();
            }
        }
        else {
            this.checkIcon = false;
            this.dateErrorPresent = true;
            this.dobPicker.nativeElement._flatpickr.clear();
        }
        setTimeout(function () {
            _this.isDatePickerOpen = false;
        }, 200);
        this.logger.postEntry(LogLevel.Info, 'Date picker closed', 'PatientIDLogin.onCloseDatePicker', this.traceId, 100);
    };
    PatientIDLoginComponent.prototype.inputValueUpdated = function () {
        this.prevSelectedDate = this.dobPicker.nativeElement._flatpickr.selectedDates[0];
    };
    PatientIDLoginComponent.prototype.getFormValue = function () {
        var loginFormVal = this.patientIDLoginFg.value;
        loginFormVal.DOB = this.dobPicker.nativeElement.value;
        loginFormVal.suffix = this.storageService.urlSuffix;
        loginFormVal.LoginType = 'PatientId';
        return loginFormVal;
    };
    PatientIDLoginComponent.prototype.patientid = function (event) {
        if (event.target.value.length > 31) {
            return false;
        }
        else {
            return true;
        }
    };
    PatientIDLoginComponent.prototype.focus = function () {
        // this.dobFormControl.untouched
        this.onFocus = true;
        this.checkIcon = false;
        // this.dateErrorPresent=false
        this.dobFormControl.markAsUntouched();
        this.dobFormControl.markAsPristine();
        // console.log(this.onFocus)
        // this.checkIcon=false
        // alert("asd")
    };
    PatientIDLoginComponent.prototype.blur = function () {
        this.onFocus = false;
        var tempDob = new Date(this.dobPicker.nativeElement.value);
        // console.log("blur",this.dobFormControl.value)
        // console.log(tempDob.getTime(),"blur2")
        if ((this.maxDate.getTime() >= tempDob.getTime())) {
            this.checkIcon = true;
            this.dateErrorPresent = false;
            //   console.log(this.checkIcon)
        }
        else {
            // this.dobFormControl.reset()
            // this.dobFormControl.setErrors({'required':true})
            // this.dobFormControl.markAsTouched()
            this.dobFormControl.setValue('');
            this.checkIcon = false;
            this.dateErrorPresent = true;
            // console.log(this.checkIcon)
            // console.log(this.dobFormControl.value)
        }
    };
    PatientIDLoginComponent.prototype.valueChanges = function () {
    };
    return PatientIDLoginComponent;
}());
export { PatientIDLoginComponent };
