import {StackFrame} from 'error-stack-parser';

export interface CommonResponse<T> {
    data: T;
    message: string;
}

export class Environments {
    EnvironmentID: number;
    env: string;
}

export class EHR {
    ID: number;
    name: string;
}

export class Integration {
    ApiTypeId: number;
    Description: string;
}

export class TemplateType {
    TemplateTypeID: number;
    TemplateTypeDesc: string;
    checkedInMsg: string;
    ButtonText: string;
}

// below commented fields are disabled temporarily
export class AccountInfoDetails {
    AccountId: number;
    Logo: string;
    Ehr: string;
    Company: string;
    ApiType: string;
    HeaderColor: string;
    ButtonColor: string;
    TextColor: string;
    ButtonFontColor: string;
    // streetAddress: boolean;
    // City: boolean;
    // State: boolean;
    // Zip: boolean;
    // HomePhone: boolean;
    // CellPhone: boolean;
    // Sex: boolean;
    LoginConfig: LoginConfig;
    LoginInfo: string;
    patientTokenData: string;
}

export class LogPublisherConfig {
    loggerName: string;
    loggerLocation: string;
    isActive: boolean;
}

export class ErrorInfoModel {
    name: string;
    appId: string;
    user: string;
    time: number;
    id: string;
    url: string;
    status: string;
    stack: StackFrame[];
}

export class LoginConfig {
    demographics: Demographics;
    patientId: PatientId;
}

export class Demographics {
    enabled: boolean;
}

export class PatientId {
    enabled: boolean;
    noOtp: boolean;
}

export class AppConfig {
    States: State[] = [];
    Gender: string[] = [];
    Relationship: string[] = [];
    TemplateTypes: TemplateType[] = [];
}


export class State {
    Code: string;
    Name: string;
}

export class PatientDetail {
    FirstName: string;
    LastName: string;
    DateOfBirth: string;
}

export class PatientData {
    patientDetails: PatientDetail;
    patientSettings: TemplateSettings;
}

export class TemplateSettings {
    IsPrimaryInsurance: boolean;
    IsSecondaryInsurance: boolean;
    IsAcuantEnabled: boolean;
    LicenseKey: string;
    FormUrl: string;
    FormPreviewLink: string;
    IsDriverLicenseEnable: boolean;
    IsImageUploadEnabled: boolean;
    IsPaymentEnable: boolean;
    IsAgreementAndConsentEnable: boolean;
    IsAppointmentStatusChangedEnable: boolean;
    IsNotifyForCompletedFormEnable: boolean;
    SuccessMessage: boolean;
    FailureMessage: boolean;
    TemplateTypeID: number;
    AgreementAndConsentText: string;
    IsSecondaryInsuranceImageOnly: boolean;
    IsPrimaryInsuranceImageOnly: boolean;
    IsDriverLicenseImageOnly: boolean;
    ImageText: string;
}

export enum LogLevel {
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
    Debug = 'debug'
}

export class LogEntry {
    // Public Properties
    entryDate: Date = new Date();
    message = '';
    level: LogLevel = LogLevel.Debug;
    location: string;
    traceId: string;
    code: number;
    extraInfo: any[] = [];
    errorInfo: ErrorInfoModel;
    logWithDate = true;

    buildLogString(): string {
        let ret = '';

        if (this.logWithDate) {
            ret = new Date() + ' - ';
        }
        ret += 'Type: ' + LogLevel[this.level];
        ret += ' - Message: ' + this.message;
        if (this.extraInfo.length) {
            ret += ' - Extra Info: '
                + this.formatParams(this.extraInfo);
        }

        return ret;
    }
    private formatParams(params: any[]): string {
        let ret: string = params.join(',');

        // Is there at least one object in the array?
        if (params.some(p => typeof p === 'object')) {
            ret = '';
            // Build comma-delimited string
            for (const item of params) {
                ret += JSON.stringify(item) + ',';
            }
        }

        return ret;
    }
}

