import {Subscription} from 'rxjs';

export class LoaderOptions {
    promises: Array<Promise<any> | Subscription> = [];
    loaderType: LoaderType;
    disabledFieldSet?: HTMLFieldSetElement;
    retainLoader?: boolean;
    ignorePromises?: boolean;
}

export enum LoaderType {
    BUTTON,
    FULL_PAGE
}

export const BtnLoaderConfig = {
    spinnerTpl: `<div class="small-loader">
        <div class="sk-circle1 sk-child"></div>
        <div class="sk-circle2 sk-child"></div>
        <div class="sk-circle3 sk-child"></div>
        <div class="sk-circle4 sk-child"></div>
        <div class="sk-circle5 sk-child"></div>
        <div class="sk-circle6 sk-child"></div>
        <div class="sk-circle7 sk-child"></div>
        <div class="sk-circle8 sk-child"></div>
        <div class="sk-circle9 sk-child"></div>
        <div class="sk-circle10 sk-child"></div>
        <div class="sk-circle11 sk-child"></div>
        <div class="sk-circle12 sk-child"></div>
      </div>`,
    spinnerClass: 'small-loader'
};

export const FullPageLoaderConfig = {
    spinnerTpl: `<div class="fullpage-loader">
      <div class="overlay"></div>
      <div class="loading-container">
      <div class="sk-circle">
      <div class="sk-circle1 sk-child1"></div>
      <div class="sk-circle2 sk-child1"></div>
      <div class="sk-circle3 sk-child1"></div>
      <div class="sk-circle4 sk-child1"></div>
      <div class="sk-circle5 sk-child1"></div>
      <div class="sk-circle6 sk-child1"></div>
      <div class="sk-circle7 sk-child1"></div>
      <div class="sk-circle8 sk-child1"></div>
      <div class="sk-circle9 sk-child1"></div>
      <div class="sk-circle10 sk-child1"></div>
      <div class="sk-circle11 sk-child1"></div>
      <div class="sk-circle12 sk-child1"></div>
      </div>
      <div class="loading">Loading...</div>
      </div>
    </div>`,
    spinnerClass: 'fullpage-loader'
};

export namespace LoaderType {

    export function loaderConfig(loaderType: LoaderType) {
        switch (loaderType) {
            case LoaderType.BUTTON:
                return BtnLoaderConfig;
            case LoaderType.FULL_PAGE:
                return FullPageLoaderConfig;
        }
    }
}


