import { StorageService } from '@shared-services/storage.service';
var RoutePaths = /** @class */ (function () {
    function RoutePaths(storageService) {
        this.storageService = storageService;
        this.NOT_FOUND = "/not-found";
    }
    Object.defineProperty(RoutePaths.prototype, "LOGIN", {
        get: function () {
            return "/" + this.storageService.urlSuffix + "/login";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutePaths.prototype, "VERIFY_OTP", {
        get: function () {
            return "/" + this.storageService.urlSuffix + "/verify-otp";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutePaths.prototype, "SEND_OTP", {
        get: function () {
            return "/" + this.storageService.urlSuffix + "/send-otp";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutePaths.prototype, "PHONE_VERIFICATION", {
        get: function () {
            return "/" + this.storageService.urlSuffix + "/phone-verification";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutePaths.prototype, "APPOINTMENT_DETAILS", {
        get: function () {
            return "/" + this.storageService.urlSuffix + "/appointment-details";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutePaths.prototype, "DEMOGRAPHICS", {
        get: function () {
            return "/" + this.storageService.urlSuffix + "/home/demographics";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutePaths.prototype, "PRIMARY_INSURANCE", {
        get: function () {
            return "/" + this.storageService.urlSuffix + "/home/insurance/primary";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutePaths.prototype, "SECONDARY_INSURANCE", {
        get: function () {
            return "/" + this.storageService.urlSuffix + "/home/insurance/secondary";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutePaths.prototype, "DRIVER_LICENSE", {
        get: function () {
            return "/" + this.storageService.urlSuffix + "/home/driving-license";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutePaths.prototype, "PATIENT_IMAGE", {
        get: function () {
            return "/" + this.storageService.urlSuffix + "/home/image-upload";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutePaths.prototype, "AGREEMENT_CONSENT", {
        get: function () {
            return "/" + this.storageService.urlSuffix + "/home/agreements-consent";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutePaths.prototype, "PATIENT_BALANCE_DECLINE", {
        get: function () {
            return "/" + this.storageService.urlSuffix + "/home/md-pay/pay-in-office";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutePaths.prototype, "PATIENT_BALANCE", {
        get: function () {
            return "/" + this.storageService.urlSuffix + "/home/md-pay/balance-due";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutePaths.prototype, "COMPLETE", {
        get: function () {
            return "/" + this.storageService.urlSuffix + "/home/complete";
        },
        enumerable: true,
        configurable: true
    });
    return RoutePaths;
}());
export { RoutePaths };
