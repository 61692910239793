
import { Injectable } from '@angular/core';
import { LogLevel } from '@shared-models/common.model';
import { v4 as uuidV4 } from 'uuid';
import { environment } from '@environments/environment';

declare global {
    interface Window {
        CoralogixRum:any;
    }
}

@Injectable()
export class LogService {
    constructor( ) { }

    coralogixRUMInit() {
        window.CoralogixRum.init({
            application: 'ereg-patient',
            environment: environment.currentEnv,
            public_key: environment.coralogixRUMPublicKey,
            coralogixDomain: environment.coralogixDomain,
            version: 'v1.0.0'
        });
    }

    coralogixSetUserContext(patientId: string, firstName = '', lastName = '', email = '') {
        window.CoralogixRum.setUserContext({
            user_id: patientId,
            user_name: `${firstName} ${lastName}`,
            user_email: email,
            user_metadata: {
              role: 'patient'
            }
        });
    }

    /**
     * Posts a log entry to the paient API for ingestion by LogDNA
     * @param level
     * @param message
     * @param location
     * @param traceId
     * @param code
     * @author Rory Womack <awomack@relatient.net>
     */
    postEntry(level: LogLevel, message: string, location: string, traceId: string, code: number) {
        if (environment.enableCoralogixLogging) {
            switch (level) {
                case 'info': {
                    window.CoralogixRum.info(message, { key: environment.coralogixRUMPublicKey });
                    break;
                }
                case 'debug': {
                    window.CoralogixRum.debug(message, { key: environment.coralogixRUMPublicKey });
                    break;
                }
                case 'warning': {
                    window.CoralogixRum.warn(message,{ key: environment.coralogixRUMPublicKey });
                    break;
                }
                case 'error': {
                    window.CoralogixRum.error(message, { key: environment.coralogixRUMPublicKey });
                    break;
                }
            }
        }
    }

    /**
     * Generates a v4 UUID for use as a trace id
     * @author Rory Womack <awomack@relatient.net>
     */
    genTraceId(): string {
        return uuidV4();
    }

    trackFormIoEvents(textInfo, data) {
        if (environment.enableCoralogixLogging) {
            const message = JSON.parse(data);
            window.CoralogixRum.info(JSON.stringify(Object.assign({textInfo}, message)), { key: environment.coralogixRUMPublicKey });
        }
    }
}
