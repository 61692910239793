import {environment} from '@environments/environment';

const BASE_URL = environment.baseUrl;

export class RequestUrls {
    public static readonly AUTHENTICATED = {
        LOGOUT: BASE_URL + 'logout',
        ACCESS_TOKEN: BASE_URL + 'accessToken',
        VERIFYOTP: BASE_URL + 'validateAuthenticationToken',
        VAERIRY_OTP_WIRHOUT_DEVICE: BASE_URL + 'validateAuthCode',
        SEND_OTP: BASE_URL + 'requestOtp',
        UPDATE_OTP_RECEIVE_TIME: BASE_URL + 'updateOtpReceiveTime',
        GET_DEMOGRAPHICS_DETAILS: BASE_URL + 'getDemographicDetails',
        SET_DEMOGRAPHICS_DETAILS: BASE_URL + 'setDemographicDetails',
        GET_APPOINTMENT_DETAILS: BASE_URL + 'getAppointmentDetails',
        GET_TEMPLATE_SETTINGS: BASE_URL + 'getPatientData',
        SET_PRIMARY_INSURANCE: BASE_URL + 'setPrimaryInsuranceData',
        GET_PRIMARY_INSURANCE: BASE_URL + 'getPrimaryInsuranceData',
        UPLOAD_PRIMARY_INSURANCE_IMAGE: BASE_URL + 'uploadPrimaryInsuranceImage',
        UPLOAD_SECONDARY_INSURANCE_IMAGE: BASE_URL + 'uploadSecondaryInsuranceImage',
        GET_SECONDARY_INSURANCE: BASE_URL + 'getSecondaryInsuranceData',
        SET_SECONDARY_INSURANCE: BASE_URL + 'setSecondaryInsuranceData',
        GET_DRIVING_LICENSE: BASE_URL + 'getDrivingLicense',
        SAVE_DRIVING_LICENSE: BASE_URL + 'saveDrivingLicenseImage',
        UPLOAD_DRIVING_LICENSE_IMAGE: BASE_URL + 'uploadDrivingLicenseImage',
        COMPLETE_PAGE_STATUS: BASE_URL + 'getCompletePageStatus',
        GET_AGREEMENT_AND_CONSENT: BASE_URL + 'getAgreementConsent',
        SAVE_AGREEMENT_CONSENT: BASE_URL + 'saveAgreementConsent',
        UPLOAD_PATIENT_IMAGE: BASE_URL + 'uploadPatientImage',
        SAVE_PATIENT_IMAGE: BASE_URL + 'setPatientImage',
        GET_PATIENT_IMAGE: BASE_URL + 'getPatientImage',
        GET_PATIENT_BALANCE: BASE_URL + 'getPatientBalance',
        GET_ACCOUNT_FEATURES: BASE_URL + 'getAccountFeatures',
        GET_PHONE_LIST: BASE_URL + 'getPhoneList',
        GET_DRAFT_INFO: BASE_URL + 'getDraftData',
    };

    public static readonly ANONYMOUS = {
        GET_ACCOUNT_INFO_DETAILS: BASE_URL + 'getAccountInfoDetails',
        APP_CONFIG: BASE_URL + 'getPatientConfig',
        LOGIN: BASE_URL + 'login',
        REFRESH_TOKEN: BASE_URL + 'refreshToken',
    };

    public static readonly ACUANT = {
        PROCESS_MEDICAL_INSURANCE_CARD:
            'https://cssnwebservices.com/CSSNService/CardProcessor/ProcessMedInsuranceCard/true/0/150/false/false/105',
        PROCESS_DRIVING_LICENSE_CARD:
            'https://cssnwebservices.com/CSSNService/CardProcessor/ProcessDriversLicense/0/true/-1/false/false/true/0/150/false/105/true/false'
    };

    constructor() {
    }

    public static isAuthUrl(url: string): boolean {
        let isAuthUrl = false;
        Object.keys(RequestUrls.AUTHENTICATED).forEach((key: string) => {
            if (url.startsWith(RequestUrls.AUTHENTICATED[key])) {
                isAuthUrl = true;
            }
        });
        return isAuthUrl;
    }

    public static isAcuant(url: string): boolean {
        let isAcuant = false;
        Object.keys(RequestUrls.ACUANT).forEach((key: string) => {
            if (url.startsWith(RequestUrls.ACUANT[key])) {
                isAcuant = true;
            }
        });
        return isAcuant;
    }
}
