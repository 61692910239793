import {Pipe, PipeTransform} from '@angular/core';

import {conformToMask} from 'angular2-text-mask';


@Pipe({name: 'hidePhone', pure: false})
export class HidePhonePipe implements PipeTransform {
    transform(input: string): string {
        const phoneMask = ['+', ' ', ' ', /[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        let maskPhoneNo = conformToMask(
            input,
            phoneMask,
            {guide: false}
        ).conformedValue;
        let updatedMaskPhoneNo = maskPhoneNo.toString().replace('+', '+1');
        for (let x = 3; x < updatedMaskPhoneNo.length - 4; x++) {
            if (updatedMaskPhoneNo[x] !== '-') {
                updatedMaskPhoneNo = updatedMaskPhoneNo.substr(0, x) + 'X' + maskPhoneNo.substr(x + 1);
            }
        }
        return updatedMaskPhoneNo;
    }
}
