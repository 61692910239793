import {AbstractControl} from '@angular/forms';


export class ZipValidators {
    static zipLength(c: AbstractControl): { [key: string]: boolean } | null {
        if (c.value) {
            const isValidZip = c.value.replace(/\D+/g, '').length === 5;
            return isValidZip ? null : {'zipLength': true};
        }
        return null;
    }
}
