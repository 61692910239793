import {LocalStorageService} from './local-storage.service';
import {Injectable} from '@angular/core';

import {RequestUrls} from '@shared-constants/request-urls';
import {CommonResponse, LogLevel} from '@shared-models/common.model';
import {HttpService} from '@shared-services/http.service';
import {StorageService} from '@shared-services/storage.service';

import {
    AccountFeatureResponse,
    DraftInfo,
    LoginDetails,
    OTPCode,
    AppointmentDetails,
    PatientBalanceResponse,
    PatientDetails
} from '@app/auth/auth.model';
import {LogService} from '@shared-services/log.service';
import {Observable} from 'rxjs';

@Injectable()
export class AuthService {

    private traceId: string;

    get patientDetails(): PatientDetails[] {
        return this.localStorage.getLocalStorage('patientDetails');
    }

    set patientDetails(pDetails: PatientDetails[]) {
        this.localStorage.setItemInLocalStorage('patientDetails', pDetails);
    }

    constructor(private http: HttpService, private storageService: StorageService,
                private localStorage: LocalStorageService, private logger: LogService) {
        this.traceId = this.logger.genTraceId();
    }

    login(loginData) {
        this.logger.postEntry(
            LogLevel.Info,
            'Posting login request to :' + RequestUrls.ANONYMOUS.LOGIN,
            'AuthService.login',
            this.traceId,
            100
        );
        return this.http.post<CommonResponse<LoginDetails>>(RequestUrls.ANONYMOUS.LOGIN, loginData);
    }

    verifyOTP(verificationCode, currentDateTime) {
        this.logger.postEntry(
            LogLevel.Info,
            'Posting 2FA verification request to :' + RequestUrls.AUTHENTICATED.VERIFYOTP,
            'AuthService.verifyOTP',
            this.traceId,
            100
        );
        return this.http.post<CommonResponse<{}>>(RequestUrls.AUTHENTICATED.VERIFYOTP, {code: verificationCode.code, currentDateTime});
    }

    sendOTP(requestOTPData) {
        this.logger.postEntry(
            LogLevel.Info,
            'Posting 2FA send request to :' + RequestUrls.AUTHENTICATED.SEND_OTP,
            'AuthService.sendOTP',
            this.traceId,
            100
        );
        return this.http.post<CommonResponse<OTPCode>>(RequestUrls.AUTHENTICATED.SEND_OTP, requestOTPData);
    }

    updateOTPReceiveTime(time) {
        this.logger.postEntry(
            LogLevel.Info,
            'Updating OTP receiving time to :' + RequestUrls.AUTHENTICATED.UPDATE_OTP_RECEIVE_TIME,
            'AuthService.updateOTPTime',
            this.traceId,
            100
        );
        return this.http.post<CommonResponse<{}>>(RequestUrls.AUTHENTICATED.UPDATE_OTP_RECEIVE_TIME, time);
    }

    getAppointmentDetails(patientDetails) {
        this.logger.postEntry(
            LogLevel.Info,
            'Posting appointment details request to :' + RequestUrls.AUTHENTICATED.GET_APPOINTMENT_DETAILS,
            'AuthService.getAppointmentDetails',
            this.traceId,
            100
        );
        return this.http.post<CommonResponse<AppointmentDetails>>(RequestUrls.AUTHENTICATED.GET_APPOINTMENT_DETAILS, patientDetails);
    }

    getPatientBalance(patientId: string): Observable<CommonResponse<PatientBalanceResponse>> {
        return this.http.post<CommonResponse<PatientBalanceResponse>>(RequestUrls.AUTHENTICATED.GET_PATIENT_BALANCE, {
            patientId: patientId,
            suffix: this.storageService.urlSuffix,
        });
    }

    getAccountFeatures(): Observable<CommonResponse<AccountFeatureResponse>> {
        return this.http.post<CommonResponse<AccountFeatureResponse>>(RequestUrls.AUTHENTICATED.GET_ACCOUNT_FEATURES, {
            suffix: this.storageService.urlSuffix,
        });
    }

    getDraftInfo(apptId: string): Observable<CommonResponse<DraftInfo>> {
        return this.http.post<CommonResponse<DraftInfo>>(RequestUrls.AUTHENTICATED.GET_DRAFT_INFO, {
            patientId: this.storageService.patientId,
            apptId: apptId,
            suffix: this.storageService.urlSuffix
        });
    }

    verifyOTPWithoutDevice(code) {
        this.logger.postEntry(
            LogLevel.Info,
            'Posting deviceless 2FA verification request to :' + RequestUrls.AUTHENTICATED.VAERIRY_OTP_WIRHOUT_DEVICE,
            'AuthService.getAppointmentDetails',
            this.traceId,
            100
        );
        return this.http.post<CommonResponse<{}>>(RequestUrls.AUTHENTICATED.VAERIRY_OTP_WIRHOUT_DEVICE, {
            suffix: this.storageService.urlSuffix,
            authCode: code,
            patientId: this.storageService.patientId
        });
    }

    verifyPhonenumber(hash) {
        return this.http.post<CommonResponse<{}>>(RequestUrls.AUTHENTICATED.GET_PHONE_LIST, {
            suffix: this.storageService.urlSuffix,
            hash: hash,            
        });
    }

}
