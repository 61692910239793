import {LogLevel} from '@shared-models/common.model';

export const global_logging_conf  = {
    pulishersList: [
        {
            loggerName: 'console',
            loggerLocation: '',
            isActive: true
        },
        {
            loggerName: 'localStorage',
            loggerLocation: 'logging',
            isActive: true
        },
        {
            loggerName: 'webApi',
            loggerLocation: 'createPatientLog',
            isActive: true
        }]
};
