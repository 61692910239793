<!-- <head>
    <link rel="stylesheet" type="text/css" href="https://npmcdn.com/flatpickr/dist/themes/airbnb.css">
</head> -->

<!-- <link rel="stylesheet" href=".../flatpickr.min.css">
<script src=".../flatpickr.min.js"></script> -->
<form [formGroup]="smartLinkLoginFg">
    <div class="form-group "  
         [class.has-error]="smartLinkLoginFg.get('DOB').invalid && smartLinkLoginFg.get('DOB').touched">
        <label for="dobP" class="control-label">Date of Birth</label>
        <div class="input-group"
         [ngClass]="{'border-style': (onFocus && smartLinkLoginFg.get('DOB').untouched || (onFocus && smartLinkLoginFg.get('DOB').dirty)),
         'border-style-checkIcon':checkIcon,
         'border-style-error':(smartLinkLoginFg.get('DOB').invalid && smartLinkLoginFg.get('DOB').touched) && (dateErrorPresent)}" > 
            <input id="dobP" class="form-control input-date-picker default-cursor" placeholder="mm/dd/yyyy" type="text"
                   mwlFlatpickr [dateFormat]="'m/d/Y'"  [clickOpens]=false
                   [allowInput]=true #dobPicker [disableMobile]=true [maxDate]="maxDate" [minDate]="minDate"
                   (flatpickrOpen)="onOpenDatePicker()" (flatpickrValueUpdate)="inputValueUpdated()"
                   (flatpickrClose)="onCloseDatePicker()" 
                   value="{{dobData}}" 
                  
                    lang="en" formControlName="DOB" autocomplete="off"
                   appMaskDate (focus)="focus()" (blur)="blur()">
            <span class="input-group-addon"   
             (click)="openDatePicker($event)"  [ngClass]="{'datepicker-bg-color-valid':checkIcon}"> 
                                    <i class="glyphicon glyphicon-calendar"
                                   ></i>
                                </span>
                                <span class="input-group-addon" *ngIf="checkIcon" 
                                [ngClass]="{'datepicker-bg-color-valid':checkIcon}">
                                    <i class="glyphicon glyphicon-ok" 
                                    [ngClass]="{'checkIcon':checkIcon}"></i>
                                </span>      
                                <span class="input-group-addon" *ngIf="smartLinkLoginFg.get('DOB').invalid && smartLinkLoginFg.get('DOB').touched">
                                    <i class="glyphicon glyphicon-remove" 
                                    [ngClass]="{'errorIcon': (smartLinkLoginFg.get('DOB').invalid && smartLinkLoginFg.get('DOB').touched) && (dateErrorPresent)}"></i>
                                </span>                
        </div>
        <div *ngIf="smartLinkLoginFg.get('DOB').invalid && smartLinkLoginFg.get('DOB').touched" class="margin-top">
            <div class="formio-errors invalid-feedback" *ngIf="smartLinkLoginFg.get('DOB').errors['pattern'] || smartLinkLoginFg.get('DOB').errors['required']">
                <p  class="help-block ">{{errors.DATE_OF_BIRTH.FORMAT}}</p></div>
        </div>
        <div *ngIf="smartLinkLoginFg.get('DOB').invalid && smartLinkLoginFg.get('DOB').touched">
            <div class="formio-errors invalid-feedback" *ngIf="smartLinkLoginFg.get('DOB').errors['required']">
                <p class="help-block">{{errors.DATE_OF_BIRTH.REQUIRED}}</p></div>
        </div>
       
        <div *ngIf="onFocus && !(smartLinkLoginFg.get('DOB').invalid && smartLinkLoginFg.get('DOB').touched)" class="margin-top"  style="font-style: italic"
        [ngStyle]="{'color':checkIcon===true?'green':'#20a8d8'}" 
        [ngClass]="{'errorIcon':(smartLinkLoginFg.get('DOB').invalid && smartLinkLoginFg.get('DOB').touched) && (dateErrorPresent)}"
        >Month, Day, Year</div>
    </div>
</form>
