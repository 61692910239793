import {Injectable} from '@angular/core';


@Injectable()
export class ErrorsService {
    constructor() {

    }

    error(error: any) {

    }

}
