import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormValidationService } from '@shared-modules/ng-bootstrap-input/form-validation.service';
import { ZipValidators } from '@shared-validators/zip.validator';
import { PhoneValidators } from '@shared-validators/phone.validator';
var BootstrapInputTextComponent = /** @class */ (function () {
    function BootstrapInputTextComponent(formValidationService) {
        this.formValidationService = formValidationService;
        this.validators = [];
        this.maskConfig = {};
        this.onFocus = false;
    }
    Object.defineProperty(BootstrapInputTextComponent.prototype, "label", {
        get: function () {
            return this.inputConfig.label ? this.inputConfig.label : this.inputConfig.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BootstrapInputTextComponent.prototype, "maskArray", {
        get: function () {
            return this.inputConfig.validationConfig.maskArray;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BootstrapInputTextComponent.prototype, "minLength", {
        get: function () {
            return this.inputConfig.validationConfig.minLength;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BootstrapInputTextComponent.prototype, "required", {
        get: function () {
            return this.inputConfig.validationConfig.required;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BootstrapInputTextComponent.prototype, "validationRegex", {
        get: function () {
            return this.inputConfig.validationConfig.validationRegex;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BootstrapInputTextComponent.prototype, "maxLength", {
        get: function () {
            return this.inputConfig.validationConfig.maxLength;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BootstrapInputTextComponent.prototype, "customValidation", {
        get: function () {
            return this.inputConfig.validationConfig.customValidation;
        },
        enumerable: true,
        configurable: true
    });
    BootstrapInputTextComponent.prototype.ngOnInit = function () {
        this.configureInputMask();
        this.buildValidators();
        this.control = new FormControl(this.inputConfig.dataConfig && this.inputConfig.dataConfig.value ?
            this.inputConfig.dataConfig.value : '', this.validators);
        this.inputConfig.form.addControl(this.inputConfig.name, this.control);
    };
    BootstrapInputTextComponent.prototype.configureInputMask = function () {
        this.maskConfig = {};
        if (this.maskArray) {
            this.maskConfig['mask'] = this.maskArray;
            this.maskConfig['modelClean'] = true;
        }
        else {
            this.maskConfig['mask'] = false;
        }
    };
    BootstrapInputTextComponent.prototype.trimToMaxLength = function (inputConf) {
        return inputConf.substring(0, this.maxLength.value);
    };
    BootstrapInputTextComponent.prototype.buildValidators = function () {
        if (this.required && this.required.value) {
            this.validators.push(Validators.required);
            if (!this.required.message) {
                this.required.message = this.label + " is required";
            }
        }
        if (this.minLength && this.minLength.value) {
            this.validators.push(Validators.minLength(this.minLength.value));
            if (!this.minLength.message) {
                this.minLength.message = 'Length should be at least ' +
                    this.minLength.value + ' characters';
            }
        }
        if (this.maxLength && this.maxLength.value) {
            this.validators.push(Validators.maxLength(this.maxLength.value));
        }
        if (this.validationRegex && this.validationRegex.value) {
            this.validators.push(Validators.pattern(this.validationRegex.value));
            if (!this.validationRegex.message) {
                this.validationRegex.message = this.label + " is invalid";
            }
        }
        if (this.customValidation && this.customValidation.value === 'zip') {
            this.validators.push(ZipValidators.zipLength);
            if (!this.customValidation.message) {
                this.customValidation.message = this.label + " is invalid";
            }
        }
        if (this.customValidation && this.customValidation.value === 'phone') {
            this.validators.push(PhoneValidators.phoneLength);
            if (!this.customValidation.message) {
                this.customValidation.message = this.label + " is invalid";
            }
        }
    };
    BootstrapInputTextComponent.prototype.hasError = function () {
        var hasError = false;
        if (this.required && this.required.value) {
            hasError = this.formValidationService.hasError(this.inputConfig.form, this.inputConfig.name, this.inputConfig.form.submitted, 'required');
            this.inputConfig.validationConfig.errorMessage = this.required.message;
        }
        if (!hasError && this.minLength && this.minLength.value) {
            hasError = this.formValidationService.hasError(this.inputConfig.form, this.inputConfig.name, this.inputConfig.form.submitted, 'minlength');
            this.inputConfig.validationConfig.errorMessage = this.minLength.message;
        }
        if (!hasError && this.maxLength && this.maxLength.value) {
            hasError = this.formValidationService.hasError(this.inputConfig.form, this.inputConfig.name, this.inputConfig.form.submitted, 'maxlength');
            this.inputConfig.validationConfig.errorMessage = this.maxLength.message;
        }
        if (!hasError && this.validationRegex && this.validationRegex.value) {
            hasError = this.formValidationService.hasError(this.inputConfig.form, this.inputConfig.name, this.inputConfig.form.submitted, 'pattern');
            this.inputConfig.validationConfig.errorMessage = this.validationRegex.message;
        }
        if (!hasError && this.customValidation &&
            this.customValidation.value === 'zip') {
            hasError = this.formValidationService.hasError(this.inputConfig.form, this.inputConfig.name, this.inputConfig.form.submitted, 'zipLength');
            this.inputConfig.validationConfig.errorMessage = this.customValidation.message;
        }
        if (!hasError && this.customValidation &&
            this.customValidation.value === 'phone') {
            hasError = this.formValidationService.hasError(this.inputConfig.form, this.inputConfig.name, this.inputConfig.form.submitted, 'phoneLength');
            this.inputConfig.validationConfig.errorMessage = this.customValidation.message;
        }
        return hasError;
    };
    BootstrapInputTextComponent.prototype.isValid = function () {
        return this.formValidationService.isValid(this.inputConfig.form, this.inputConfig.name);
    };
    BootstrapInputTextComponent.prototype.getFormControl = function () {
        return this.inputConfig.form.controls[this.inputConfig.name];
    };
    BootstrapInputTextComponent.prototype.getMaxLength = function () {
        return this.maxLength && this.maxLength.value ?
            this.maxLength.value : false;
    };
    BootstrapInputTextComponent.prototype.getFormControlLabel = function () {
        return this.inputConfig.label ? this.inputConfig.label : false;
    };
    BootstrapInputTextComponent.prototype.focus = function () {
        this.onFocus = true;
        // console.log(this.onFocus)
        // this.getFormControl().markAsUntouched()
    };
    BootstrapInputTextComponent.prototype.blur = function () {
        this.onFocus = false;
        // console.log("blur")
    };
    return BootstrapInputTextComponent;
}());
export { BootstrapInputTextComponent };
