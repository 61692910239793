import {StorageService} from '@shared-services/storage.service';
import {Directive, DoCheck, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {Subscription} from 'rxjs';

import {equals} from '@shared-util/common.util';

import {LoaderOptions, LoaderType} from './loader.model';

@Directive({
    selector: '[appLoader]'
})

export class LoaderDirective implements DoCheck {

    @Input() appLoader: LoaderOptions;

    @Output() loaderVisible = new EventEmitter<boolean>();


    loaderType: LoaderType = LoaderType.BUTTON;

    disabledFieldset: HTMLFieldSetElement;

    // the promise element
    promiseEl: HTMLElement;

    // the applied spinner element
    spinnerEl: HTMLElement;

    // the promise list
    promiseList: Array<Promise<any> | Subscription> = [];

    private optionsRecord: any;


    // boolean to determine if promises were resolved
    get isPromiseActive(): boolean {
        return this.promiseList.length > 0;
    }

    constructor(el: ElementRef, private storageService: StorageService) {
        // save element
        this.promiseEl = el.nativeElement;
    }

    ngDoCheck() {
        if (!this.appLoader || !this.promiseEl) {
            return;
        }

        if (!this.appLoader.promises) {
            return;
        }

        if (!this.dectectOptionsChange()) {
            return;
        }

        this.promiseList = [];
        this.appLoader.promises.forEach(promise => {
            if (!promise || promise['fulfilled']) {
                return;
            }
            this.addPromise(promise);
        });

        this.loaderType = this.appLoader.loaderType;
        if (this.appLoader.disabledFieldSet) {
            this.disabledFieldset = this.appLoader.disabledFieldSet;
        }

        if (this.isPromiseActive && (this.loaderType === LoaderType.FULL_PAGE)) {
            this.checkAndInitPromiseHandler();
        }
    }

    private dectectOptionsChange() {
        if (equals(this.appLoader, this.optionsRecord)) {
            return false;
        }
        this.optionsRecord = this.appLoader;
        return true;
    }

    /**
     * Checks if all required parameters are there and inits the promise handler
     * @param {Object}promiseEl
     */
    checkAndInitPromiseHandler() {
        // check if element and promise is set
        if (this.promiseEl && this.isPromiseActive) {
            this.initLoadingState();
        }
    }

    /**
     * Handles everything to be triggered when the promiseEl is set
     * to loading state.
     * @param {Object}promiseEl
     */
    initLoadingState() {
        this.appendSpinnerTpl();

        if (this.loaderType === LoaderType.BUTTON) {
            this.disableEl(this.promiseEl);
            if (this.disabledFieldset) {
                this.disableEl(this.disabledFieldset);
            }
        }
    }

    /**
     * Handles everything to be triggered when loading is finished
     * @param {Object}loaderEl
     */
    cancelLoadingStateIfPromiseDone() {
        if (!this.isPromiseActive) {
            this.removeSpinnerTpl();
            if (this.loaderType === LoaderType.BUTTON) {
                this.enableEl(this.promiseEl);
                if (this.disabledFieldset) {
                    this.enableEl(this.disabledFieldset);
                }
            }
        }
    }

    /**
     * @param {Object}promiseEl
     */
    disableEl(el: HTMLElement) {
        el.setAttribute('disabled', 'disabled');
    }

    /**
     * @param {Object}promiseEl
     */
    enableEl(el: HTMLElement) {
        el.removeAttribute('disabled');
    }

    /**
     * $compile and append the spinner template.
     * @param {Object}promiseEl
     */
    appendSpinnerTpl() {
        const config = LoaderType.loaderConfig(this.loaderType);
        if (this.loaderType === LoaderType.FULL_PAGE) {
            this.promiseEl.classList.add('blur');
        }
        this.promiseEl.insertAdjacentHTML('beforeend', config.spinnerTpl);
        this.spinnerEl = <HTMLElement>this.promiseEl.getElementsByClassName(config.spinnerClass)[0];
        setTimeout(this.changeLoadingText.bind(this), 15000);
        this.loaderVisible.emit(true);
    }

    changeLoadingText() {
        if (this.promiseEl && this.promiseEl.getElementsByClassName('loading')[0]) {
            this.promiseEl.getElementsByClassName('loading')[0].innerHTML = 'Still loading...';
        }
    }

    removeSpinnerTpl() {
        if (this.loaderType === LoaderType.FULL_PAGE) {
            this.promiseEl.classList.remove('blur');
        }
        if (this.spinnerEl && this.spinnerEl.parentElement) {
            this.spinnerEl.parentElement.removeChild(this.spinnerEl);
        }
        this.loaderVisible.emit(false);
    }

    private addPromise(promise: Promise<any> | Subscription) {
        if (this.promiseList.indexOf(promise) !== -1) {
            return;
        }

        this.promiseList.push(promise);

        if (promise instanceof Promise) {
            promise.then.call(
                promise,
                () => this.finishPromise(promise),
                () => this.finishPromise(promise)
            );
        } else if (promise instanceof Subscription) {
            promise.add(() => this.finishPromise(promise));
        }
    }

    private finishPromise(promise: Promise<any> | Subscription) {
        promise['fulfilled'] = true;
        const index = this.promiseList.indexOf(promise);
        if (index === -1) {
            return;
        }
        this.promiseList.splice(index, 1);

        if (!this.isPromiseActive && (!this.appLoader.retainLoader || this.storageService.isSessionExpired)) {
            this.cancelLoadingStateIfPromiseDone();
        }
    }

    @HostListener('click')
    onClick() {
        // Click triggers @Input update
        // We need to use timeout to wait for @Input to update

        if (this.loaderType !== LoaderType.FULL_PAGE) {
            setTimeout(() => {
                // return if something else than a promise is passed
                if ((!this.promiseList || this.promiseList.length <= 0) && !this.appLoader.ignorePromises) {
                    return;
                }
                this.initLoadingState();

            }, 0);
        }
    }
}
