import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {empty, Observable, Subject, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {RequestUrls} from '@app/shared/constants/request-urls';
import {StorageService} from '@app/shared/services/storage.service';
import {CommonResponse} from '@shared-models/common.model';
import {LoginDetails} from '@app/auth/auth.model';

@Injectable()
export class HttpService {

    private sessionExpiredSubject: Subject<string>;

    constructor(private httpClient: HttpClient, private storageService: StorageService) {
        this.sessionExpiredSubject = new Subject<string>();
    }

    get authExpiry(): number {
        return this.storageService.getItemFromLocalStorage('authExpiry');
    }

    post<R>(url: string, body: any, options = {}): Observable<R> {
        options = this.setHeader(url, options);
        if(options == null) return;

        return this.httpClient.post<R>(url, body, options).pipe(
            tap(),
            catchError((error) => this.onErrorHandler(error))
        );
    }

    get<R>(url: string, options = {}): Observable<R> {
        options = this.setHeader(url, options);
        if(options == null) return;
        
        return this.httpClient.get<R>(url, options).pipe(
            tap(),
            catchError((error) => this.onErrorHandler(error))
        );
    }

    put<R>(url: string, body: any, options = {}): Observable<R> {
        options = this.setHeader(url, options);
        if(options == null) return;

        return this.httpClient.put<R>(url, body, options).pipe(
            tap(),
            catchError((error) => this.onErrorHandler(error))
        );
    }

    delete<R>(url: string, options = {}): Observable<R> {
        options = this.setHeader(url, options);
        if(options == null) return;

        return this.httpClient.delete<R>(url, options).pipe(
            tap(),
            catchError((error) => this.onErrorHandler(error))
        );
    }

    setHeader(url: string, options: object): object {
        if (RequestUrls.isAuthUrl(url)) {
            options['headers'] =
                    new HttpHeaders({'Authorization': `Bearer ${this.storageService.accountCode}:${this.storageService.authToken}`});
        } else if (RequestUrls.isAcuant(url)) {
            options['headers'] =
                new HttpHeaders({'Authorization': `LicenseKey ${btoa(this.storageService.acuantLicenseKey)}`});
        }
        return options;
    }

    private async refreshToken() {
        console.log("starting refreshToken");
        await this.post<CommonResponse<LoginDetails>>(RequestUrls.ANONYMOUS.REFRESH_TOKEN, {
            previousToken: this.storageService.authToken,
            patientId: this.storageService.getItemFromLocalStorage('patientId'),
            suffix: this.storageService.getItemFromLocalStorage('urlSuffix')
        }).toPromise().then((response) => {
            debugger;
            console.log("new: " + response.data.AcccessToken);
            this.storageService.authToken = response.data.AcccessToken;
            this.storageService.authExpiry = response.data.TokenExpiry;
            this.storageService.storeAuthTokenInLocalStorage();
            return this.storageService.authToken;
        }).catch (err => {
            this.onErrorHandler(err);
            return null;
          });
          console.log("finished refreshToken");
    }

    private onErrorHandler(error: any) {
        if (error.status === 401) {
            this.storageService.isSessionExpired = true;
            this.sessionExpiredSubject.next(error.error['message']);
            return empty();
        }
        if ('onLine' in navigator && !navigator.onLine) {
            error.error['message'] = 'Internet connection not available. Please check your internet connection and try again!!';
        }
        return throwError(error);
    }

    onSessionExpired(): Observable<string> {
        return this.sessionExpiredSubject;
    }
}
