import {Injectable} from '@angular/core';

@Injectable()
export class FormValidationService {

    constructor() {
    }

    hasError(form, fieldName, formSubmitted, errorName) {
        return (form.controls[fieldName].touched) && form.controls[fieldName].invalid && form.controls[fieldName].hasError(errorName);
    }

    isValid(form, fieldName) {
        return form.controls[fieldName].touched && form.controls[fieldName].valid;
    }

}
