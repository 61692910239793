export const environment = {
    production: true,
    baseUrl: 'https://api.chkin.com/patient/api/',
    currentEnv: 'Production',
    mdpayUrl: 'https://msgmngr.com/',
    // matomoUrl: 'https://matomo-prod.relatient.net/',
    //matomoId: 3
    coralogixRUMPublicKey: 'cxtp_MJwMQkvYQuYL2ra5x4uhelwQv2jWZB',
    coralogixRUMCLIKey: 'cxtp_cbPN7DSmyEVEGzroltimTYEbnOtSwD',
    coralogixDomain: 'US1',
    enableCoralogixLogging: true
};
