<div class="form-group" 
 
[class.has-error]="hasError()">
    <label *ngIf="getFormControlLabel()" for="{{this.inputConfig.name}}" class="control-label">{{this.getFormControlLabel()}}</label>
    <input id="{{this.inputConfig.name}}" class="form-control" type="text" lang="en" [formControl]="getFormControl()"
           [attr.maxlength]="getMaxLength()" [textMask]="maskConfig"
           [ngClass]="{'border-style-checkIcon':isValid() && getFormControl().touched ,'border-style':onFocus && getFormControl().untouched,
                    'border-style-error':!isValid() && getFormControl().touched}"
           (focus)="focus()" (focusout)="blur()">  
           <!-- <span class="input-group-addon" *ngIf="isValid()" 
           [ngClass]="{'datepicker-bg-color-valid':isValid()}">
               <i class="glyphicon glyphicon-ok" 
               [ngClass]="{'checkIcon':isValid()}"></i>
           </span>       -->
           <!-- <span class="input-group-addon" *ngIf="patientIDLoginFg.get('DOB').invalid && patientIDLoginFg.get('DOB').touched">
               <i class="glyphicon glyphicon-remove" 
               [ngClass]="{'errorIcon': patientIDLoginFg.get('DOB').invalid && patientIDLoginFg.get('DOB').touched}"></i>
           </span>     -->
           
    <div>
        <div class="formio-errors invalid-feedback position-right margin-top" *ngIf="hasError()"><p class="help-block">
            {{inputConfig.validationConfig.errorMessage}}</p></div>
    </div>
</div>
