import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

import {AuthService} from '@shared-services/auth.service';
import {StorageService} from '@shared-services/storage.service';
import {PopupType} from '@shared-components/popup/popup.model';
import {LoaderType} from '@shared-directives/loader/loader.model';
import {RoutePaths} from '@shared-constants/route-paths';


import {PatientDetails} from '../../auth.model';
import {PopupService} from '@shared-components/popup/popup.service';
import {ERROR} from '@shared-constants/error-messages';
import {LogService} from '@shared-services/log.service';
import {LogLevel} from '@shared-models/common.model';
// import { MatomoTracker } from 'ngx-matomo';

@Component({
    selector: 'app-send-otp',
    templateUrl: './send-otp.component.html',
    styleUrls: ['./send-otp.component.scss']
})
export class SendOtpComponent implements OnInit, AfterViewInit, OnDestroy {
    title = 'Identity Verification';
    sendOtpFg: FormGroup;
    patientDetails: PatientDetails[];

    sendOtpSubscription: Subscription;
    public loaderTypeEnum = LoaderType;
    private traceId: string;
    logData= {};
    finalog = {};
    arrayDate = {};
    logText = 'Application Log Send OTP';

    constructor(private storageService: StorageService, private sendOtpFb: FormBuilder, private authService: AuthService,
                private router: Router, private routePaths: RoutePaths, private popupService: PopupService,
                private logger: LogService
                ) {
        this.traceId = this.logger.genTraceId();

    }

    ngOnInit() {
        this.logger.postEntry(
            LogLevel.Info,
            'Initializing Send OTP Component',
            'SendOTP.ngOnInit',
            this.traceId,
            100
        );
        this.patientDetails = this.authService.patientDetails;
        this.setupFormControls();
    }

    ngAfterViewInit() {
        this.logger.postEntry(
            LogLevel.Info,
            'Send OTP Component initialized',
            'SendOTP.ngAfterViewInit',
            this.traceId,
            100
        );
    }

    ngOnDestroy() {
        this.logger.postEntry(
            LogLevel.Info,
            'Destroying Send OTP Component',
            'SendOTP.ngOnInit',
            this.traceId,
            100
        );
    }

    setupFormControls() {
        this.logger.postEntry(
            LogLevel.Info,
            'Setting up Send OTP form controls',
            'SendOTP.setupFormControls',
            this.traceId,
            100
        );
        this.sendOtpFg = this.sendOtpFb.group({
            destination: this.sendOtpFb.control('', Validators.required),
            patid: this.sendOtpFb.control(this.storageService.patientId),
        });
    }

    getValue(destination) {
        this.arrayDate['destination'] = destination;
        this.prepareLog(this.logText, this.arrayDate);
    }

    sendOtp() {
        this.logger.postEntry(
            LogLevel.Info,
            'Sending 2FA code',
            'SendOTP.sendOTP',
            this.traceId,
            100
        );
        if (isNaN(this.sendOtpFg.value.destination)) {
            this.sendOtpFg.value.destination = 'Email';
        } else {
            this.sendOtpFg.value.destination = 'Phone';
        }
        const accountInfoDetails = this.storageService.accountInfoDetails
        this.sendOtpFg.value.loginAccount = accountInfoDetails.Company
        this.sendOtpSubscription = this.authService.sendOTP(this.sendOtpFg.value).subscribe(() => {
            this.storageService.isDeviceNotAvailable = false;
            this.logger.postEntry(
                LogLevel.Info,
                '2FA code sent to user\'s ' + this.sendOtpFg.value.destination,
                'SendOTP.sendOTP',
                this.traceId,
                100
            );
            // this.matomoTracker.trackEvent('checkin-patient-app', 'sendOtp', 'success');
            this.logger.postEntry(
                LogLevel.Info,
                'Navigating to ' + this.routePaths.VERIFY_OTP,
                'SendOTP.sendOTP',
                this.traceId,
                100
            );
            this.arrayDate['SubmitOTP'] = 'done';
            this.prepareLog(this.logText, this.arrayDate);
            this.router.navigate([this.routePaths.VERIFY_OTP]);
        }, (err) => {
            this.handleError(err);
           // this.matomoTracker.trackEvent('checkin-patient-app', 'sendOtp', 'fail');
          });
    }

    donotHaveDevice() {
        this.logger.postEntry(
            LogLevel.Info,
            'User device marked unavailable',
            'SendOTP.donotHaveDevice',
            this.traceId,
            100
        );
        this.storageService.isDeviceNotAvailable = true;
        this.logger.postEntry(
            LogLevel.Info,
            'Navigating to ' + this.routePaths.VERIFY_OTP,
            'SendOTP.donotHaveDevice',
            this.traceId,
            100
        );
        this.arrayDate['noDevice'] = 'clicked';
        this.prepareLog(this.logText, this.arrayDate);
        this.router.navigate([this.routePaths.VERIFY_OTP]);
    }

    showInfo() {
        this.logger.postEntry(
            LogLevel.Info,
            'Displaying 2FA info modal.',
            'SendOTP.showInfo',
            this.traceId,
            100
        );
        this.popupService.showModal('' +
            'Why do I need a<br /> verification code?',
            'Before proceeding, you need to verify your identity.',
            PopupType.INFO,
            true
        );
    }

    private handleError(err) {
        this.logger.postEntry(
            LogLevel.Error,
            err.error.message,
            'SendOTP.sendOTP',
            this.traceId,
            err.status
        );
        this.popupService.showModal(ERROR, err.error.message, PopupType.ERROR, true);
        if (err.status === 404) {
            this.logger.postEntry(
                LogLevel.Info,
                'Navigating to ' + this.routePaths.LOGIN,
                'SendOTP.sendOTP',
                this.traceId,
                100
            );
            this.router.navigate([this.routePaths.LOGIN]);
        }

    }

    private prepareLog(formName, data) {
        var formvalue = formName
        if (data) {
            //console.log(this.logData);
            this.finalog[formvalue] = JSON.stringify(data);
            this.finalog['patientId'] = this.storageService.patientId;
            //this.finalog['appointmentId'] = this.storageService.appointmentId;   
        }
        var textinfo = 'Getting log for Patient Id ' + this.storageService.patientId + ' Send OTP Page:';
        this.logger.trackFormIoEvents(textinfo, JSON.stringify(this.finalog));
    }
}
