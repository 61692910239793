import {Component, Input, OnInit} from '@angular/core';
import {BootstrapInputConfigInterface} from '@shared-modules/ng-bootstrap-input/bootstrap-input-config';
import {AbstractControl, FormControl, ValidatorFn, Validators} from '@angular/forms';
import {InputComponentInterface} from '@shared-modules/ng-bootstrap-input/inputs/input-component-interface';
import {FormValidationService} from '@shared-modules/ng-bootstrap-input/form-validation.service';
import {ZipValidators} from '@shared-validators/zip.validator';
import {PhoneValidators} from '@shared-validators/phone.validator';

@Component({
    selector: 'app-bootstrap-input-text',
    templateUrl: 'bootstrap-input-text.component.html'
})

export class BootstrapInputTextComponent implements InputComponentInterface, OnInit {

    @Input() inputConfig: BootstrapInputConfigInterface;
    control: FormControl;
    validators: ValidatorFn[] = [];
    maskConfig = {};
    regex;
    onFocus:boolean=false

    get label() {
        return this.inputConfig.label ? this.inputConfig.label : this.inputConfig.name;
    }

    get maskArray() {
        return this.inputConfig.validationConfig.maskArray;
    }

    get minLength() {
        return this.inputConfig.validationConfig.minLength;
    }

    get required() {
        return this.inputConfig.validationConfig.required;
    }

    get validationRegex() {
        return this.inputConfig.validationConfig.validationRegex;
    }

    get maxLength() {
        return this.inputConfig.validationConfig.maxLength;
    }

    get customValidation() {
        return this.inputConfig.validationConfig.customValidation;
    }

    ngOnInit(): void {
        this.configureInputMask();
        this.buildValidators();
        this.control = new FormControl(this.inputConfig.dataConfig && this.inputConfig.dataConfig.value ?
            this.inputConfig.dataConfig.value : '', this.validators);
        this.inputConfig.form.addControl(this.inputConfig.name, this.control);
    }

    constructor(private formValidationService: FormValidationService) {
    }

    configureInputMask() {
        this.maskConfig = {};
        if (this.maskArray) {
            this.maskConfig['mask'] = this.maskArray;
            this.maskConfig['modelClean'] = true;
        } else {
            this.maskConfig['mask'] = false;
        }

    }

    trimToMaxLength(inputConf: any) {
        return inputConf.substring(0, this.maxLength.value);
    }

    buildValidators(): void {

        if (this.required && this.required.value) {
            this.validators.push(Validators.required);
            if (!this.required.message) {
                this.required.message = `${this.label} is required`;
            }
        }

        if (this.minLength && this.minLength.value) {
            this.validators.push(Validators.minLength(this.minLength.value));
            if (!this.minLength.message) {
                this.minLength.message = 'Length should be at least ' +
                    this.minLength.value + ' characters';
            }
        }

        if (this.maxLength && this.maxLength.value) {
            this.validators.push(Validators.maxLength(this.maxLength.value));
        }

        if (this.validationRegex && this.validationRegex.value) {
            this.validators.push(Validators.pattern(this.validationRegex.value));
            if (!this.validationRegex.message) {
                this.validationRegex.message = `${this.label} is invalid`;
            }
        }

        if (this.customValidation && this.customValidation.value === 'zip') {
            this.validators.push(ZipValidators.zipLength);
            if (!this.customValidation.message) {
                this.customValidation.message = `${this.label} is invalid`;
            }
        }

        if (this.customValidation && this.customValidation.value === 'phone') {
            this.validators.push(PhoneValidators.phoneLength);
            if (!this.customValidation.message) {
                this.customValidation.message = `${this.label} is invalid`;
            }
        }

    }

    hasError(): boolean {
        let hasError = false;
        if (this.required && this.required.value) {
            hasError = this.formValidationService.hasError(this.inputConfig.form, this.inputConfig.name,
                this.inputConfig.form.submitted, 'required');
            this.inputConfig.validationConfig.errorMessage = this.required.message;
        }

        if (!hasError && this.minLength && this.minLength.value) {
            hasError = this.formValidationService.hasError(this.inputConfig.form, this.inputConfig.name,
                this.inputConfig.form.submitted, 'minlength');
            this.inputConfig.validationConfig.errorMessage = this.minLength.message;
        }

        if (!hasError && this.maxLength && this.maxLength.value) {
            hasError = this.formValidationService.hasError(this.inputConfig.form, this.inputConfig.name,
                this.inputConfig.form.submitted, 'maxlength');
            this.inputConfig.validationConfig.errorMessage = this.maxLength.message;
        }

        if (!hasError && this.validationRegex && this.validationRegex.value) {
            hasError = this.formValidationService.hasError(this.inputConfig.form, this.inputConfig.name,
                this.inputConfig.form.submitted, 'pattern');
            this.inputConfig.validationConfig.errorMessage = this.validationRegex.message;
        }

        if (!hasError && this.customValidation &&
            this.customValidation.value === 'zip') {
            hasError = this.formValidationService.hasError(this.inputConfig.form, this.inputConfig.name,
                this.inputConfig.form.submitted, 'zipLength');
            this.inputConfig.validationConfig.errorMessage = this.customValidation.message;
        }

        if (!hasError && this.customValidation &&
            this.customValidation.value === 'phone') {
            hasError = this.formValidationService.hasError(this.inputConfig.form, this.inputConfig.name,
                this.inputConfig.form.submitted, 'phoneLength');
            this.inputConfig.validationConfig.errorMessage = this.customValidation.message;
        }

        return hasError;
    }

    isValid(): boolean {
        
        return this.formValidationService.isValid(this.inputConfig.form, this.inputConfig.name);
    }

    getFormControl(): AbstractControl {
        return this.inputConfig.form.controls[this.inputConfig.name];
    }

    getMaxLength() {
        return this.maxLength && this.maxLength.value ?
            this.maxLength.value : false;
    }

    getFormControlLabel() {
        return this.inputConfig.label ? this.inputConfig.label : false;
    }

    focus()
    {
        this.onFocus=true
        // console.log(this.onFocus)
        // this.getFormControl().markAsUntouched()
    }
    blur()
    {
        this.onFocus=false
        // console.log("blur")
    }

}
