import {Injectable, Injector} from '@angular/core';
import {LogWebApi} from '@app/shared/publishers/log-web-api.service';
import {LogPublisher} from '@app/shared/publishers/log-publisher';
import {LogLocalStorage} from '@app/shared/publishers/log-local-storage.service';
import {global_logging_conf} from '@app/shared/publishers/global-logging-conf';
import {LogConsole} from '@app/shared/publishers/log-console.service';
import {HttpService} from '@shared-services/http.service';
import { StorageService } from '@shared-services/storage.service';

@Injectable()
export class LogPublishersService {
    constructor(public injector: Injector, public httpService: HttpService, public storageService: StorageService) {
        // Build publishers arrays
        this.buildPublishers();
    }

    // Public properties
    publishers: LogPublisher[] = [];
    private logLocalStorage: LogLocalStorage;
    public logWepAPI: LogWebApi;

    // Build publishers array
    buildPublishers(): void {
        let logPub: any;
        for (const pub of global_logging_conf.pulishersList.filter(p => p.isActive)) {
            switch (pub.loggerName.toLowerCase()) {
                case 'console':
                    logPub = new LogConsole(this.injector);
                    break;
                case 'localstorage':
                    logPub = new LogLocalStorage(this.injector);
                    this.logLocalStorage = logPub;
                    break;
                case 'webapi':
                    logPub = new LogWebApi(this.injector, this.logLocalStorage, this.httpService, this.storageService);
                    this.logWepAPI = logPub;
                    break;
            }
            // Set location of logging
            logPub.logPublisherConfig = pub;
            // Add publisher to array
            this.publishers.push(logPub);
        }
    }

}
