
import {of as observableOf, Observable} from 'rxjs';
import {LogEntry} from '@shared-models/common.model';
import {LogPublisher} from '@app/shared/publishers/log-publisher';
import {Injector} from '@angular/core';

export class LogConsole extends LogPublisher {

    constructor(public injector: Injector) {
        super(injector);
    }

    log(entry: LogEntry): Observable<any> {
        // Log to console
        // console.log(entry.buildLogString());
        return observableOf(entry);
    }

    clear(): Observable<any> {
        console.clear();
        return observableOf(true);
    }

}
