<div class="app flex-row align-items-center">
    <div class="container not-found-container">
        <div class="row justify-content-center">
            <div class="col-xs-12">
                <div class="clearfix">
                    <h1 class="float-left display-3 mr-4" *ngIf="type==='not-found'">404</h1>
                    <h4 class="pt-3">{{pText}}</h4>
                    <p class="text-muted">{{mutedText}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
