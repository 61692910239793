import { AfterViewInit, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LogLevel } from '@shared-models/common.model';
import { StorageService } from '@shared-services/storage.service';
import { CommonService } from '@shared-services/common.service';
import { LoaderType } from '@app/shared/directives/loader/loader.model';
import { Error } from '@shared-constants/error-messages';
// import { MatomoTracker } from 'ngx-matomo';
import * as moment from 'moment';
import { LogService } from '@shared-services/log.service';
var DemographicsLoginComponent = /** @class */ (function () {
    // phoneMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    // zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
    function DemographicsLoginComponent(loginFb, commonService, storageService, logger) {
        this.loginFb = loginFb;
        this.commonService = commonService;
        this.storageService = storageService;
        this.logger = logger;
        // startOption:FlatpickrDirective={
        //     maxDate:new Date()
        // }
        this.demographicsLoginFg = this.loginFb.group({});
        this.errors = Error;
        this.maxDate = new Date();
        this.minDate = new Date('01/01/1900');
        this.isDatePickerOpen = false;
        this.retainLoader = true;
        // streetAddressConfig: BootstrapInputConfigInterface;
        // cityConfig: BootstrapInputConfigInterface;
        // zipConfig: BootstrapInputConfigInterface;
        // cellConfig: BootstrapInputConfigInterface;
        // homeConfig: BootstrapInputConfigInterface;
        this.loaderTypeEnum = LoaderType;
        this.onFocus = false;
        this.checkIcon = false;
        this.dateErrorPresent = false;
    }
    Object.defineProperty(DemographicsLoginComponent.prototype, "dobFormControl", {
        /*get addressFormGroup(): BootstrapFormGroup {
            return this.demographicsLoginFg.get('Address') as BootstrapFormGroup;
        }
    
        get phoneFormGroup(): BootstrapFormGroup {
            return this.demographicsLoginFg.get('PhoneNumber') as BootstrapFormGroup;
        }*/
        get: function () {
            return this.demographicsLoginFg.get('DOB');
        },
        enumerable: true,
        configurable: true
    });
    DemographicsLoginComponent.prototype.ngOnInit = function () {
        this.logger.postEntry(LogLevel.Info, 'Initializing Demographic Login Component', 'DemographicLogin.ngOnInit', this.traceId, 100);
        this.storageService.clear();
        this.states = this.commonService.states;
        this.gender = this.commonService.gender;
        this.setupFormControls();
        // this.dobFormControl.valueChanges.subscribe(()=>{
        //     this.dobFormControl.value.trim()
        //      this.tempDate=new Date(this.dobFormControl.value)
        // this.dobFormControl.statusChanges.subscribe(()=>{
        //     if(this.dobFormControl.valid && (this.maxDate.getTime()>= this.tempDate.getTime()) )
        //     {
        //         this.checkIcon=true
        //         this.dateErrorPresent=false
        //     }
        //     else{
        //         this.checkIcon=false
        //         this.dateErrorPresent=true
        //     }
        // })
        // })
    };
    DemographicsLoginComponent.prototype.ngAfterViewInit = function () {
        this.logger.postEntry(LogLevel.Info, 'Demographic Login Component initialized', 'DemographicLogin.ngAfterViewInit', this.traceId, 100);
    };
    DemographicsLoginComponent.prototype.ngOnDestroy = function () {
        this.logger.postEntry(LogLevel.Info, 'Destroying Demographic Login Component', 'DemographicLogin.ngOnDestroy', this.traceId, 100);
    };
    DemographicsLoginComponent.prototype.isFormValid = function () {
        this.logger.postEntry(LogLevel.Info, 'Checking if demographics form is valid', 'DemographicLogin.isFormValid', this.traceId, 100);
        return this.demographicsLoginFg.valid;
    };
    DemographicsLoginComponent.prototype.setupFormControls = function () {
        this.logger.postEntry(LogLevel.Info, 'Setting up demographics form controls', 'DemographicComponent.setupFormControls', this.traceId, 100);
        this.demographicsLoginFg = this.loginFb.group({
            DOB: this.loginFb.control('', Validators.required),
            PhoneNumber: this.loginFb.group({}),
            Address: this.loginFb.group({}),
        });
        this.logger.postEntry(LogLevel.Info, 'Configuring FirstName', 'DemographicComponent.setupFormControls', this.traceId, 100);
        this.firstNameConfig = {
            type: 'text',
            name: 'FirstName',
            form: this.demographicsLoginFg,
            label: 'First Name',
            validationConfig: {
                required: {
                    value: true,
                    message: this.errors.LOGIN.FIRSTNAME.REQUIRED
                },
                maxLength: {
                    value: 100
                },
                validationRegex: {
                    value: /^[a-zA-Z][a-zA-Z\s]*([-a-zA-Z\s][a-zA-Z\s]*)?$/,
                    message: this.errors.LOGIN.FIRSTNAME.INVALID
                }
            },
        };
        this.logger.postEntry(LogLevel.Info, 'Configuring LastName', 'DemographicComponent.setupFormControls', this.traceId, 100);
        this.lastNameConfig = {
            type: 'text',
            name: 'LastName',
            form: this.demographicsLoginFg,
            label: 'Last Name',
            validationConfig: {
                required: {
                    value: true,
                    message: this.errors.LOGIN.LASTNAME.REQUIRED
                },
                maxLength: {
                    value: 100
                },
                validationRegex: {
                    value: /^[a-zA-Z][a-zA-Z\s]*([-a-zA-Z\s][a-zA-Z\s]*)?$/,
                    message: this.errors.LOGIN.LASTNAME.INVALID
                }
            },
        };
        // this.addAdditionalControls();
    };
    // Don't delete: Additional Controls are disabled as per currently not in use but might be utilized in near future
    // addAdditionalControls() {
    //     if (this.storageService.accountInfoDetails.Sex) {
    //         this.demographicsLoginFg.addControl('Sex', this.loginFb.control(this.gender[0], Validators.required));
    //     }
    //     if (this.storageService.accountInfoDetails.HomePhone) {
    //         this.homeConfig = {
    //             type: 'text',
    //             name: 'Home',
    //             form: this.phoneFormGroup,
    //             label: 'Home Phone',
    //             validationConfig: {
    //                 required: {
    //                     value: true,
    //                     message: this.errors.LOGIN.PHONE.HOME.REQUIRED
    //                 },
    //                 customValidation: {
    //                     value: 'phone',
    //                     message: this.errors.LOGIN.PHONE.HOME.INVALID
    //                 },
    //                 maskArray: this.phoneMask,
    //             },
    //         };
    //     }
    //     if (this.storageService.accountInfoDetails.CellPhone) {
    //         this.cellConfig = {
    //             type: 'text',
    //             name: 'Mobile',
    //             form: this.phoneFormGroup,
    //             label: 'Cell Phone',
    //             validationConfig: {
    //                 required: {
    //                     value: true,
    //                     message: this.errors.LOGIN.PHONE.CELL.REQUIRED
    //                 },
    //                 customValidation: {
    //                     value: 'phone',
    //                     message: this.errors.LOGIN.PHONE.CELL.INVALID
    //                 },
    //                 maskArray: this.phoneMask,
    //             },
    //         };
    //     }
    //     if (this.storageService.accountInfoDetails.streetAddress) {
    //         this.streetAddressConfig = {
    //             type: 'text',
    //             name: 'StreetAddress',
    //             form: this.addressFormGroup,
    //             label: 'Street Address',
    //             validationConfig: {
    //                 required: {
    //                     value: true,
    //                     message: this.errors.LOGIN.ADDRESS.STREET.REQUIRED
    //                 },
    //                 maxLength: {
    //                     value: 100
    //                 },
    //             },
    //         };
    //     }
    //     if (this.storageService.accountInfoDetails.City) {
    //         this.cityConfig = {
    //             type: 'text',
    //             name: 'City',
    //             form: this.addressFormGroup,
    //             label: 'City',
    //             validationConfig: {
    //                 required: {
    //                     value: true,
    //                     message: this.errors.LOGIN.ADDRESS.CITY.REQUIRED
    //                 },
    //                 maxLength: {
    //                     value: 100
    //                 },
    //                 validationRegex: {
    //                     value: /^[a-zA-Z]+(?:[\s-.]*[a-zA-Z]*)*$/,
    //                     message: this.errors.LOGIN.ADDRESS.CITY.INVALID
    //                 }
    //             },
    //         };
    //     }
    //     if (this.storageService.accountInfoDetails.State) {
    //         this.addressFormGroup.addControl('State', this.loginFb.control(this.states[0].Code, Validators.required));
    //     }
    //     if (this.storageService.accountInfoDetails.Zip) {
    //         this.zipConfig = {
    //             type: 'text',
    //             name: 'Zip',
    //             form: this.addressFormGroup,
    //             label: 'Zip',
    //             validationConfig: {
    //                 required: {
    //                     value: true,
    //                     message: this.errors.LOGIN.ADDRESS.ZIP.REQUIRED
    //                 },
    //                 customValidation: {
    //                     value: 'zip',
    //                     message: this.errors.LOGIN.ADDRESS.ZIP.INVALID
    //                 },
    //                 maskArray: this.zipCodeMask,
    //             },
    //         };
    //     }
    // }
    DemographicsLoginComponent.prototype.openDatePicker = function (event) {
        this.logger.postEntry(LogLevel.Info, 'Attempting to open date picker', 'DemographicComponent.openDatePicker', this.traceId, 100);
        this.dobPicker.nativeElement.nextSibling.focus();
        this.dobPicker.nativeElement.nextSibling.click();
        if (!this.isDatePickerOpen) {
            this.dobPicker.nativeElement.focus();
            this.dobPicker.nativeElement.click();
            this.dobPicker.nativeElement._flatpickr.open();
            event.stopPropagation();
            return false;
        }
        this.logger.postEntry(LogLevel.Info, 'Date picker is already open', 'DemographicComponent.openDatePicker', this.traceId, 100);
    };
    DemographicsLoginComponent.prototype.onOpenDatePicker = function () {
        this.logger.postEntry(LogLevel.Info, 'Opened date picker', 'DemographicComponent.onOpenDatePicker', this.traceId, 100);
        this.prevSelectedDate = this.dobPicker.nativeElement._flatpickr.selectedDates[0];
        this.isDatePickerOpen = true;
    };
    DemographicsLoginComponent.prototype.onCloseDatePicker = function () {
        var _this = this;
        this.logger.postEntry(LogLevel.Info, 'Closing date picker', 'DemographicComponent.onCloseDatePicker', this.traceId, 100);
        this.dobFormControl.markAsTouched();
        this.dobPicker.nativeElement.blur();
        var calSelectedDate = this.dobPicker.nativeElement._flatpickr.selectedDates[0];
        if (this.dobPicker.nativeElement.value) {
            var dateMoment = moment(this.dobPicker.nativeElement.value, 'MM/DD/YYYY', true);
            var date = dateMoment.toDate();
            if (dateMoment.isValid() && (dateMoment.year() >= 1900) && (date.getTime() <= this.maxDate.getTime())) {
                this.dobPicker.nativeElement._flatpickr.setDate(this.dobPicker.nativeElement.value);
                this.checkIcon = true;
                this.onFocus = false;
                this.dateErrorPresent = false;
            }
            else {
                this.dobPicker.nativeElement._flatpickr.clear();
                this.checkIcon = false;
                this.dateErrorPresent = true;
            }
        }
        else {
            this.checkIcon = false;
            this.dateErrorPresent = true;
            this.dobPicker.nativeElement._flatpickr.clear();
        }
        setTimeout(function () {
            _this.isDatePickerOpen = false;
        }, 200);
    };
    DemographicsLoginComponent.prototype.inputValueUpdated = function () {
        this.prevSelectedDate = this.dobPicker.nativeElement._flatpickr.selectedDates[0];
    };
    DemographicsLoginComponent.prototype.getFormValue = function () {
        var loginFormVal = this.demographicsLoginFg.value;
        loginFormVal.DOB = this.dobPicker.nativeElement.value;
        loginFormVal.suffix = this.storageService.urlSuffix;
        // if (this.storageService.accountInfoDetails.HomePhone) {
        //     loginFormVal.PhoneNumber.Home = `+${loginFormVal.PhoneNumber.Home.replace(/\D+/g, '')}`;
        // }
        // if (this.storageService.accountInfoDetails.CellPhone) {
        //     loginFormVal.PhoneNumber.Mobile = `+${loginFormVal.PhoneNumber.Mobile.replace(/\D+/g, '')}`;
        // }
        return loginFormVal;
    };
    DemographicsLoginComponent.prototype.focus = function () {
        // this.dobFormControl.untouched
        this.onFocus = true;
        this.checkIcon = false;
        this.dobFormControl.markAsUntouched();
        this.dobFormControl.markAsPristine();
        // console.log(this.onFocus)
        // this.checkIcon=false
        // alert("asd")
    };
    DemographicsLoginComponent.prototype.blur = function () {
        this.onFocus = false;
        var tempDob = new Date(this.dobPicker.nativeElement.value);
        if ((this.maxDate.getTime() >= tempDob.getTime())) {
            this.checkIcon = true;
            this.dateErrorPresent = false;
            //   console.log(this.checkIcon)
        }
        else {
            this.dobFormControl.setValue('');
            this.checkIcon = false;
            this.dateErrorPresent = true;
            // console.log(this.checkIcon)
        }
    };
    return DemographicsLoginComponent;
}());
export { DemographicsLoginComponent };
