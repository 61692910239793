import {NgModule} from '@angular/core';

import {LoaderDirective} from '@shared-directives/loader/loader.directive';

@NgModule({
    declarations: [
        LoaderDirective,
    ],
    exports: [
        LoaderDirective
    ]
})
export class LoaderModule {
}
