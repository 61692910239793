import {FormArray, FormControl, FormGroup} from '@angular/forms';

export class FormUtil {
    public static markAsTouched(group: FormGroup | FormArray) {
        this.iterateFormControlsMarkAsTouchedOrUntouched(true, group);
    }

    public static markAsUntouched(group: FormGroup | FormArray) {
        this.iterateFormControlsMarkAsTouchedOrUntouched(false, group);
    }

    public static iterateFormControlsMarkAsTouchedOrUntouched(isMarkAsTouched: boolean, group: FormGroup | FormArray) {
        if (group && group.controls) {
            Object.keys(group.controls).map((field) => {
                const control = group.get(field);
                if (control instanceof FormControl) {
                    if (isMarkAsTouched === true) {
                        control.markAsTouched({onlySelf: true});
                        control.markAsDirty({onlySelf: true});
                    } else {
                        control.markAsUntouched({onlySelf: true});
                    }
                } else if (control instanceof FormGroup || control instanceof FormArray) {
                    this.markAsTouched(control);
                }
            });
        }
    }
}
