import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlatpickrModule} from 'angularx-flatpickr';

import {BootstrapInputModule} from '@shared-modules/ng-bootstrap-input/bootstrap-input.module';
import {LoginComponent} from '@auth/login/login.component';
import {ComponentsModule} from '@shared-components/components.module';
import {LoaderModule} from '@shared-directives/loader/loader.module';
import {MaskDateModule} from '@shared-directives/mask/date-mask.module';

import {PatientIDLoginComponent} from './patient-login/patientID-login.component';
import {DemographicsLoginComponent} from './demographics-login/demographic-login.component';
import {SmartLinkLoginComponent} from './smart-link/smart-link.component';

@NgModule({
    imports: [FormsModule,
        ReactiveFormsModule,
        BootstrapInputModule,
        FlatpickrModule,
        CommonModule,
        BrowserModule,
        ComponentsModule,
        LoaderModule,
        MaskDateModule
    ],
    declarations: [LoginComponent, PatientIDLoginComponent, DemographicsLoginComponent, SmartLinkLoginComponent],
    providers: []
})
export class LoginModule {
}
