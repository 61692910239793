import {Injectable} from '@angular/core';
import {PopupType} from '@shared-components/popup/popup.model';
import {PopupComponent} from '@shared-components/popup/popup.component';

@Injectable()
export class PopupService {
    private modalComponent: PopupComponent;

    constructor() {
    }

    set modal(value: PopupComponent) {
        this.modalComponent = value;
    }

    showModal(heading: string, message: string, type: PopupType, isVisible: boolean, btnName?: string) {
        this.modalComponent.modal = {
            'heading': heading,
            'message': message,
            'type': type,
            'isVisible': isVisible,
            'btnName': btnName ? btnName : 'Ok'
        };
    }
}
