
import {of as observableOf, Observable} from 'rxjs';
import {LogEntry} from '@shared-models/common.model';
import {LogPublisher} from '@app/shared/publishers/log-publisher';
import {Injector} from '@angular/core';

export class LogLocalStorage extends LogPublisher {
    constructor(public injector: Injector) {
        // Must call super() from derived classes
        super(injector);
        // Set location
    }

    // Append log entry to local storage
    log(entry: LogEntry): Observable<any> {
        let values: LogEntry[];

        try {
            // Get previous values from local storage
            values = JSON.parse(
                localStorage.getItem(this.logPublisherConfig.loggerLocation))
                || [];
            // Add new log entry to array
            if(values.length == 50) {
                values = values.splice(0, 1);
            }
            values.push(entry);
            // Store array into local storage
            localStorage.setItem(this.logPublisherConfig.loggerLocation,
                JSON.stringify(values));

            // Set return value
        } catch (ex) {
            // Display error in console
            console.log(ex);
        }

        return observableOf(entry);
    }

    // Clear all log entries from local storage
    clear(): Observable<boolean> {
        localStorage.removeItem(this.logPublisherConfig.loggerLocation);
        return observableOf(true);
    }
}
