import { PopupType } from '@app/shared/components/popup/popup.model';
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { StorageService } from '@shared-services/storage.service';
import { HttpService } from '@shared-services/http.service';
import { Router } from '@angular/router';
import { RoutePaths } from '@shared-constants/route-paths';
import { LogService } from '@shared-services/log.service';
import { LogLevel } from '@shared-models/common.model';
var PopupComponent = /** @class */ (function () {
    function PopupComponent(storageService, httpService, router, routePaths, logger) {
        this.storageService = storageService;
        this.httpService = httpService;
        this.router = router;
        this.routePaths = routePaths;
        this.logger = logger;
        this.modalOptions = {
            'heading': '', 'message': ' ', 'type': PopupType.ERROR, 'isVisible': false
        };
        this.traceId = this.logger.genTraceId();
    }
    PopupComponent.prototype.ngOnInit = function () {
        this.logger.postEntry(LogLevel.Info, 'Initializing Popup Component', 'Popup.ngOnInit', this.traceId, 100);
    };
    PopupComponent.prototype.ngAfterViewInit = function () {
        this.logger.postEntry(LogLevel.Info, 'Popup Component initialized', 'Popup.ngAfterViewInit', this.traceId, 100);
    };
    PopupComponent.prototype.ngOnDestroy = function () {
        this.logger.postEntry(LogLevel.Info, 'Destroying Popup Component', 'Popup.ngOnInit', this.traceId, 100);
    };
    Object.defineProperty(PopupComponent.prototype, "modal", {
        get: function () {
            this.logger.postEntry(LogLevel.Info, 'Getting modal options', 'Popup.get modal', this.traceId, 100);
            var options = this.modalOptions;
            this.logger.postEntry(LogLevel.Info, 'Current modal options: ' + JSON.stringify(options), 'Popup.get modal', this.traceId, 100);
            return options;
        },
        set: function (value) {
            this.logger.postEntry(LogLevel.Info, 'Setting modal options to: ' + JSON.stringify(value), 'Popup.set modal', this.traceId, 100);
            this.modalOptions = value;
            this.setLogo(this.modal);
        },
        enumerable: true,
        configurable: true
    });
    PopupComponent.prototype.setLogo = function (modal) {
        this.logger.postEntry(LogLevel.Info, 'Setting modal logo to: ' + JSON.stringify(modal.type), 'Popup.setLogo', this.traceId, 100);
        this.logo = PopupType.popupIcon(modal.type);
    };
    PopupComponent.prototype.closeModal = function () {
        this.logger.postEntry(LogLevel.Info, 'Closing modal', 'Popup.closeModal', this.traceId, 100);
        if (this.modalOptions.btnName == 'Refresh') {
            window.location.reload();
        }
        if (this.storageService.isSessionExpired) {
            this.storageService.isSessionExpired = false;
            this.router.navigate([this.routePaths.LOGIN]);
        }
        this.modalOptions.isVisible = false;
    };
    return PopupComponent;
}());
export { PopupComponent };
