import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageService} from '@app/shared/services/local-storage.service';
import {LogService} from '@shared-services/log.service';
import {LogLevel} from '@shared-models/common.model';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

    type: string;
    mutedText: string;
    pText: string;
    private traceId: string;

    constructor(private localStorage: LocalStorageService, private route: ActivatedRoute,
                private router: Router, private logger: LogService) {
        this.traceId = this.logger.genTraceId();
    }

    ngOnInit() {
        this.logger.postEntry(
            LogLevel.Info,
            'Initializing Page Not Found Component',
            'SendOTP.ngOnInit',
            this.traceId,
            100
        );
        this.localStorage.resetLocalStorage();
        this.type = this.route.snapshot.data['type'];
        this.mutedText = this.route.snapshot.data['mutedText'];
        this.pText = this.route.snapshot.data['pText'];
    }

}
