import {Component, Input, OnInit} from '@angular/core';
import {LogService} from '@shared-services/log.service';
import {LogLevel} from '@shared-models/common.model';

@Component({
    selector: 'app-title',
    templateUrl: './title.component.html',
    styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {

    @Input() title: string;
    @Input() subtitle: string;

    private traceId: string;

    constructor(private logger: LogService) {
        this.traceId = this.logger.genTraceId();
    }

    ngOnInit() {
        this.logger.postEntry(
            LogLevel.Info,
            'Initializing Title Component',
            'Popup.ngOnInit',
            this.traceId,
            100
        );
    }

}
