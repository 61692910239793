import { LocalStorageService } from './local-storage.service';
import { RequestUrls } from '@shared-constants/request-urls';
import { LogLevel } from '@shared-models/common.model';
import { HttpService } from '@shared-services/http.service';
import { StorageService } from '@shared-services/storage.service';
import { LogService } from '@shared-services/log.service';
var AuthService = /** @class */ (function () {
    function AuthService(http, storageService, localStorage, logger) {
        this.http = http;
        this.storageService = storageService;
        this.localStorage = localStorage;
        this.logger = logger;
        this.traceId = this.logger.genTraceId();
    }
    Object.defineProperty(AuthService.prototype, "patientDetails", {
        get: function () {
            return this.localStorage.getLocalStorage('patientDetails');
        },
        set: function (pDetails) {
            this.localStorage.setItemInLocalStorage('patientDetails', pDetails);
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.login = function (loginData) {
        this.logger.postEntry(LogLevel.Info, 'Posting login request to :' + RequestUrls.ANONYMOUS.LOGIN, 'AuthService.login', this.traceId, 100);
        return this.http.post(RequestUrls.ANONYMOUS.LOGIN, loginData);
    };
    AuthService.prototype.verifyOTP = function (verificationCode, currentDateTime) {
        this.logger.postEntry(LogLevel.Info, 'Posting 2FA verification request to :' + RequestUrls.AUTHENTICATED.VERIFYOTP, 'AuthService.verifyOTP', this.traceId, 100);
        return this.http.post(RequestUrls.AUTHENTICATED.VERIFYOTP, { code: verificationCode.code, currentDateTime: currentDateTime });
    };
    AuthService.prototype.sendOTP = function (requestOTPData) {
        this.logger.postEntry(LogLevel.Info, 'Posting 2FA send request to :' + RequestUrls.AUTHENTICATED.SEND_OTP, 'AuthService.sendOTP', this.traceId, 100);
        return this.http.post(RequestUrls.AUTHENTICATED.SEND_OTP, requestOTPData);
    };
    AuthService.prototype.updateOTPReceiveTime = function (time) {
        this.logger.postEntry(LogLevel.Info, 'Updating OTP receiving time to :' + RequestUrls.AUTHENTICATED.UPDATE_OTP_RECEIVE_TIME, 'AuthService.updateOTPTime', this.traceId, 100);
        return this.http.post(RequestUrls.AUTHENTICATED.UPDATE_OTP_RECEIVE_TIME, time);
    };
    AuthService.prototype.getAppointmentDetails = function (patientDetails) {
        this.logger.postEntry(LogLevel.Info, 'Posting appointment details request to :' + RequestUrls.AUTHENTICATED.GET_APPOINTMENT_DETAILS, 'AuthService.getAppointmentDetails', this.traceId, 100);
        return this.http.post(RequestUrls.AUTHENTICATED.GET_APPOINTMENT_DETAILS, patientDetails);
    };
    AuthService.prototype.getPatientBalance = function (patientId) {
        return this.http.post(RequestUrls.AUTHENTICATED.GET_PATIENT_BALANCE, {
            patientId: patientId,
            suffix: this.storageService.urlSuffix,
        });
    };
    AuthService.prototype.getAccountFeatures = function () {
        return this.http.post(RequestUrls.AUTHENTICATED.GET_ACCOUNT_FEATURES, {
            suffix: this.storageService.urlSuffix,
        });
    };
    AuthService.prototype.getDraftInfo = function (apptId) {
        return this.http.post(RequestUrls.AUTHENTICATED.GET_DRAFT_INFO, {
            patientId: this.storageService.patientId,
            apptId: apptId,
            suffix: this.storageService.urlSuffix
        });
    };
    AuthService.prototype.verifyOTPWithoutDevice = function (code) {
        this.logger.postEntry(LogLevel.Info, 'Posting deviceless 2FA verification request to :' + RequestUrls.AUTHENTICATED.VAERIRY_OTP_WIRHOUT_DEVICE, 'AuthService.getAppointmentDetails', this.traceId, 100);
        return this.http.post(RequestUrls.AUTHENTICATED.VAERIRY_OTP_WIRHOUT_DEVICE, {
            suffix: this.storageService.urlSuffix,
            authCode: code,
            patientId: this.storageService.patientId
        });
    };
    AuthService.prototype.verifyPhonenumber = function (hash) {
        return this.http.post(RequestUrls.AUTHENTICATED.GET_PHONE_LIST, {
            suffix: this.storageService.urlSuffix,
            hash: hash,
        });
    };
    return AuthService;
}());
export { AuthService };
