<div class="info-popup active" *ngIf="modalOptions.isVisible">
    <div class="container">
        <div class="info-popup-wrapper">
            <i class="popup-icon" [style.background-image]='logo'>Icon</i>
            <h4 [innerHtml]="modalOptions.heading"></h4>
            <p>{{modalOptions.message}}</p>
            <button #btnModal type="button" autofocus tabindex="-1" class="btn btn-primary" (click)="closeModal()">{{
                modalOptions.btnName ? modalOptions.btnName : 'OK' }}{{ btnModal.focus()}}
            </button>
        </div>
    </div>
</div>
