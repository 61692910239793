import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {forkJoin, Observable, Subscription} from 'rxjs';
import * as moment from 'moment';

import {StorageService} from '@shared-services/storage.service';
import {AuthService} from '@shared-services/auth.service';
import {CommonService} from '@shared-services/common.service';
import {LoaderType} from '@shared-directives/loader/loader.model';
import {PopupType} from '@shared-components/popup/popup.model';
import {RoutePaths} from '@shared-constants/route-paths';
import {PopupService} from '@shared-components/popup/popup.service';
import {ERROR} from '@shared-constants/error-messages';
import {LogService} from '@shared-services/log.service';
import {LogLevel} from '@shared-models/common.model';
import { environment } from '@environments/environment';


// import { MatomoTracker } from 'ngx-matomo';

@Component({
    selector: 'app-appointment-details',
    templateUrl: './appointment-details.component.html',
    styleUrls: ['./appointment-details.component.scss']
})
export class AppointmentDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

    logo: string;
    title = 'Appointment Details';
    buttonText: string;
    retainLoader = true;
    AppointmentSubscription: Subscription;
    BalanceSubscription: Subscription;
    AccountInfoSubscription: Subscription;
    checkinSubscription: Subscription;
    draftSubscription: Subscription;
    private traceId: string;
    public loaderTypeEnum = LoaderType;
    AppointmentDetails: any;
    logData= {};
    finalog = {};
    arrayDate = {};
    logText = 'Application Log Appointment Page';
    pageDemographics = 'Demographics';
    pagePrimaryInsurance = 'PrimaryInsurance';
    pageSecondaryInsurance = 'SecondaryInsurance';
    pageDriversLicense = 'DriversLicense';

    constructor(private storageService: StorageService, private router: Router,
                private authService: AuthService, private commonService: CommonService,
                private cdRef: ChangeDetectorRef, private routePaths: RoutePaths,
                private popupService: PopupService,
                private logger: LogService
                ) {
        this.traceId = this.logger.genTraceId();

        const [{Email, PatientID}] = this.authService.patientDetails;

        const {patientDetails} = this.storageService.patientData;

        let firstName = '';
        let lastName = '';

        if (patientDetails) {
            firstName = patientDetails.FirstName;
            lastName = patientDetails.LastName;
        }
  
        if (PatientID && (firstName || lastName) && environment.enableCoralogixLogging) {
            this.logger.coralogixSetUserContext(PatientID, firstName, lastName, Email);
        }
    }

    ngOnInit() {
        this.logger.postEntry(
            LogLevel.Info,
            'Initializing Appointment Details Component',
            'AppointmentDetails.ngOnInit',
            this.traceId,
            100
        );
        this.logo = `url(${this.storageService.logo})`;
        this.getAppointmentInfo();
        this.getBalanceInfo();
        this.getAccountInfo();
    }

    ngAfterViewInit() {
        this.logger.postEntry(
            LogLevel.Info,
            'Appointment Details Component initialized',
            'AppointmentDetails.ngAfterViewInit',
            this.traceId,
            100
        );
    }

    getAccountInfo() {
        this.logger.postEntry(
            LogLevel.Info,
            'Getting balance information',
            'AppointmentDetails.getAccountInfo',
            this.traceId,
            100
        );
        this.AccountInfoSubscription = this.authService.getAccountFeatures().subscribe((response) => {
            const enabledFeatures = response.data.enabledFeatures;
            const enabledCapabilities = response.data.enabledCapabilities;
            this.storageService.mdpayEnabled = enabledFeatures.includes('mdpay') && enabledCapabilities.includes('eregmdpayhandover');
        }, () => {
            this.logger.postEntry(
                LogLevel.Warning,
                'Failed to fetch account MDPay status, Suppressing MDPay features',
                'AppointmentDetails.getBalanceInfo',
                this.traceId,
                100
            );
            this.storageService.mdpayEnabled = false;
        });
    }

    getDraftInfo(apptId) {

        this.draftSubscription = this.authService.getDraftInfo(apptId).subscribe((response) => {
            let draftPage = response.data;
            this.storageService.draftPage = draftPage;
        });
    }

    getBalanceInfo() {
        this.logger.postEntry(
            LogLevel.Info,
            'Getting balance information',
            'AppointmentDetails.getBalanceInfo',
            this.traceId,
            100
        );
        this.BalanceSubscription = this.authService.getPatientBalance(this.storageService.patientId).subscribe((response) => {
            const {balance, copay} = response.data;
            this.storageService.patientBalance = Math.max(balance, 0.0) + Math.max(copay, 0.0);
        }, () => {
            this.logger.postEntry(
                LogLevel.Warning,
                'Failed to fetch patient balance info. Suppressing MDPay features',
                'AppointmentDetails.getBalanceInfo',
                this.traceId,
                100
            );
            this.storageService.patientBalance = 0.0;
        });
    }

    getAppointmentInfo() {
        this.logger.postEntry(
            LogLevel.Info,
            'Getting appointment information',
            'AppointmentDetails.getAppointmentInfo',
            this.traceId,
            100
        );
        const appointmentDetails : {
            patientId: string;
            suffix: string;
            appointmentId?: string;
            locationId?: string
        } = {
            'patientId': this.storageService.patientId,
            'suffix': this.storageService.urlSuffix
        };
        if (this.storageService.appointmentId) {
            appointmentDetails.appointmentId = this.storageService.appointmentId;
        }
        if (this.storageService.locationId) {
            appointmentDetails.locationId = this.storageService.locationId;
        }
        this.AppointmentSubscription = this.authService.getAppointmentDetails(appointmentDetails).subscribe((response) => {
            const apptDetails = response.data;
            if (apptDetails.appointmentTZ === 'Z' || apptDetails.appointmentTZ === 'UTC') {
                const apptDTLocal = moment(apptDetails.appointmentTS);
                apptDetails.appointmentDate = apptDTLocal.format('MM/DD/YYYY');
                apptDetails.appointmentTime = apptDTLocal.format('h:mm A');
                apptDetails.appointmentTZ = moment.tz(moment.tz.guess()).zoneAbbr();
            }
            this.AppointmentDetails = apptDetails;
            /*this.logger.postEntry( PHI
                LogLevel.Info,
                'Appointment Information: ' + JSON.stringify(response.data),
                'AppointmentDetails.getAppointmentInfo',
                this.traceId,
                100
            );*/
            this.storageService.appointmentId = this.AppointmentDetails.apptId;
            this.storageService.formId = this.AppointmentDetails.formId;
            
            //if (!this.storageService.authToken) {
                console.log("===========Storage services=============");
                this.storageService.authToken = this.AppointmentDetails.authToken;
                console.log(this.storageService);
                console.log("===========Storage services Ends=============");
                //this.storageService.storeAuthTokenInLocalStorage();
            //}
           // this.storageService.storeAuthTokenInLocalStorage();
            this.getDraftInfo(this.AppointmentDetails.apptId);
            this.buttonText = this.commonService.templateTypes.filter(
                template => template.TemplateTypeID === this.storageService.templateTypeID)[0].ButtonText;
        }, (err) => this.handleError(err));
        
    }

    checkin() {
        const checkinObservable = new Observable((observer) => {
            observer.next();
        });
        this.retainLoader = true;
        this.checkinSubscription = checkinObservable.subscribe(() => {
            this.retainLoader = false;
            this.cdRef.detectChanges();
            this.storageService.isPatientCheckedIn = true;
            if (!this.storageService.authToken) {
                this.storageService.storeAuthTokenInLocalStorage();
            }
            //
            this.arrayDate[this.buttonText] = 'Clicked';
            this.prepareLog(this.logText, this.arrayDate);

            const draftPage = this.storageService.draftPage;
            if (draftPage.DraftPage == "" || draftPage.isFormUpdated) {
                this.storageService.isDemographicsSubmitted = false;
                this.storageService.isPrimaryInsuranceSubmitted = false;
                this.storageService.isSecondaryInsuranceSubmitted = false;
                this.storageService.isDriverLicenseSubmitted = false;
                this.router.navigate([this.routePaths.DEMOGRAPHICS]);
                return false;
            }
              
            
            if (draftPage.DraftPage == this.pageDemographics) {
                this.storageService.isDemographicsSubmitted = true; 
                this.router.navigate([this.routePaths.PRIMARY_INSURANCE]);
            }
            else if (draftPage.DraftPage == this.pagePrimaryInsurance) {
                this.storageService.isDemographicsSubmitted = true;
                this.storageService.isPrimaryInsuranceSubmitted = true;
                this.router.navigate([this.routePaths.SECONDARY_INSURANCE]);
            }
            else if (draftPage.DraftPage == this.pageSecondaryInsurance) {
                this.storageService.isDemographicsSubmitted = true;
                this.storageService.isPrimaryInsuranceSubmitted = true;
                this.storageService.isSecondaryInsuranceSubmitted = true;
                this.router.navigate([this.routePaths.DRIVER_LICENSE]);                
            }
            else if (draftPage.DraftPage == this.pageDriversLicense) {
                this.storageService.isDemographicsSubmitted = true;
                this.storageService.isPrimaryInsuranceSubmitted = true;
                this.storageService.isSecondaryInsuranceSubmitted = true;
                this.storageService.isDriverLicenseSubmitted = true;
                this.router.navigate([this.routePaths.AGREEMENT_CONSENT]);    
            }
            else{
                this.router.navigate([this.routePaths.DEMOGRAPHICS]);
            }
            
            return false;
        });
    }

    private handleError(err) {
        this.logger.postEntry(
            LogLevel.Error,
            err.error.message,
            'AppointmentDetails.handleError',
            this.traceId,
            err.status
        );
        this.popupService.showModal(ERROR, err.error.message, PopupType.ERROR, true);
    }

    ngOnDestroy() {
        this.logger.postEntry(
            LogLevel.Info,
            'Destroying Appointment Details Component',
            'AppointmentDetails.ngOnDestroy',
            this.traceId,
            100
        );
        this.AppointmentSubscription.unsubscribe();
        this.retainLoader = false;
    }

    private prepareLog(formName, data) {
        var formvalue = formName
        if (data) {
            //console.log(this.logData);
            this.finalog[formvalue] = JSON.stringify(data);
            this.finalog['patientId'] = this.storageService.patientId;
            //this.finalog['appointmentId'] = this.storageService.appointmentId;   
        }
        var textinfo = 'Getting log for Patient Id ' + this.storageService.patientId + ' Appointment Page:';
        this.logger.trackFormIoEvents(textinfo, JSON.stringify(this.finalog));
    }
}
