/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./inputs/phone/bootstrap-input-phone.component.ngfactory";
import * as i2 from "./inputs/phone/bootstrap-input-phone.component";
import * as i3 from "./form-validation.service";
import * as i4 from "./inputs/text/bootstrap-input-text.component.ngfactory";
import * as i5 from "./inputs/text/bootstrap-input-text.component";
import * as i6 from "./inputs/email/bootstrap-input-email.component.ngfactory";
import * as i7 from "./inputs/email/bootstrap-input-email.component";
import * as i8 from "./inputs/select/bootstrap-select.component.ngfactory";
import * as i9 from "./inputs/select/bootstrap-select.component";
import * as i10 from "@angular/common";
import * as i11 from "./bootstrap-input.component";
var styles_BootstrapInputComponent = [];
var RenderType_BootstrapInputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BootstrapInputComponent, data: {} });
export { RenderType_BootstrapInputComponent as RenderType_BootstrapInputComponent };
function View_BootstrapInputComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-bootstrap-input-phone", [], null, null, null, i1.View_BootstrapInputPhoneComponent_0, i1.RenderType_BootstrapInputPhoneComponent)), i0.ɵdid(1, 114688, null, 0, i2.BootstrapInputPhoneComponent, [i3.FormValidationService], { inputConfig: [0, "inputConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputConfig; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BootstrapInputComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-bootstrap-input-text", [], null, null, null, i4.View_BootstrapInputTextComponent_0, i4.RenderType_BootstrapInputTextComponent)), i0.ɵdid(1, 114688, null, 0, i5.BootstrapInputTextComponent, [i3.FormValidationService], { inputConfig: [0, "inputConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputConfig; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BootstrapInputComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-bootstrap-input-email", [], null, null, null, i6.View_BootstrapInputEmailComponent_0, i6.RenderType_BootstrapInputEmailComponent)), i0.ɵdid(1, 114688, null, 0, i7.BootstrapInputEmailComponent, [i3.FormValidationService], { inputConfig: [0, "inputConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputConfig; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BootstrapInputComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-bootstrap-select", [], null, null, null, i8.View_BootstrapInputSelectComponent_0, i8.RenderType_BootstrapInputSelectComponent)), i0.ɵdid(1, 114688, null, 0, i9.BootstrapInputSelectComponent, [i3.FormValidationService], { inputConfig: [0, "inputConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputConfig; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BootstrapInputComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_BootstrapInputComponent_1)), i0.ɵdid(1, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BootstrapInputComponent_2)), i0.ɵdid(3, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BootstrapInputComponent_3)), i0.ɵdid(5, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BootstrapInputComponent_4)), i0.ɵdid(7, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isType("phone"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isType("text"); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.isType("email"); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.isType("select"); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_BootstrapInputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-bootstrap-input", [], null, null, null, View_BootstrapInputComponent_0, RenderType_BootstrapInputComponent)), i0.ɵdid(1, 114688, null, 0, i11.BootstrapInputComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BootstrapInputComponentNgFactory = i0.ɵccf("app-bootstrap-input", i11.BootstrapInputComponent, View_BootstrapInputComponent_Host_0, { inputConfig: "inputConfig" }, {}, []);
export { BootstrapInputComponentNgFactory as BootstrapInputComponentNgFactory };
