import { LogLevel } from './../models/common.model';
import { RequestUrls } from '@app/shared/constants/request-urls';
import { HttpService } from '@app/shared/services/http.service';
import { StorageService } from '@app/shared/services/storage.service';
import { LogService } from "@shared-services/log.service";
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
import * as i2 from "./storage.service";
import * as i3 from "./log.service";
var CommonService = /** @class */ (function () {
    function CommonService(http, storageService, logger) {
        this.http = http;
        this.storageService = storageService;
        this.logger = logger;
        this.traceId = this.logger.genTraceId();
    }
    Object.defineProperty(CommonService.prototype, "gender", {
        get: function () {
            this.logger.postEntry(LogLevel.Info, 'Getting gender from app configuration.', 'CommonService.get gender', this.traceId, 100);
            return this.appConfig.Gender;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonService.prototype, "states", {
        get: function () {
            this.logger.postEntry(LogLevel.Info, 'Getting states from app configuration.', 'CommonService.get states', this.traceId, 100);
            return this.appConfig.States;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonService.prototype, "relationships", {
        get: function () {
            this.logger.postEntry(LogLevel.Info, 'Getting relationships from app configuration.', 'CommonService.get relationships', this.traceId, 100);
            return this.appConfig.Relationship;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonService.prototype, "templateTypes", {
        get: function () {
            this.logger.postEntry(LogLevel.Info, 'Getting states from app configuration.', 'CommonService.get templateTypes', this.traceId, 100);
            return this.appConfig.TemplateTypes;
        },
        enumerable: true,
        configurable: true
    });
    CommonService.prototype.getAccountInfoDetails = function (previewLinkData) {
        this.logger.postEntry(LogLevel.Info, 'Posting account info detail request to: ' + RequestUrls.ANONYMOUS.GET_ACCOUNT_INFO_DETAILS, 'CommonService.getAccountInfoDetails', this.traceId, 100);
        return this.http.post(RequestUrls.ANONYMOUS.GET_ACCOUNT_INFO_DETAILS, previewLinkData);
    };
    CommonService.prototype.getAppConfig = function () {
        this.logger.postEntry(LogLevel.Info, 'Posting account info detail request to: ' + RequestUrls.ANONYMOUS.GET_ACCOUNT_INFO_DETAILS, 'CommonService.getAppConfig', this.traceId, 100);
        return this.http.get(RequestUrls.ANONYMOUS.APP_CONFIG);
    };
    CommonService.prototype.isFileGreaterThanSize = function (file, size) {
        this.logger.postEntry(LogLevel.Info, 'Checking file size', 'CommonService.isFileGreaterThanSize', this.traceId, 100);
        if (file.size > size) {
            this.logger.postEntry(LogLevel.Warning, 'File size ' + JSON.stringify(file.size) + ' exceeds maximum size of ' + JSON.stringify(size), 'CommonService.isFileGreaterThanSize', this.traceId, 100);
            return true;
        }
        this.logger.postEntry(LogLevel.Info, 'File size within parameters', 'CommonService.isFileGreaterThanSize', this.traceId, 100);
        return false;
    };
    CommonService.prototype.getTemplateSettings = function () {
        this.logger.postEntry(LogLevel.Info, 'Getting template settings', 'CommonService.getTemplateSettings', this.traceId, 100);
        var reqData = {
            suffix: this.storageService.urlSuffix,
            patientId: this.storageService.patientId
        };
        if (this.storageService.locationId !== undefined) {
            reqData.locationId = this.storageService.locationId;
        }
        this.logger.postEntry(LogLevel.Info, 'Template request data: ' + JSON.stringify(reqData), 'CommonService.getAppointmentDetails', this.traceId, 100);
        this.logger.postEntry(LogLevel.Info, 'Posting template request to: ' + RequestUrls.AUTHENTICATED.GET_TEMPLATE_SETTINGS, 'CommonService.getAppointmentDetails', this.traceId, 100);
        return this.http.post(RequestUrls.AUTHENTICATED.GET_TEMPLATE_SETTINGS, reqData);
    };
    CommonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommonService_Factory() { return new CommonService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.StorageService), i0.ɵɵinject(i3.LogService)); }, token: CommonService, providedIn: "root" });
    return CommonService;
}());
export { CommonService };
