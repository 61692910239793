import {Directive, ElementRef, Input, OnDestroy} from '@angular/core';

import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';

@Directive({
    selector: '[appMaskDate]'
})

export class MaskDateDirective implements OnDestroy {

    @Input()
    dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

    maskedInputController;

    constructor(private element: ElementRef) {
        this.maskedInputController = textMask.maskInput({
            inputElement: this.element.nativeElement,
            mask: this.dateMask,
            guide: false,
            modelClean: true
        });
    }

    ngOnDestroy() {
        this.maskedInputController.destroy();
    }
}
