import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';

import {BootstrapInputConfigInterface} from '@shared-modules/ng-bootstrap-input/bootstrap-input-config';
import {BootstrapFormGroup} from '@shared-modules/ng-bootstrap-input/bootstrap-form-group';
import {StorageService} from '@shared-services/storage.service';
import {LoaderType} from '@app/shared/directives/loader/loader.model';
import {Error} from '@shared-constants/error-messages';
import flatpickr from 'flatpickr';
// import { MatomoTracker } from 'ngx-matomo';


import * as moment from 'moment';
import {LogService} from '@shared-services/log.service';
import {LogLevel} from '@shared-models/common.model';

@Component({
    selector: 'app-patient',
    templateUrl: './patientID-login.component.html',
    styleUrls: ['./patientID-login.component.scss']
})
export class PatientIDLoginComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('dobPicker', {static: false}) dobPicker: ElementRef;
    // @ViewChild('dobPickerIcon') dobPickerIcon: ElementRef;

    readonly errors = Error;
    maxDate = new Date();
    minDate = new Date('01/01/1900');
    isDatePickerOpen = false;
    retainLoader = true;
    prevSelectedDate: string;
    private traceId: string;
    patientIDLoginFg: BootstrapFormGroup = <BootstrapFormGroup>this.loginFb.group({});
    patientIDConfig: BootstrapInputConfigInterface;
    loaderTypeEnum = LoaderType;

    onFocus = false;
    checkIcon = false;
    dateErrorPresent = false;
    tempDate: Date;
    dateValid = false;
    date: Date;

    get dobFormControl(): FormControl {
        return this.patientIDLoginFg.get('DOB') as FormControl;
    }

    constructor(public loginFb: FormBuilder,
                public storageService: StorageService,
                private logger: LogService) {
        this.traceId = this.logger.genTraceId();
    }

    ngOnInit() {
        this.logger.postEntry(
            LogLevel.Info,
            'Initializing Patient ID Login Component',
            'PatientIDLogin.ngOnInit',
            this.traceId,
            100
        );
        this.setupFormControls();


    }

    ngAfterViewInit() {
        this.logger.postEntry(
            LogLevel.Info,
            'Patient ID Login Component initialized',
            'PatientIDLogin.ngAfterViewInit',
            this.traceId,
            100
        );
    }

    ngOnDestroy() {
        this.logger.postEntry(
            LogLevel.Info,
            'Destroying Patient ID Login Component',
            'PatientIDLogin.ngOnDestroy',
            this.traceId,
            100
        );
    }

    isFormValid() {
        this.logger.postEntry(
            LogLevel.Info,
            'Checking if Patient ID Login form is valid',
            'PatientIDLogin.isFormValid',
            this.traceId,
            100
        );
        return this.patientIDLoginFg.valid;
    }

    setupFormControls() {
        this.logger.postEntry(
            LogLevel.Info,
            'Setting up Patient ID Login form controls',
            'PatientIDLogin.setupFormControls',
            this.traceId,
            100
        );
        this.patientIDLoginFg = <BootstrapFormGroup>this.loginFb.group({
            DOB: this.loginFb.control('', Validators.required)
        });
        this.logger.postEntry(
            LogLevel.Info,
            'Configuring PatientId',
            'PatientIDLogin.setupFormControls',
            this.traceId,
            100
        );
        this.patientIDConfig = {
            type: 'text',
            name: 'PatientId',
            form: this.patientIDLoginFg,
            label: 'PATIENT NUMBER',
            validationConfig: {
                required: {
                    value: true,
                    message: this.errors.LOGIN.PATIENT_ID.REQUIRED
                },
                maxLength: {
                    value: 32
                },
                validationRegex: {
                    value: /^[0-9a-zA-Z./-]*$/,

                    message: this.errors.LOGIN.PATIENT_ID.INVALID
                }
            },
        };
    }

    openDatePicker(event) {
        this.logger.postEntry(
            LogLevel.Info,
            'Attempting to open date picker',
            'PatientIDLogin.openDatePicker',
            this.traceId,
            100
        );
        this.dobPicker.nativeElement.nextSibling.focus();
        this.dobPicker.nativeElement.nextSibling.click();
        if (!this.isDatePickerOpen) {
            this.dobPicker.nativeElement.focus();
            this.dobPicker.nativeElement.click();
            this.dobPicker.nativeElement._flatpickr.open();
            event.stopPropagation();
            return false;
        }
        this.logger.postEntry(
            LogLevel.Info,
            'Date picker already open',
            'PatientIDLogin.openDatePicker',
            this.traceId,
            100
        );

    }

    onOpenDatePicker() {
        this.logger.postEntry(
            LogLevel.Info,
            'Date picker opened',
            'PatientIDLogin.onOpenDatePicker',
            this.traceId,
            100
        );
        setTimeout(() => {
            this.prevSelectedDate = this.dobPicker.nativeElement._flatpickr.selectedDates[0];
        this.isDatePickerOpen = true;

        }, 200);

    }



    onCloseDatePicker() {
        this.logger.postEntry(
            LogLevel.Info,
            'Closing date picker',
            'PatientIDLogin.onCloseDatePicker',
            this.traceId,
            100
        );
        this.dobFormControl.markAsTouched();
        this.dobPicker.nativeElement.blur();

        const calSelectedDate = this.dobPicker.nativeElement._flatpickr.selectedDates[0];

        if (this.dobPicker.nativeElement.value ) {
            const dateMoment = moment(this.dobPicker.nativeElement.value, 'MM/DD/YYYY', true);
            const date = dateMoment.toDate();
            this.date = date;
            if (dateMoment.isValid() && (dateMoment.year() >= 1900) && (date.getTime() <= this.maxDate.getTime())) {
                this.dobPicker.nativeElement._flatpickr.setDate(this.dobPicker.nativeElement.value);
                this.checkIcon = true;
                this.onFocus = false;
                this.dateErrorPresent = false;
            } else {
                this.checkIcon = false;
                this.dateErrorPresent = true;
                this.dobPicker.nativeElement._flatpickr.clear();
            }
        } else {
            this.checkIcon = false;
                this.dateErrorPresent = true;
                this.dobPicker.nativeElement._flatpickr.clear();
        }
        setTimeout(() => {
            this.isDatePickerOpen = false;
        }, 200);
        this.logger.postEntry(
            LogLevel.Info,
            'Date picker closed',
            'PatientIDLogin.onCloseDatePicker',
            this.traceId,
            100
        );
    }

    inputValueUpdated() {
        this.prevSelectedDate = this.dobPicker.nativeElement._flatpickr.selectedDates[0];
    }

    getFormValue() {
        const loginFormVal = this.patientIDLoginFg.value;
        loginFormVal.DOB = this.dobPicker.nativeElement.value;
        loginFormVal.suffix = this.storageService.urlSuffix;
        loginFormVal.LoginType = 'PatientId';
        return loginFormVal;
    }

    patientid(event) {
     if (event.target.value.length > 31) {
         return false;
     } else {
         return true;
     }
    }
    focus() {
        // this.dobFormControl.untouched
        this.onFocus = true;
        this.checkIcon = false;
        // this.dateErrorPresent=false
        this.dobFormControl.markAsUntouched();
        this.dobFormControl.markAsPristine();
        // console.log(this.onFocus)
        // this.checkIcon=false
        // alert("asd")
    }

    blur() {

        this.onFocus = false;
        const tempDob = new Date(this.dobPicker.nativeElement.value);

        // console.log("blur",this.dobFormControl.value)
        // console.log(tempDob.getTime(),"blur2")
        if ((this.maxDate.getTime() >= tempDob.getTime())) {

          this.checkIcon = true;
          this.dateErrorPresent = false;
        //   console.log(this.checkIcon)

        } else {
            // this.dobFormControl.reset()
            // this.dobFormControl.setErrors({'required':true})
            // this.dobFormControl.markAsTouched()
            this.dobFormControl.setValue('');
            this.checkIcon = false;
            this.dateErrorPresent = true;
            // console.log(this.checkIcon)
            // console.log(this.dobFormControl.value)
        }
    }

    valueChanges() {


    }
}
