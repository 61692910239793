import {PopupOptions, PopupType} from '@app/shared/components/popup/popup.model';
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {StorageService} from '@shared-services/storage.service';
import {HttpService} from '@shared-services/http.service';
import {Router} from '@angular/router';
import {RoutePaths} from '@shared-constants/route-paths';
import {LogService} from '@shared-services/log.service';
import {LogLevel} from '@shared-models/common.model';

@Component({
    selector: 'app-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit, AfterViewInit, OnDestroy {

    modalOptions: PopupOptions = {
        'heading': '', 'message': ' ', 'type': PopupType.ERROR, 'isVisible': false
    };
    logo: string;
    private traceId: string;

    constructor(
        private storageService: StorageService,
        private httpService: HttpService,
        private router: Router,
        private routePaths: RoutePaths,
        private logger: LogService
    ) {
        this.traceId = this.logger.genTraceId();
    }

    ngOnInit() {
        this.logger.postEntry(
            LogLevel.Info,
            'Initializing Popup Component',
            'Popup.ngOnInit',
            this.traceId,
            100
        );
    }

     ngAfterViewInit() {
         this.logger.postEntry(
             LogLevel.Info,
             'Popup Component initialized',
             'Popup.ngAfterViewInit',
             this.traceId,
             100
         );
     }

     ngOnDestroy() {
         this.logger.postEntry(
             LogLevel.Info,
             'Destroying Popup Component',
             'Popup.ngOnInit',
             this.traceId,
             100
         );
     }

    set modal(value) {
        this.logger.postEntry(
            LogLevel.Info,
            'Setting modal options to: ' + JSON.stringify(value) ,
            'Popup.set modal',
            this.traceId,
            100
        );
        this.modalOptions = value;
        this.setLogo(this.modal);
    }

    setLogo(modal) {
        this.logger.postEntry(
            LogLevel.Info,
            'Setting modal logo to: ' + JSON.stringify(modal.type),
            'Popup.setLogo',
            this.traceId,
            100
        );
        this.logo = PopupType.popupIcon(modal.type);
    }

    get modal() {
        this.logger.postEntry(
            LogLevel.Info,
            'Getting modal options',
            'Popup.get modal',
            this.traceId,
            100
        );
        const options = this.modalOptions;
        this.logger.postEntry(
            LogLevel.Info,
            'Current modal options: ' + JSON.stringify(options),
            'Popup.get modal',
            this.traceId,
            100
        );
        return options;
    }

    closeModal() {
        this.logger.postEntry(
            LogLevel.Info,
            'Closing modal',
            'Popup.closeModal',
            this.traceId,
            100
        );

        if (this.modalOptions.btnName == 'Refresh') {
            window.location.reload();
        }
        if (this.storageService.isSessionExpired) {
            this.storageService.isSessionExpired = false;
            this.router.navigate([this.routePaths.LOGIN]);
        }
        this.modalOptions.isVisible = false;
    }
}
