import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AfterViewInit, Component, ChangeDetectorRef, OnDestroy, OnInit, NgZone} from '@angular/core';
import {Subscription} from 'rxjs';

import {AuthService} from '@shared-services/auth.service';
import {StorageService} from '@shared-services/storage.service';
import {PopupType} from '@shared-components/popup/popup.model';
import {LoaderType} from '@shared-directives/loader/loader.model';
import {RoutePaths} from '@shared-constants/route-paths';

import {PatientDetails} from '../../auth.model';
import {PopupService} from '@shared-components/popup/popup.service';
import {ERROR} from '@shared-constants/error-messages';
//import {LogService} from '@shared-services/log.service';
//import {LogLevel} from '@shared-models/common.model';
import { LogService } from '@app/shared/services/log.service';
// import { MatomoTracker } from 'ngx-matomo';

@Component({
    selector: 'app-phone-verification',
    templateUrl: './phone-verification.component.html',
    styleUrls: ['./phone-verification.component.scss']
})
export class PhoneVerificationComponent implements OnInit, AfterViewInit, OnDestroy {
    title = 'Identity Verification';
    phoneVerificationFg: FormGroup;
    phoneDetails= [];

    patientVerificationSubscription: Subscription;
    retainLoader = true;
    public loaderTypeEnum = LoaderType;
    private traceId: string;
    logData= {};
    finalog = {};
    arrayDate = {};
    attemptCount = 0;
    logText = 'Application Log Smart Link Verification';
    //MAX_ATTEMPT = 3;

    constructor(private storageService: StorageService, private phoneVerificationFb: FormBuilder, private authService: AuthService,
                private router: Router, private routePaths: RoutePaths, private popupService: PopupService,
               // private logger: LogService,
                private cdRef: ChangeDetectorRef,
                private NgZone: NgZone,
                private logger: LogService
                ) {
        //this.traceId = this.logger.genTraceId();

    }

    ngOnInit() {
        /*this.logger.postEntry(
            LogLevel.Info,
            'Initializing Send OTP Component',
            'SendOTP.ngOnInit',
            this.traceId,
            100
        );*/
        //this.patientDetails = this.authService.patientDetails;
        this.setupFormControls();
    }

    ngAfterViewInit() {
        /*this.logger.postEntry(
            LogLevel.Info,
            'Send OTP Component initialized',
            'SendOTP.ngAfterViewInit',
            this.traceId,
            100
        );*/
        this.cdRef.detectChanges();
    }

    ngOnDestroy() {
        /*this.logger.postEntry(
            LogLevel.Info,
            'Destroying Send OTP Component',
            'SendOTP.ngOnInit',
            this.traceId,
            100
        );*/
    }

    setupFormControls() {
        /*this.logger.postEntry(
            LogLevel.Info,
            'Setting up Send OTP form controls',
            'SendOTP.setupFormControls',
            this.traceId,
            100
        );*/
        this.phoneVerificationFg = this.phoneVerificationFb.group({
            destination: this.phoneVerificationFb.control('', Validators.required),
            patid: this.phoneVerificationFb.control(this.storageService.patientId),
        });
        this.cdRef.detectChanges();
        var phone = this.storageService.patientData.patientDetails['Patient'];
        this.patientVerificationSubscription = this.authService.verifyPhonenumber(phone).subscribe((response) => {
            this.retainLoader = true;
            //console.log(response);
            let phoneData = response.data;
            /*for(var i= 0 ; i < phoneData.length; i++) {
                this.phoneDetails[i] = phoneData[i];
            }*/
            var i= 0;
            for (var phone in phoneData) {
                this.phoneDetails[i] = phoneData[phone];
                i++;
            }

        }, (err) => this.handleError(err));    
      
    }

    verify() {
        /*this.logger.postEntry(
            LogLevel.Info,
            'Sending 2FA code',
            'SendOTP.sendOTP',
            this.traceId,
            100
        );*/
        //this.cdRef.detectChanges();

        var patientDetails = this.storageService.patientData;    
        var encryptedPhones = patientDetails.patientDetails['Patient'];
        var submittedPhones = btoa(this.phoneVerificationFg.value.destination);
        //TODO: MOve this to config file
        var error = 'Selected phone number does not match our records';

        if (encryptedPhones != submittedPhones) {
            this.popupService.showModal(ERROR, error, PopupType.ERROR, true);
            this.storageService.isReturnedFronPhoneVerification = true;
            this.router.navigate([this.routePaths.LOGIN], { queryParams: { token: this.storageService.patientToken } });
            console.log("Redirected");
        }
        else {
            this.storageService.isOTPVerified = true;
            this.router.navigate([this.routePaths.APPOINTMENT_DETAILS]);
            //console.log('navigated to ' + this.routePaths.DEMOGRAPHICS);
        }
        
    }


    private handleError(err) {
        /*this.logger.postEntry(
            LogLevel.Error,
            err.error.message,
            'SendOTP.sendOTP',
            this.traceId,
            err.status
        );*/
        this.popupService.showModal(ERROR, err.error.message, PopupType.ERROR, true);
        if (err.status === 404) {
            /*this.logger.postEntry(
                LogLevel.Info,
                'Navigating to ' + this.routePaths.LOGIN,
                'SendOTP.sendOTP',
                this.traceId,
                100
            );*/
            this.router.navigate([this.routePaths.LOGIN]);
        }

    }

    private prepareLog(formName, data) {
        var formvalue = formName
        if (data) {
            //console.log(this.logData);
            this.finalog[formvalue] = JSON.stringify(data);
            this.finalog['patientId'] = this.storageService.patientId;
            //this.finalog['appointmentId'] = this.storageService.appointmentId;   
        }
        var textinfo = 'Getting log for Patient Id ' + this.storageService.patientId + ' Smart Link Verification Page:';
        this.logger.trackFormIoEvents(textinfo, JSON.stringify(this.finalog));
    }
}
