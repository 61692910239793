<div [appLoader]="{promises: [patientVerificationSubscription], loaderType: loaderTypeEnum.FULL_PAGE}">
<div class="form-wrapper">
    <fieldset #disabledFieldset>
        <app-header [title]="title"></app-header>
        <div id="main">
            <div class="container">
                <p>Before proceeding, we need to verify your identity. Please select your correct phone number.</p>
                <form [formGroup]="phoneVerificationFg">
                    <div *ngFor="let phoneDetail of phoneDetails; let i = index"
                         class="form-group form-field-type-radio">
                        <div class="radio">
                            <label class="control-label" for="verification-method-sms{{i}}">
                                <input id="verification-method-sms{{i}}" [value]="phoneDetail"
                                       formControlName="destination"
                                       type="radio">
                                <span>{{phoneDetail | hidePhone}}</span>
                                <!-- XXX-XXX-4035 -->
                            </label>
                        </div>
                    </div>
                    <button type="submit" [disabled]="phoneVerificationFg.invalid" class="btn btn-primary btn-full-width"
                            (click)="verify()"
                            [appLoader]="{ loaderType: loaderTypeEnum.BUTTON, disabledFieldSet: disabledFieldset, retainLoader: retainLoader}">
                        Continue
                    </button>
                </form>

            </div>
        </div>
    </fieldset>
</div>
</div>

