export const Error = {
    IMAGE: {
        INVALID: 'Invalid file type. Valid file types are PNG and JPEG.',
    },
    DATE_OF_BIRTH: {
        REQUIRED: '* Date of Birth is required',
        FORMAT: '* Enter DOB in mm/dd/yyyy format e.g. 01/13/2001',
    },

    EXPIRATION_DATE: {
        REQUIRED: 'Expiration Date is required',
    },

    LOGIN: {
        FIRSTNAME: {
            REQUIRED: '* First Name is required',
            INVALID: '* First Name is invalid',
        },
        LASTNAME: {
            REQUIRED: '* Last Name is required',
            INVALID: '* Last Name is invalid',
        },
        PATIENT_ID: {
            REQUIRED: '* Patient Number is required',
            INVALID: '* Patient Number is invalid',
        },
        PHONE: {
            HOME: {
                REQUIRED: '* Home Phone is required',
                INVALID: '* Home Phone is invalid',
            },
            CELL: {
                REQUIRED: 'Cell Phone is required',
                INVALID: 'Cell Phone is invalid',
            },
        },
        ADDRESS: {
            STREET: {
                REQUIRED: 'Street Address is required',
            },
            CITY: {
                REQUIRED: 'City is required',
                INVALID: 'City is invalid',
            },
            ZIP: {
                REQUIRED: 'Zip is required',
                INVALID: 'Zip is invalid',
            },
        },
        INVALID_2FA: `"We're sorry.  We are unable to verify your identity because there is no cell phone or
        email on file for you. Please contact your provider and try again!"`,
        INVALID_PATIENT_DETAILS: `Oops, something isn't right with the information
        you entered.  Please verify that the name and date of birth are correct and matches
        how your provider has the patient listed.  If you continue to have problems, please contact
        your provider.`,
        PAST_APPOINTMENT_DATE: `This eRegistration link is no longer valid, as it is for a past or a cancelled appointment.`,
        TECHNICAL_ISSUES: `We're sorry. We are having technical issues.  Please try again later or call
        your provider for assistance.`,
    },

    INSURANCE: {
        FRONT_IMAGE_IS_REQUIRED: '* Front image is required',
        BACK_IMAGE_IS_REQUIRED: '* Back image is required',
        BOTH_SIDES_REQUIRED: 'Both sides required',
        RELATIONSHIP_REQUIRED: 'Relationship is required',
        EFFECTIVE_DATE_REQUIRED: 'Effective Date is required',
        EXP_DATE_GREATER_THAN_EFFECTIVE: 'Expiration Date Must Be Greater Than Effective Date',
        ARE_YOU_PRIMARY_REQUIRED: 'Please select one of the options',
        IMAGE: {
            FRONT: {
                SIZE: '* Insurance Card Front Image Must Be Less than 20MB',
                INVALID: '* Invalid insurance card front image.',
            },
            BACK: {
                SIZE: '* Insurance Card Back Image Must Be Less than 20MB',
                INVALID: '* Invalid insurance card back image.',
            },
        },
        NAME: {
            REQUIRED: '* Insurance Name is required',
        },
        GROUP_ID: {
            REQUIRED: 'Group ID is required',
            INVALID: 'Group ID is invalid',
        },
        POLICY_NUMBER: {
            REQUIRED: 'Policy Number is required',
            INVALID: 'Policy Number is invalid',
        },
        PRIMARY: {
            FIRSTNAME: {
                REQUIRED: '* Primary First Name is required',
                INVALID: '* Primary First Name is invalid',
            },
            LASTNAME: {
                REQUIRED: '* Primary Last Name is required',
                INVALID: '* Primary Last Name is invalid',
            },
        },
    },

    LICENSE: {
        ISSUE_DATE_REQUIRED: '* Date Issued is required',
        EXP_DATE_GREATER_THAN_ISSUE: 'Expiration Date Must Be Greater Than Issue Date',
        IMAGE: {
            REQUIRED: '* Drivers License Image is required',
            SIZE: '* Drivers License Image Must Be Less than 20MB',
            INVALID: '* Invalid drivers license image.',
        },
        FULLNAME: {
            REQUIRED: '* Full Name is required',
            INVALID: '* Full Name is invalid',
        },
        LICENSE_NO: {
            REQUIRED: '* License Number is required',
            INVALID: '* License Number is invalid',
        },
    },

    PATIENT_IMAGE: {
        IMAGE: {
            SIZE: '* Image Must Be Less than 20MB',
            INVALID: '* Invalid  image.',
        },
    },
};

export const LoginErrorStatus = {
    401: Error.LOGIN.INVALID_2FA,
    404: Error.LOGIN.INVALID_PATIENT_DETAILS,
    422: Error.LOGIN.PAST_APPOINTMENT_DATE,
    Default: Error.LOGIN.TECHNICAL_ISSUES,
};

export const ERROR = 'Error';
export const SUCCESS = 'Success';

export const LoginType = {
    DEMOGRAPHICS: 'Demographic',
    PATIENT_ID: 'PatientId',
};

export const OTP_ERRORS = {
    OTPExpired: 'OTP has been expired'
}