import { AfterViewInit, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { StorageService } from '@shared-services/storage.service';
import { LoaderType } from '@app/shared/directives/loader/loader.model';
import { Error } from '@shared-constants/error-messages';
// import { MatomoTracker } from 'ngx-matomo';
import * as moment from 'moment';
import { LogService } from '@shared-services/log.service';
import { LogLevel } from '@shared-models/common.model';
var SmartLinkLoginComponent = /** @class */ (function () {
    function SmartLinkLoginComponent(loginFb, storageService, logger) {
        this.loginFb = loginFb;
        this.storageService = storageService;
        this.logger = logger;
        // @ViewChild('dobPickerIcon') dobPickerIcon: ElementRef;
        this.errors = Error;
        this.maxDate = new Date();
        this.minDate = new Date('01/01/1900');
        this.isDatePickerOpen = false;
        this.retainLoader = true;
        this.smartLinkLoginFg = this.loginFb.group({});
        this.loaderTypeEnum = LoaderType;
        this.onFocus = false;
        this.checkIcon = false;
        this.dateErrorPresent = false;
        this.dateValid = false;
        this.dobData = '';
        this.traceId = this.logger.genTraceId();
    }
    Object.defineProperty(SmartLinkLoginComponent.prototype, "dobFormControl", {
        get: function () {
            return this.smartLinkLoginFg.get('DOB');
        },
        enumerable: true,
        configurable: true
    });
    SmartLinkLoginComponent.prototype.ngOnInit = function () {
        this.logger.postEntry(LogLevel.Info, 'Initializing Patient ID Login Component', 'smartLinkLogin.ngOnInit', this.traceId, 100);
        this.setupFormControls();
    };
    SmartLinkLoginComponent.prototype.ngAfterViewInit = function () {
        this.logger.postEntry(LogLevel.Info, 'Patient ID Login Component initialized', 'smartLinkLogin.ngAfterViewInit', this.traceId, 100);
        this.dobPicker.nativeElement.focus();
    };
    SmartLinkLoginComponent.prototype.ngOnDestroy = function () {
        this.logger.postEntry(LogLevel.Info, 'Destroying Patient ID Login Component', 'smartLinkLogin.ngOnDestroy', this.traceId, 100);
    };
    SmartLinkLoginComponent.prototype.isFormValid = function () {
        this.logger.postEntry(LogLevel.Info, 'Checking if Patient ID Login form is valid', 'smartLinkLogin.isFormValid', this.traceId, 100);
        return this.smartLinkLoginFg.valid;
    };
    SmartLinkLoginComponent.prototype.setupFormControls = function () {
        this.logger.postEntry(LogLevel.Info, 'Setting up Patient ID Login form controls', 'smartLinkLogin.setupFormControls', this.traceId, 100);
        if (this.storageService.isReturnedFronPhoneVerification == true) {
            var dob = this.storageService.patientData.patientDetails['DateOfBirth'];
            this.dobData = dob.replace('-', '/');
            this.storageService.isReturnedFronPhoneVerification = false;
        }
        this.smartLinkLoginFg = this.loginFb.group({
            DOB: this.loginFb.control('', Validators.required)
        });
        this.logger.postEntry(LogLevel.Info, 'Configuring smartLink', 'smartLinkLogin.setupFormControls', this.traceId, 100);
    };
    SmartLinkLoginComponent.prototype.openDatePicker = function (event) {
        this.logger.postEntry(LogLevel.Info, 'Attempting to open date picker', 'smartLinkLogin.openDatePicker', this.traceId, 100);
        this.dobPicker.nativeElement.nextSibling.focus();
        this.dobPicker.nativeElement.nextSibling.click();
        if (!this.isDatePickerOpen) {
            this.dobPicker.nativeElement.focus();
            this.dobPicker.nativeElement.click();
            this.dobPicker.nativeElement._flatpickr.open();
            event.stopPropagation();
            return false;
        }
        this.logger.postEntry(LogLevel.Info, 'Date picker already open', 'smartLinkLogin.openDatePicker', this.traceId, 100);
    };
    SmartLinkLoginComponent.prototype.onOpenDatePicker = function () {
        var _this = this;
        this.logger.postEntry(LogLevel.Info, 'Date picker opened', 'smartLinkLogin.onOpenDatePicker', this.traceId, 100);
        setTimeout(function () {
            _this.prevSelectedDate = _this.dobPicker.nativeElement._flatpickr.selectedDates[0];
            _this.isDatePickerOpen = true;
        }, 200);
    };
    SmartLinkLoginComponent.prototype.onCloseDatePicker = function () {
        var _this = this;
        this.logger.postEntry(LogLevel.Info, 'Closing date picker', 'smartLinkLogin.onCloseDatePicker', this.traceId, 100);
        this.dobFormControl.markAsTouched();
        this.dobPicker.nativeElement.blur();
        var calSelectedDate = this.dobPicker.nativeElement._flatpickr.selectedDates[0];
        if (this.dobPicker.nativeElement.value) {
            var dateMoment = moment(this.dobPicker.nativeElement.value, 'MM/DD/YYYY', true);
            var date = dateMoment.toDate();
            this.date = date;
            if (dateMoment.isValid() && (dateMoment.year() >= 1900) && (date.getTime() <= this.maxDate.getTime())) {
                this.dobPicker.nativeElement._flatpickr.setDate(this.dobPicker.nativeElement.value);
                this.checkIcon = true;
                this.onFocus = false;
                this.dateErrorPresent = false;
            }
            else {
                this.checkIcon = false;
                this.dateErrorPresent = true;
                this.dobPicker.nativeElement._flatpickr.clear();
            }
        }
        else {
            this.checkIcon = false;
            this.dateErrorPresent = true;
            this.dobPicker.nativeElement._flatpickr.clear();
        }
        setTimeout(function () {
            _this.isDatePickerOpen = false;
        }, 200);
        this.logger.postEntry(LogLevel.Info, 'Date picker closed', 'smartLinkLogin.onCloseDatePicker', this.traceId, 100);
    };
    SmartLinkLoginComponent.prototype.inputValueUpdated = function () {
        this.prevSelectedDate = this.dobPicker.nativeElement._flatpickr.selectedDates[0];
    };
    SmartLinkLoginComponent.prototype.getFormValue = function () {
        var loginFormVal = this.smartLinkLoginFg.value;
        loginFormVal.PatientId = this.storageService.patientId;
        loginFormVal.appointmentId = this.storageService.appointmentId;
        loginFormVal.DOB = this.dobPicker.nativeElement.value;
        loginFormVal.suffix = this.storageService.urlSuffix;
        loginFormVal.LocationId = this.storageService.locationId;
        loginFormVal.LoginType = 'SmartLink';
        console.log("Login Form value", loginFormVal);
        return loginFormVal;
    };
    SmartLinkLoginComponent.prototype.focus = function () {
        this.onFocus = true;
        this.checkIcon = false;
        this.dobFormControl.markAsUntouched();
        this.dobFormControl.markAsPristine();
    };
    SmartLinkLoginComponent.prototype.blur = function () {
        this.onFocus = false;
        var tempDob = new Date(this.dobPicker.nativeElement.value);
        if ((this.maxDate.getTime() >= tempDob.getTime())) {
            this.checkIcon = true;
            this.dateErrorPresent = false;
        }
        else {
            this.dobFormControl.setValue('');
            this.checkIcon = false;
            this.dateErrorPresent = true;
        }
    };
    SmartLinkLoginComponent.prototype.valueChanges = function () {
    };
    return SmartLinkLoginComponent;
}());
export { SmartLinkLoginComponent };
