import {Injectable} from '@angular/core';

import {StorageService} from '@shared-services/storage.service';

@Injectable()
export class RoutePaths {
    constructor(private storageService: StorageService) {
    }

    NOT_FOUND = `/not-found`;

    get LOGIN() {
        return `/${this.storageService.urlSuffix}/login`;
    }

    get VERIFY_OTP() {
        return `/${this.storageService.urlSuffix}/verify-otp`;
    }

    get SEND_OTP() {
        return `/${this.storageService.urlSuffix}/send-otp`;
    }

    get PHONE_VERIFICATION() {
        return `/${this.storageService.urlSuffix}/phone-verification`;
    }

    get APPOINTMENT_DETAILS() {
        return `/${this.storageService.urlSuffix}/appointment-details`;
    }

    get DEMOGRAPHICS() {
        return `/${this.storageService.urlSuffix}/home/demographics`;
    }

    get PRIMARY_INSURANCE() {
        return `/${this.storageService.urlSuffix}/home/insurance/primary`;
    }

    get SECONDARY_INSURANCE() {
        return `/${this.storageService.urlSuffix}/home/insurance/secondary`;
    }

    get DRIVER_LICENSE() {
        return `/${this.storageService.urlSuffix}/home/driving-license`;
    }

    get PATIENT_IMAGE() {
        return `/${this.storageService.urlSuffix}/home/image-upload`;
    }

    get AGREEMENT_CONSENT() {
        return `/${this.storageService.urlSuffix}/home/agreements-consent`;
    }

    get PATIENT_BALANCE_DECLINE() {
        return `/${this.storageService.urlSuffix}/home/md-pay/pay-in-office`;
    }

    get PATIENT_BALANCE() {
        return `/${this.storageService.urlSuffix}/home/md-pay/balance-due`;
    }

    get COMPLETE() {
        return `/${this.storageService.urlSuffix}/home/complete`;
    }
}
