import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HeaderComponent} from './header/header.component';
import {TitleComponent} from './title/title.component';
import {PopupComponent} from './popup/popup.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        HeaderComponent,
        TitleComponent,
        PopupComponent
    ],
    exports: [
        HeaderComponent,
        TitleComponent,
        PopupComponent
    ]
})
export class ComponentsModule {
}
