import { LocalStorageService } from '@shared-services/local-storage.service';
var StorageService = /** @class */ (function () {
    function StorageService(localStorage) {
        this.localStorage = localStorage;
    }
    StorageService.prototype.getItemFromLocalStorage = function (key) {
        var value = this.localStorage.getLocalStorage(key);
        if (value) {
            return this.localStorage.getLocalStorage(key);
        }
        else {
            return false;
        }
    };
    Object.defineProperty(StorageService.prototype, "appCounter", {
        get: function () {
            return this.getItemFromLocalStorage('_appCounter');
        },
        set: function (value) {
            this._appCounter = value;
            this.localStorage.setItemInLocalStorage('_appCounter', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "lastPage", {
        get: function () {
            return this.getItemFromLocalStorage('lastPage');
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('lastPage', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "loginType", {
        get: function () {
            return this.getItemFromLocalStorage('loginType');
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('loginType', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isPatientCheckedIn", {
        get: function () {
            return this.getItemFromLocalStorage('isPatientCheckedIn');
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('isPatientCheckedIn', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isOTPVerified", {
        get: function () {
            return this.getItemFromLocalStorage('isOTPVerified');
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('isOTPVerified', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isDemographicsSubmitted", {
        get: function () {
            return this.getItemFromLocalStorage('isDemographicsSubmitted');
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('isDemographicsSubmitted', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isPrimaryInsuranceSubmitted", {
        get: function () {
            return this.getItemFromLocalStorage('isPrimaryInsuranceSubmitted');
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('isPrimaryInsuranceSubmitted', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isSecondaryInsuranceSubmitted", {
        get: function () {
            return this.getItemFromLocalStorage('isSecondaryInsuranceSubmitted');
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('isSecondaryInsuranceSubmitted', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isDriverLicenseSubmitted", {
        get: function () {
            return this.getItemFromLocalStorage('isDriverLicenseSubmitted');
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('isDriverLicenseSubmitted', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isPatientImageSubmitted", {
        get: function () {
            return this.getItemFromLocalStorage('isPatientImageSubmitted');
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('isPatientImageSubmitted', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isAgreementAndConsentSubmitted", {
        get: function () {
            return this.getItemFromLocalStorage('isAgreementAndConsentSubmitted');
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('isAgreementAndConsentSubmitted', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isCompletePage", {
        get: function () {
            return this.getItemFromLocalStorage('isCompletePage');
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('isCompletePage', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isDeviceNotAvailable", {
        get: function () {
            return this.getItemFromLocalStorage('isDeviceNotAvailable');
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('isDeviceNotAvailable', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isSessionExpired", {
        get: function () {
            return this.getItemFromLocalStorage('isSessionExpired');
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('isSessionExpired', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "patientBalance", {
        get: function () {
            return this.getItemFromLocalStorage('patientTotalBalance');
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('patientTotalBalance', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "mdpayEnabled", {
        get: function () {
            return this.getItemFromLocalStorage('mdpayEnabled');
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('mdpayEnabled', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "patientToken", {
        get: function () {
            return this.getItemFromLocalStorage('patientToken');
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('patientToken', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isReturnedFronPhoneVerification", {
        get: function () {
            return this.getItemFromLocalStorage('isReturnedFronPhoneVerification');
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('isReturnedFronPhoneVerification', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isSuffixResolved", {
        get: function () {
            if (this.localStorage.getLocalStorage('isSuffixResolved') !== null) {
                return this.localStorage.getLocalStorage('isSuffixResolved');
            }
            else {
                return true;
            }
        },
        set: function (value) {
            this.localStorage.setItemInLocalStorage('isSuffixResolved', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "authToken", {
        get: function () {
            if (this.localStorage.getLocalStorage('authToken')) {
                return this.localStorage.getLocalStorage('authToken');
            }
            else {
                return this._authToken;
            }
        },
        set: function (token) {
            this._authToken = token;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "authExpiry", {
        get: function () {
            if (this.localStorage.getLocalStorage('authExpiry')) {
                return this.localStorage.getLocalStorage('authExpiry');
            }
            else {
                return this._authExpiry;
            }
        },
        set: function (expiry) {
            this._authExpiry = expiry;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "appointmentId", {
        get: function () {
            return this.localStorage.getLocalStorage('appointmentId');
        },
        set: function (apptId) {
            this.localStorage.setItemInLocalStorage('appointmentId', apptId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "locationId", {
        get: function () {
            return this.localStorage.getLocalStorage('locationId');
        },
        set: function (locId) {
            this.localStorage.setItemInLocalStorage('locationId', locId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "patientId", {
        get: function () {
            return this.localStorage.getLocalStorage('patientId');
        },
        set: function (pId) {
            this.localStorage.setItemInLocalStorage('patientId', pId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "urlSuffix", {
        get: function () {
            return this.localStorage.getLocalStorage('urlSuffix');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "accountInfoDetails", {
        get: function () {
            return this.localStorage.getLocalStorage('accountInfoDetails');
        },
        set: function (accInfoDetails) {
            this.localStorage.setItemInLocalStorage('accountInfoDetails', accInfoDetails);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "draftPage", {
        get: function () {
            return this.localStorage.getLocalStorage('draftPage');
        },
        set: function (page) {
            this.localStorage.setItemInLocalStorage('draftPage', page);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "formId", {
        get: function () {
            return this.localStorage.getLocalStorage('formId');
        },
        set: function (formId) {
            this.localStorage.setItemInLocalStorage('formId', formId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "oldFormId", {
        get: function () {
            return this.localStorage.getLocalStorage('oldformId');
        },
        set: function (formId) {
            this.localStorage.setItemInLocalStorage('oldformId', formId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "accountCode", {
        get: function () {
            if (this.accountInfoDetails) {
                return this.accountInfoDetails.Company;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "logo", {
        get: function () {
            if (this.accountInfoDetails) {
                return this.accountInfoDetails.Logo;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "ButtonColor", {
        get: function () {
            if (this.accountInfoDetails) {
                return this.accountInfoDetails.ButtonColor;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "TextColor", {
        get: function () {
            if (this.accountInfoDetails) {
                return this.accountInfoDetails.TextColor;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "headerColor", {
        get: function () {
            if (this.accountInfoDetails) {
                return this.accountInfoDetails.HeaderColor;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "ButtonFontColor", {
        get: function () {
            if (this.accountInfoDetails) {
                return this.accountInfoDetails.ButtonFontColor;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "patientData", {
        get: function () {
            return this.localStorage.getLocalStorage('patientData');
        },
        set: function (pData) {
            this.localStorage.setItemInLocalStorage('patientData', pData);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isPrimaryInsuranceEnabled", {
        get: function () {
            if (this.patientData) {
                return this.patientData.patientSettings.IsPrimaryInsurance;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isSecondaryInsuranceEnabled", {
        get: function () {
            if (this.patientData) {
                return this.patientData.patientSettings.IsSecondaryInsurance;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "IsPrimaryInsuranceImageOnly", {
        get: function () {
            if (this.patientData) {
                return this.patientData.patientSettings.IsPrimaryInsuranceImageOnly;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "IsSecondaryInsuranceImageOnly", {
        get: function () {
            if (this.patientData) {
                return this.patientData.patientSettings.IsSecondaryInsuranceImageOnly;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "IsDriverLicenseImageOnly", {
        get: function () {
            if (this.patientData) {
                return this.patientData.patientSettings.IsDriverLicenseImageOnly;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isAcuantEnabled", {
        get: function () {
            if (this.patientData) {
                return this.patientData.patientSettings.IsAcuantEnabled;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "acuantLicenseKey", {
        get: function () {
            if (this.patientData) {
                return this.patientData.patientSettings.LicenseKey;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isDriverLicenseEnabled", {
        get: function () {
            if (this.patientData) {
                return this.patientData.patientSettings.IsDriverLicenseEnable;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isPatientImageEnabled", {
        get: function () {
            if (this.patientData) {
                return this.patientData.patientSettings.IsImageUploadEnabled;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "patientImageText", {
        get: function () {
            if (this.patientData && this.patientData.patientSettings.ImageText) {
                return this.patientData.patientSettings.ImageText;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "isAgreementAndConsentEnabled", {
        get: function () {
            if (this.patientData) {
                return this.patientData.patientSettings.IsAgreementAndConsentEnable;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "AgreementAndConsentText", {
        get: function () {
            if (this.patientData && this.patientData.patientSettings.AgreementAndConsentText) {
                return this.patientData.patientSettings.AgreementAndConsentText;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "templateTypeID", {
        get: function () {
            if (this.patientData) {
                return this.patientData.patientSettings.TemplateTypeID;
            }
            return;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "LoginInfoText", {
        get: function () {
            if (this.accountInfoDetails) {
                return this.accountInfoDetails.LoginInfo;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageService.prototype, "LoginConfiguration", {
        get: function () {
            if (this.accountInfoDetails) {
                return this.accountInfoDetails.LoginConfig;
            }
        },
        enumerable: true,
        configurable: true
    });
    StorageService.prototype.getLocalStorage = function (key) {
        return JSON.parse(localStorage.getItem(key));
    };
    StorageService.prototype.storeAuthTokenInLocalStorage = function () {
        this.localStorage.setItemInLocalStorage('authToken', this._authToken);
        var time = new Date();
        time.setSeconds(time.getSeconds() + this._authExpiry);
        this.localStorage.setItemInLocalStorage('authExpiry', time.getTime());
    };
    StorageService.prototype.clear = function () {
        this.isPatientCheckedIn = false;
        //this.isOTPVerified = false;
        this.lastPage = false;
        this.isDemographicsSubmitted = false;
        this.isPrimaryInsuranceSubmitted = false;
        this.isSecondaryInsuranceSubmitted = false;
        this.isDriverLicenseSubmitted = false;
        this.isAgreementAndConsentSubmitted = false;
        this.isCompletePage = false;
        this.isDeviceNotAvailable = false;
        this.isSessionExpired = false;
        this.isPatientImageSubmitted = false;
        this._authToken = '';
        this._authExpiry = 0;
        this.localStorage.deleteLocalStorage('authToken');
        this.localStorage.deleteLocalStorage('authExpiry');
        this.localStorage.deleteLocalStorage('patientId');
        this.localStorage.deleteLocalStorage('appointmentId');
        this.localStorage.deleteLocalStorage('patientDetails');
        this.localStorage.deleteLocalStorage('isOTPVerified');
        this.localStorage.deleteLocalStorage('draftPage');
    };
    return StorageService;
}());
export { StorageService };
