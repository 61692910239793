/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./title.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./title.component";
import * as i4 from "../../services/log.service";
var styles_TitleComponent = [i0.styles];
var RenderType_TitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TitleComponent, data: {} });
export { RenderType_TitleComponent as RenderType_TitleComponent };
function View_TitleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subtitle; _ck(_v, 1, 0, currVal_0); }); }
function View_TitleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
export function View_TitleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "page-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitleComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitleComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subtitle; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_TitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-title", [], null, null, null, View_TitleComponent_0, RenderType_TitleComponent)), i1.ɵdid(1, 114688, null, 0, i3.TitleComponent, [i4.LogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TitleComponentNgFactory = i1.ɵccf("app-title", i3.TitleComponent, View_TitleComponent_Host_0, { title: "title", subtitle: "subtitle" }, {}, []);
export { TitleComponentNgFactory as TitleComponentNgFactory };
