export class PopupOptions {
    heading: string;
    message: string;
    type: PopupType;
    isVisible: boolean;
    btnName?: string;
}

export enum PopupType {
    INFO,
    SUCCESS,
    ERROR
}

export namespace PopupType {

    export function popupIcon(popupType: PopupType) {
        switch (popupType) {
            case PopupType.INFO:
                return `url(assets/images/icon-info-inverse.png)`;
            case PopupType.ERROR:
                return `url(assets/images/icon-error.png)`;
            case PopupType.SUCCESS:
                return `url(assets/images/icon-success.png)`;
        }
    }
}

