import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AppResolver} from '@shared-guards/auth/app.resolve';
import {AuthGuard} from '@shared-guards/auth';
import {PageNotFoundComponent} from '@shared-components/not-found/not-found.component';
import {NotCheckinGuard} from '@shared-guards/not-checkin.guard';
import {AppointmentGuard} from '@shared-guards/appointment-details.guard';
import {OTPGuard} from '@shared-guards/otp.guard';

import {OTPVerificationComponent} from '@app/auth/verification/otp-verification/otp-verification.component';
import {SendOtpComponent} from '@app/auth/verification/send-otp/send-otp.component';
import {LoginComponent} from '@app/auth/login/login.component';
import {AppointmentDetailsComponent} from '@app/auth/appointment-details/appointment-details.component';
import {PhoneVerificationComponent} from '@app/auth/verification/phone-verification/phone-verification.component';

const appRoutes: Routes = [

    {
        path: 'not-found',
        component: PageNotFoundComponent,
        data: {
            'type': 'not-found',
            'mutedText': 'The page you are looking for was not found.',
            'pText': 'Oops! You\'re lost.'
        }
    },
    {
        'path': ':Template',
        'children': [
            {path: 'login', component: LoginComponent, resolve: {AppResolve: AppResolver}},
            {path: 'send-otp', component: SendOtpComponent, canActivate: [AuthGuard, NotCheckinGuard, OTPGuard]},
            {
                path: 'verify-otp',
                component: OTPVerificationComponent,
                canActivate: [AuthGuard, NotCheckinGuard, OTPGuard]
            },
            {
                path: 'phone-verification',
                component: PhoneVerificationComponent,
              //  canActivate: [AuthGuard, NotCheckinGuard]
            },
            {
                path: 'appointment-details',
                component: AppointmentDetailsComponent,
                canActivate: [AppointmentGuard]
            },
            {path: 'home', loadChildren: '.\/home\/home.module#HomeModule', canActivate: [AuthGuard]},
            {path: 'demo-app', loadChildren: '.\/demo\/demo.module#DemoModule'},
           // {path: '', redirectTo: 'home/demographics', pathMatch: 'full'},
            {path: '**', redirectTo: 'login', pathMatch: 'full'}
        ],
    }, {
        path: '',
        component: PageNotFoundComponent,
        data: {
            'type': 'template-not-found',
            'mutedText': 'Please contact the Admin.',
            'pText': 'Oops! Template is not found'
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
        )
    ],
    exports: [
        RouterModule    ]
})
export class AppRoutingModule {
}
