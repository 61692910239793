import {AbstractControl} from '@angular/forms';


export class PhoneValidators {
    static phoneLength(c: AbstractControl): { [key: string]: boolean } | null {
        if (c.value) {
            const isValidPhoneNo = c.value.replace(/\D+/g, '').length === 11;
            return isValidPhoneNo ? null : {'phoneLength': true};
        }
        return null;
    }
}


