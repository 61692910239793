<form [formGroup]="demographicsLoginFg">
    <app-bootstrap-input [inputConfig]="firstNameConfig"></app-bootstrap-input>
    <app-bootstrap-input [inputConfig]="lastNameConfig"></app-bootstrap-input>
    <div class="form-group"
         [class.has-error]="demographicsLoginFg.get('DOB').invalid && demographicsLoginFg.get('DOB').touched">
        <label for="dobP" class="control-label">Date of Birth</label>
        <div class="input-group"
        [ngClass]="{'border-style': (onFocus && demographicsLoginFg.get('DOB').untouched || (onFocus && demographicsLoginFg.get('DOB').dirty)),
        'border-style-checkIcon':checkIcon,
        'border-style-error':(demographicsLoginFg.get('DOB').invalid && demographicsLoginFg.get('DOB').touched) && (dateErrorPresent)}">
            <input id="dobP" class="form-control input-date-picker default-cursor" placeholder="mm/dd/yyyy" type="text"
                   mwlFlatpickr [dateFormat]="'m/d/Y'" [clickOpens]=false
                   [allowInput]=true #dobPicker [disableMobile]=true [maxDate]="maxDate" [minDate]="minDate"
                   (flatpickrOpen)="onOpenDatePicker()" (flatpickrValueUpdate)="inputValueUpdated()"
                   (flatpickrClose)="onCloseDatePicker()" lang="en" formControlName="DOB" autocomplete="off"
                  
                   (focus)="focus()" (blur)="blur()"
                   appMaskDate>
            <span class="input-group-addon" (click)="openDatePicker($event)" 
            [ngClass]="{'datepicker-bg-color-valid':checkIcon}">
                                    <i class="glyphicon glyphicon-calendar"></i>
                                </span>

                                <span class="input-group-addon" *ngIf="checkIcon" 
                                [ngClass]="{'datepicker-bg-color-valid':checkIcon}">
                                    <i class="glyphicon glyphicon-ok" 
                                    [ngClass]="{'checkIcon':checkIcon}"></i>
                                </span>      
                                <span class="input-group-addon" *ngIf="demographicsLoginFg.get('DOB').invalid && demographicsLoginFg.get('DOB').touched">
                                    <i class="glyphicon glyphicon-remove" 
                                    [ngClass]="{'errorIcon': demographicsLoginFg.get('DOB').invalid && demographicsLoginFg.get('DOB').touched}"></i>
                                </span>                    
        </div>
        <div *ngIf="demographicsLoginFg.get('DOB').invalid && demographicsLoginFg.get('DOB').touched" class="margin-top">
            <div class="formio-errors invalid-feedback" *ngIf="demographicsLoginFg.get('DOB').errors['pattern'] || demographicsLoginFg.get('DOB').errors['required']"><p
                class="help-block ">{{errors.DATE_OF_BIRTH.FORMAT}}</p></div>
        </div>
        <div *ngIf="demographicsLoginFg.get('DOB').invalid && demographicsLoginFg.get('DOB').touched">
            <div class="formio-errors invalid-feedback" *ngIf="demographicsLoginFg.get('DOB').errors['required']"><p
                class="help-block">{{errors.DATE_OF_BIRTH.REQUIRED}}</p></div>
        </div>
        <div *ngIf="onFocus && !(demographicsLoginFg.get('DOB').invalid && demographicsLoginFg.get('DOB').touched)" class="margin-top"  style="font-style: italic"
        [ngStyle]="{'color':checkIcon===true?'green':'#20a8d8'}" 
        [ngClass]="{'errorIcon':dateErrorPresent}"
        >Month, Day, Year</div>
    </div>


    <!-- <div formGroupName="Address">
        <div *ngIf="storageService.accountInfoDetails.streetAddress">
            <app-bootstrap-input [inputConfig]="streetAddressConfig"></app-bootstrap-input>
        </div>

        <div *ngIf="storageService.accountInfoDetails.City">
            <app-bootstrap-input [inputConfig]="cityConfig"></app-bootstrap-input>
        </div>

        <div class="form-group" *ngIf="storageService.accountInfoDetails.State">
            <label for="state" class="control-label">State</label>
            <div class="select-wrapper">
                <select id="state" class="form-control" formControlName="State">
                    <option *ngFor="let state of states" [value]="state.Code">{{state.Name}}</option>
                </select>
            </div>
        </div>

        <div *ngIf="storageService.accountInfoDetails.Zip">
            <app-bootstrap-input [inputConfig]="zipConfig"></app-bootstrap-input>

        </div>
    </div>

    <div formGroupName="PhoneNumber">
        <div *ngIf="storageService.accountInfoDetails.HomePhone">
            <app-bootstrap-input [inputConfig]="homeConfig"></app-bootstrap-input>
        </div>

        <div *ngIf="storageService.accountInfoDetails.CellPhone">
            <app-bootstrap-input [inputConfig]="cellConfig"></app-bootstrap-input>
        </div>

    </div>

    <div class="form-group" *ngIf="storageService.accountInfoDetails.Sex">
        <label for="sex" class="control-label">Sex</label>
        <div class="select-wrapper">
            <select id="sex" class="form-control" formControlName="Sex">
                <option *ngFor="let gen of gender">{{gen}}</option>
            </select>
        </div>
    </div> -->
</form>
