<div>
    <fieldset #disabledFieldset>
        <div class="form-wrapper">
            <app-header [title]="title"></app-header>
            <div id="main">
                <div class="container" *ngIf="isCookieEnabled">
                  <div class="ql-editor" [innerHTML]="headerMessage"></div>
                    <form [formGroup]="loginTypeFg" *ngIf="isAlternateLoginAllowed && !isSmartLinkEnabled">
                        <div class="form-group form-field-type-radio">
                            <div class="radio-inline">
                                <label class="control-label">
                                    <input id="demographics" type="radio" formControlName="loginType"
                                           [value]="loginType.DEMOGRAPHICS">
                                    <span>Patient Login </span>
                                </label>
                            </div>
                            <div class="radio-inline">
                                <label class="control-label">
                                    <input id="patient_id" type="radio" formControlName="loginType"
                                           [value]="loginType.PATIENT_ID">
                                    <span>Office Login </span>
                                </label>
                            </div>
                        </div>
                    </form>
                    <div [ngSwitch]="loginTypeValue">
                        <app-demographics *ngSwitchCase="loginType.DEMOGRAPHICS"></app-demographics>
                        <app-patient *ngSwitchCase="loginType.PATIENT_ID"></app-patient>
                        <app-smartlink *ngSwitchCase="smartLink"></app-smartlink>
                    </div>
                    <button type="submit" class="btn btn-primary btn-full-width margin-top-10" (click)="login()"
                            [appLoader]="{promises: [loginSubscription], loaderType: loaderTypeEnum.BUTTON, disabledFieldSet: disabledFieldset, retainLoader: retainLoader}">
                        Begin eRegistration
                    </button>
                </div>
            </div>
        </div>
    </fieldset>
</div>
